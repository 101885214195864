global.$RefreshReg$ = () => { }; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => { }; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import React from "react";
import GoogleCalendarService, {
  EDITABLE_ROLES,
  renderDefaultSignatureWithEmptyLinesAbove,
  DATE_TIME_24_HOUR_FORMAT,
  MOMENT_MDY_DATE_FORMAT,
  MOMENT_DMY_DATE_FORMAT,
  MOMENT_YMD_DATE_FORMAT,
  BACKEND_ZOOM,
  BACKEND_HANGOUT,
  BACKEND_NO_CONFERENCING,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  BACKEND_CUSTOM_CONFERENCING,
  RSVP_STATUS,
  getVimcalRichTextSignature,
} from "./googleCalendarService";
import ShortcutTile from "../components/shortcutTile";
import { RRule, rrulestr } from "rrule";
import moment from "moment";
import FlexSearch from "flexsearch";
import _ from "underscore";
import StyleConstants, {
  DESKTOP_TITLE_BAR_HEIGHT,
  TOP_BAR_HEIGHT,
  ZOOM_CONFERENCING,
  CONFERENCING_OPTIONS_ARRAY,
  DARK_MODE_THEME,
  DIGIT_TO_NUMBER_OBJECT,
  GOOGLE_CONFERENCING,
  GOOGLE_HANGOUT,
  GOOGLE_MEET,
  DEFAULT_PRIMARY_CALENDAR_COLOR,
  DEFAULT_PRIMARY_CALENDAR_FADED_COLOR,
  BACKEND_MONTH,
  DEBOUNCE_BACKEND_TIMER,
  LOGGED_IN_ACCOUNTS,
  GMAIL,
  GOOGLE_DRIVE,
  GOOGLE_CALENDAR,
  NEW_GOOGLE_MEET,
  UTC_TIME_ZONE,
  INVITEE_NAME_BLOCK,
  AGENDA_FORMAT_JS_DATE,
  COMMAND_KEY,
  PC_CONTROL_KEY,
  SAFARI,
  OPERA,
  CHROME,
  FIREFOX,
  INTERNET_EXPLORE,
  DURATION_UNITS,
  ZOOM_SEARCH_STRINGS,
  MODAL_BLUR,
  MODAL_BORDER_RADIUS,
  getModalBackgroundColor,
} from "./globalVariables";
import { constructRequestURL } from "./api";
import Fetcher from "./fetcher";
import * as Sentry from "@sentry/browser";
import {
  TEMPLATE,
  UPDATE_TEMPLATE,
  ZOOM_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
  PHONE_CONFERENCING_OPTION,
  NO_CONFERENCING_OPTION,
  WHATS_APP_CONFERENCING_OPTION,
} from "./googleCalendarService";
import EmojiRegex from "emoji-regex";
import {
  TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX,
  IANNA_TIME_ZONES,
  POPULAR_TIME_ZONES,
  TIME_ZONE_SEARCH_QUERY_INDEX,
  POPULAR_TIME_ZONES_INDEX,
  ALL_VALID_GMT_OFFSET_TIMEZONES,
  getValidFloatingPointGMTTimeZone,
} from "./timeZone";
import GoogleColors from "./googleColors";
import PhoneNumber from "awesome-phonenumber";
import * as chrono from "chrono-node";
import { TinyColor } from "@ctrl/tinycolor";
import Spinner from "../components/spinner";
import {
  add,
  differenceInMinutes,
  parseISO,
  subDays,
  format,
  getUnixTime,
  parse,
  startOfDay,
  addMinutes,
  isValid,
  set,
  startOfMinute,
  startOfWeek,
  endOfWeek,
  isAfter,
  endOfDay,
  startOfMonth,
  endOfMonth,
  addDays,
  isBefore,
  isSameMinute,
  isSameDay,
  formatISO,
  subMinutes,
  addWeeks,
  getISODay,
  setISODay,
  startOfHour,
  parseJSON,
  subHours,
  differenceInHours,
  getMinutes,
  setMinutes,
} from "date-fns";
import differenceInDays from "date-fns/differenceInDays";
import {
  createCustomConferencingOption,
  isPhoneConferencingWhatsApp,
  isValidCustomConferencing,
  isZoomFromGSuiteIntegration,
} from "../lib/conferencing";
import { sortContacts, createEmailString } from "../lib/contactFunctions";
import { getObjectEmail } from "../lib/objectFunctions";
import {
  isEventPrivate,
  isOrganizerSelf,
  getEventStartAllDayDate,
  getEventEndAllDayDate,
  getEventStartDateTime,
  getEventEndDateTime,
  isAllDayEvent,
  isValidEvent,
  getEventStartValue,
  getEventStartTimeZone,
  getEventEndTimeZone,
  getEventStartAndEnd,
  isOutlookEvent,
  getEventEndValue,
  isAvailabilityEvent,
  isEventHiddenAttendees,
  isWorkplaceEvent,
  isAllDayWorkLocationEvent,
  isPreviewOutlookEvent,
  isEventAttendeeOrganizer,
  hasEventTimeZoneBeenExpliciltySpecified,
  getExplicitlySpecifiedTimeZone,
} from "../lib/eventFunctions";
import md5 from "md5";
import produce from "immer";
import { createDateIndexForDB, filterOutInvalidTimeZones, getOffsetBetweenTimeZones, safeGuardTimeZones } from "../lib/timeFunctions";
import { protectMidnightCarryOver } from "../lib/rbcFunctions";
import { determineConferenceText } from "../lib/conferencing";
import {
  addEventUserCalendarID,
  addEventUserEventID,
  getEventAttendees,
  getEventConferenceData,
  getEventConferenceURL,
  getEventCreator,
  getEventDescription,
  getEventEtag,
  getEventGuestPermissions,
  getEventID,
  getEventLocation,
  getEventMasterEventID,
  getEventOrganizer,
  getEventRecurrence,
  getEventTitle,
  getEventUpdatedAt,
  getEventUserCalendarID,
  getEventUserEventID,
  getGCalEventId,
  GUESTS_CAN_MODIFY,
  GUESTS_CAN_SEE_OTHER_GUESTS,
  isAllDayOutlookEvent,
} from "./eventResourceAccessors";
import {
  createTemplateRBCEventEnd,
  getTemplateEndDate,
  getTemplateEndDateTime,
  getTemplateEndTimeZone,
  getTemplateStartDate,
  getTemplateStartDateTime,
  getTemplateStartTimeZone,
  getTemplateStartValue,
  isValidEventTemplate,
} from "./templateFunctions";
import {
  getCalendarBackgroundColor,
  getCalendarColorID,
  getCalendarEditRole,
  getCalendarIsDeleted,
  getCalendarIsHidden,
  getCalendarEmail,
} from "./calendarAccessors";
import { getUserSettings, hasUserSettings } from "./settingsAccessors";
import { isAppInTaskMode } from "./appFunctions";
import { isVersionV2 } from "./versionFunctions";
import { BACKEND_OUTLOOK_CONFERENCING, convertOutlookConferencingToHumanReadable, getPrimaryCalendarConferencing, isOutlookEventAndOrganizer, isOutlookUser, shouldHideEventResponseOptions } from "../lib/outlookFunctions";
import { cleanRRuleString } from "../lib/recurringEventFunctions";
import { getAccountCompletedToolTips, getMatchingUserFromAllUsers, getUserEmail, getUserName, getUserToken } from "../lib/userFunctions";
import { getMeetingIDAndPassword } from "./zoomFunctions";
import { isOutstandingSlotEvent, isTemporaryAIEvent } from "../lib/availabilityFunctions";
import { createTimeZoneAvailabilityLine, formatSlotsTextForTime, getListOfTimeZoneAbbreviationsForSlotsHeader, sortMultipleTimeZonesForSlots } from "../lib/availabilityFunctions";
import { getCalendarFromUserCalendarID, getCalendarName, getMatchingCalendarWithCalendarOwnerFromEmail } from "../lib/calendarFunctions";
import { handleIndexDBError } from "../lib/errorFunctions";
import { safeJSONParse } from "../lib/jsonFunctions";
import { getFetchWindowStartAndEnd, getInitialSyncWindowForGoogle } from "../lib/syncFunctions";
import tzlookup from "tz-lookup";
import { LOCAL_DATA_ACTION, getCurrentUserEmail, getSavedLocationTimeZone, saveLocationTimeZone } from "../lib/localData";
import { getClearbitNameFromAttendee } from "../lib/clearbitFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getCurrentTrip, getTripTimeZone } from "../lib/personalLinkFunctions";
import { isMeetWithEvent } from "../lib/meetWithFunctions";
import { useAllLoggedInUsers } from "./stores/SharedAccountData";
import { trackError } from "../components/tracking";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined, isTypeString } from "./typeGuards";
import { getDefaultBackgroundColor } from "../lib/styleFunctions";
import { getEventHoldDetails } from "./holdFunctions";
import { isInternalTeamUserEmail } from "../lib/featureFlagFunctions";
import {
  capitalizeFirstLetter,
  equalAfterTrimAndLowerCased,
  isValidEmail,
  isUrl,
  lowerCaseAndTrimString,
  pluralize,
  stringHasNumber,
  isSameEmail,
  formatEmail,
  WHATSAPP_BASE_URL,
  extractWhatsappURL,
} from "../lib/stringFunctions";
import { ENVIRONMENTS } from "../lib/vimcalVariables";
import { devErrorPrint, isLocal } from "./devFunctions";
import { getDefaultUserConferencing, getWorkHours } from "../lib/settingsFunctions";
import { MAGIC_LINK_PATH } from "./maestro/maestroRouting";
import { getDistroListGroupMembers, getDistroListName, isDistroListEmail } from "../lib/distroListFunctions";
import { getChronoKnownEndFromStart } from "../lib/chronoFunctions";
import { getDateTimeFormatLowercaseAMPM } from "../lib/dateFunctions";

let {
  daysString,
  weeksString,
  monthsString,
  yearsString,
  temporary,
  self_response_confirmed,
  self_response_tentative,
  attendee_event_attending,
  attendee_event_tentative,
  attendee_event_declined,
  never,
  on,
  after,
  availability,
  zoomUS,
  takenLetterList,
  allLetterList,
  editableRoles,
  visibilityPrivate,
  busySummary,
  noTitle,
} = GoogleCalendarService;

// regex for finding dd/mm/yyyy or dd-mm-yyy
const DATE_REGEX = new RegExp(
  "(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})(?=\\W)|\\b(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])?|(?:(?:16|[2468][048]|[3579][26])00)?)))(?=\\W)|\\b(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))(\\4)?(?:(?:1[6-9]|[2-9]\\d)?\\d{2})?(?=\\b)",
  "gm"
);

// TODO: MOVE INTO SEPARATE FILE - KEYSTROKES ARE DIFFERENT FOR DIFFERENT KEYBOARDS (e.g. EUROPEAN) AND OPERATING SYSTEMS
// TODO: The event.keyCode property is deprecated, migrate to use keys instead.
// @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
export const KEYCODE_ENTER = 13;
export const KEYCODE_UP_ARROW = 38;
export const KEYCODE_DOWN_ARROW = 40;
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_COMMA = 188;
export const KEYCODE_SPACE = 32;
export const KEYCODE_COMMAND_LEFT = 91;
export const KEYCODE_COMMAND_FIREFOX = 224;
export const KEYCODE_COMMAND_RIGHT = 93;
export const KEYCODE_CONTROL = 17;
export const KEYCODE_CONTROL_MAC = 17;
export const KEYCODE_PC_ALT = 18;
export const KEYCODE_K = 75;
export const KEYCODE_J = 74;
export const KEYCODE_SEMI_COLON = 186;
export const KEYCODE_TAB = 9;
export const KEYCODE_LEFT = 37;
export const KEYCODE_RIGHT = 39;
export const KEYCODE_DELETE = 8;
export const KEYCODE_F = 70;
export const KEYCODE_C = 67;
export const NO_RESPONSE_NEEDED = "noResponseNeeded";
export const KEYCODE_W = 87;
export const KEYCODE_4 = 52;
export const KEYCODE_D = 68;
export const KEYCODE_M = 77;
export const KEYCODE_BACKSPACE = 8;

let _staticShadeColorIndex = {};
let _staticDarkenColorIndex = {};
let _staticFadeColorIndex = {};
let _staticBrightnessIndex = {};
let _staticDimDarkModeColors = {};
let _staticTimeZoneAbbreviation = {};
let _staticTimeZoneList = {};
let _staticLightenColor = {};
let _isElectronCache;
let _isMacCache;
let _isFirefox;
let _isSafari;
let _isMenuBar;
let _validTimeZones = {};
let _invalidTimeZones = {};
let _guessedTimeZoneBasedOnLocation = getSavedLocationTimeZone(); // set it after the async is complete
const hasBrowserTimeZone = !!Intl.DateTimeFormat().resolvedOptions().timeZone;
guessTimeZoneFromLocation(); // gets location synchronously
let _guessedTimeZone = getDeviceTimeZone();
const customChrono = chrono.casual.clone();
let _browserType = null;

function addParserToCustomChrono(parser) {
  // https://github.com/wanasit/chrono/issues/299
  chrono.en?.casual?.parsers?.push(parser);
  customChrono.parsers.push(parser);
}

function unshiftParserToCustomChrono(parser) {
  chrono.en?.casual?.parsers?.unshift(parser);
  customChrono.parsers.unshift(parser);
}

const mondayComPattern = new RegExp(/monday\.com/, "i");
// Add the custom parser to chrono-node's parsers list
addParserToCustomChrono({
  pattern: () => {
    return mondayComPattern;
  },
  extract: (context, match) => {
    return {};
  },
});

DURATION_UNITS.forEach((n) => {
  // Get 2 words before the unit label above
  // One hundred and twenty three will not work
  // let re = new RegExp("((?: |^)\\w+){2}" + ` ${n}` + "\\b",  'gmi'); // two words
  let re = new RegExp("((?: |^)\\w+){1}" + ` ${n}` + "\\b", "gmi");

  addParserToCustomChrono({
    pattern: () => {
      return re;
    },
    extract: (context, match) => {
      return {
        second: 0,
        isDuration: true,
      };
    },
  });
});

DURATION_UNITS.forEach((n) => {
  // catches the entire word if it contains duration:
  // e.g. 2hrs, 3min, etc
  // \b\d+\s?mins?\b => this expression works on https://regex101.com/r/DtUpe8/1

  let re = new RegExp("\\b\\d+\\s?" + `${n}s?` + "\\b", "gmi");

  addParserToCustomChrono({
    pattern: () => {
      return re;
    },
    extract: (context, match) => {
      return {
        second: 0,
        isDuration: true,
      };
    },
  });
});

unshiftParserToCustomChrono({
  pattern: () => {
    return /\bnoon\b/i;
  },
  extract: (context, match) => {
    return {
      hour: 12,
      minute: 0,
    };
  },
});

unshiftParserToCustomChrono({
  pattern: () => {
    return /\ball day\b/i;
  },
  extract: (context, match) => {
    return {
      second: 0,
      isAllDay: true,
    };
  },
});

unshiftParserToCustomChrono({
  pattern: () => {
    return /\ball-day\b/i;
  },
  extract: (context, match) => {
    return {
      second: 0,
      isAllDay: true,
    };
  },
});

unshiftParserToCustomChrono({
  pattern: () => {
    return /\bmidnight\b/i;
  },
  extract: (context, match) => {
    return {
      hour: 0,
      minute: 0,
      meridiem: 0,
    };
  },
});

// for patterns such as "11/14 - 16"
unshiftParserToCustomChrono({
  pattern: () => {
    return /\b(\d{1,2}\/\d{1,2})\s*(?:-|to)\s*(\d{1,2})\b/i
  },
  extract: (context, match) => {
    try {
      const startDateText = match[1];
      const endDayText = match[2];
      // Regular expression to validate MM/DD format
      const isValidDate = (dateText) => {
        const [month, day] = dateText.split("/").map(Number);
        return (
          month >= 1 && month <= 12 &&
          day >= 1 && day <= 31 &&
          !isNaN(month) && !isNaN(day)
        );
      };
      // Validate both start and end dates
      if (!isValidDate(startDateText) || !(endDayText >= 1 && endDayText <= 31 && !isNaN(endDayText))) {
        return null; // Return null if the date is invalid
      }

      const year = new Date().getFullYear(); // Assuming the year is the current year, adjust as needed.
      const startDate = chrono.parseDate(startDateText + "/" + year);
      const endDateText = startDateText.split("/")[0] + "/" + endDayText + "/" + year;
      const endDate = chrono.parseDate(endDateText);

      return {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
        knownEnd: {
          year: endDate.getFullYear(),
          month: endDate.getMonth() + 1,
          day: endDate.getDate()
        }, // since we can't explicitly set the end date through chrono parse.
      };
    } catch (e) {
      if (isLocal()) {
        console.error("Error in unshiftParserToCustomChrono mm/dd - dd: ", e);
      }
      return null;
    }
  },
});


// for patterns such as "11/14 - 11/16"
// or "11/14 to 11/16"
// with optional spaces around the hyphen or "to"
unshiftParserToCustomChrono({
  pattern: () => {
    return /\b(\d{1,2}\/\d{1,2})\s*(?:-|to)\s*(\d{1,2}\/\d{1,2})\b/i;
  },
  extract: (context, match) => {
    try {
      const startDateText = match[1];
      const endDateText = match[2];
      // Regular expression to validate MM/DD format
      const isValidDate = (dateText) => {
        const [month, day] = dateText.split("/").map(Number);
        return (
          month >= 1 && month <= 12 &&
          day >= 1 && day <= 31 &&
          !isNaN(month) && !isNaN(day)
        );
      };
      // Validate both start and end dates
      if (!isValidDate(startDateText) || !isValidDate(endDateText)) {
        return null; // Return null if the date is invalid
      }

      const year = new Date().getFullYear(); // Assuming the year is the current year, adjust as needed.

      const startDate = chrono.parseDate(startDateText + "/" + year);
      const endDate = chrono.parseDate(endDateText + "/" + year);

      return {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
        knownEnd: {
          year: endDate.getFullYear(),
          month: endDate.getMonth() + 1,
          day: endDate.getDate()
        }, // since we can't explicitly set the end date through chrono parse.
      };
    } catch (e) {
      if (isLocal()) {
        console.error("Error in unshiftParserToCustomChrono mm/dd - mm/dd: ", e);
      }
      return null;
    }
  },
});

// This regular expression uses the following syntax:
// on the: Matches the words "on the".
// (\d{1,2})(st|nd|rd|th): Matches the day of the month, which consists of one or two digits followed by a suffix ("st", "nd", "rd", or "th"). This is the first capturing group.
// on: Matches the word "on".
// (\d{1,2})(st|nd|rd|th): Matches the day of the month, which consists of one or two digits followed by a suffix ("st", "nd", "rd", or "th"). This is the second capturing group.
// (\d{1,2})(st|nd|rd|th): Matches the day of the month, which consists of one or two digits followed by a suffix ("st", "nd", "rd", or "th"). This is the third capturing group.
unshiftParserToCustomChrono({
  pattern: () => {
    return /on the (\d{1,2})(st|nd|rd|th)|on (\d{1,2})(st|nd|rd|th)|(\d{1,2})(st|nd|rd|th)/;
  },
  extract: (context, match) => {
    return {
      day: parseInt(match[1] || match[3] || match[5]),
    };
  },
});

// Look for @ and then take the characters following it until space
let re = new RegExp("\\B@(\\S+)", "i");
unshiftParserToCustomChrono({
  pattern: () => {
    return re;
  },
  extract: (context, match) => {
    let returnResult = {};

    if (match && match[1]) {
      let searchInput = `at ${match[1]}`;
      let chronoResult = chrono.en.parse(searchInput, new Date(), {
        forwardDate: true,
      });

      if (chronoParseHasKnownValues(chronoResult)) {
        returnResult = chronoResult[0].start.knownValues;
        returnResult.text = match[1];
      }
    }

    return returnResult;
  },
});

export function getMonthDayYearLongForm({
  date,
  dateFormat = "MDY",
  isShortDay = false,
  hideYear = false
}) {
  const month = format(date, "MMM");
  const day = format(date, "d");
  const doubleDigitDay = format(date, "dd");
  const year = hideYear ? "" : format(date, "yyyy");

  if (dateFormat === MOMENT_DMY_DATE_FORMAT) {
    if (isShortDay) {
      return `${day} ${month} ${year}`;
    }
    return `${doubleDigitDay} ${month} ${year}`;
  } else if (dateFormat === MOMENT_YMD_DATE_FORMAT) {
    if (isShortDay) {
      return `${year} ${month} ${day}`;
    }
    return `${year} ${month} ${doubleDigitDay}`;
  } else if (dateFormat === MOMENT_MDY_DATE_FORMAT) {
    return `${month} ${day}, ${year}`;
  } else {
    // default
    return `${month} ${day}, ${year}`;
  }
}

export function FormatTime(date, format24Hour = false) {
  if (format24Hour) {
    return format(date, DATE_TIME_24_HOUR_FORMAT);
  }

  let hours = format(date, "H");
  let minutes = format(date, "mm");
  let ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes === 0 ? "" : ":" + minutes;

  return `${hours}${minutes} ${ampm}`;
}

export function formatTimeJSDate(date, format24Hour = false) {
  if (format24Hour) {
    return format(date, DATE_TIME_24_HOUR_FORMAT);
  }

  let hours = format(date, "H");
  let minutes = format(date, "mm");
  let ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes === 0 ? "" : ":" + minutes;

  return `${hours}${minutes} ${ampm}`;
}

export function OpenLink(url) {
  if (isElectron() && window?.vimcal) {
    window.vimcal.openLink(url);
  } else if (isElectron() && window?.require) {
    const { shell } = window.require("electron");
    shell.openExternal(url);
  } else {
    window.open(url, "_blank");
  }
}

export function openLinkOnSamePage(url) {
  if (isElectron() && window?.vimcal) {
    window.vimcal.openLink(url);
  } else if (isElectron() && window?.require) {
    const { shell } = window.require("electron");
    shell.openExternal(url);
  } else {
    window.open(url, "_self");
  }
}

export function getGuestAttendanceCounts({attendees, distroListDictionary}) {
  const getAllAttendees = () => {
    const inputAttendees = filterOutResourceAttendees(attendees);
    if (isEmptyObjectOrFalsey(distroListDictionary)) {
      return inputAttendees;
    }
    let allAttendees = inputAttendees;
    inputAttendees.forEach(attendee => {
      const email = getObjectEmail(attendee);
      if (isDistroListEmail({email, distroListDictionary})) {
        const distroListAttendees = filterOutResourceAttendees(getDistroListGroupMembers({
          email,
          distroListDictionary,
        }));
        allAttendees = allAttendees.concat(distroListAttendees);
      }
    });

    // need to remove duplicates, prioritize the ones with actually attendence information
    const RESPONSE_STATUS_NONE = "none";
    const priority = {
      [RSVP_STATUS.ATTENDING]: 4,
      [RSVP_STATUS.MAYBE]: 3,
      [RSVP_STATUS.DECLINED]: 2,
      [RSVP_STATUS.NEEDS_ACTION]: 1,
      RESPONSE_STATUS_NONE: 0,
    };
    const uniqueAttendees = [];
    const map = new Map();
    allAttendees.forEach(attendee => {
      const { email, responseStatus = RESPONSE_STATUS_NONE } = attendee;
      if (!map.has(email) || priority[responseStatus] > priority[map.get(email).responseStatus]) {
        map.set(email, attendee);
      }
    });
    map.forEach(value => uniqueAttendees.push(value));
    return uniqueAttendees;
  };

  const filteredAttendees = getAllAttendees();

  const acceptedCount = filteredAttendees.filter(
    (item) => item.responseStatus === RSVP_STATUS.ATTENDING
  ).length;
  const tentativeCount = filteredAttendees.filter(
    (item) => item.responseStatus === RSVP_STATUS.MAYBE
  ).length;
  const declinedCount = filteredAttendees.filter(
    (item) => item.responseStatus === RSVP_STATUS.DECLINED
  ).length;

  // the rest falls into here
  const needsActionResponseCount = filteredAttendees.filter(
    (item) => ![RSVP_STATUS.DECLINED, RSVP_STATUS.ATTENDING, RSVP_STATUS.MAYBE].includes(item.responseStatus)
  ).length;

  const guestCount = String(filteredAttendees.length) + " " + pluralize(filteredAttendees.length, "guest");

  const needsActionResponseString = needsActionResponseCount > 0 
    ? ", " + String(needsActionResponseCount) + " awaiting" : "";

  return {
    guestCount,
    acceptedCount,
    tentativeCount,
    declinedCount,
    needsActionResponseCount,
    needsActionResponseString,
  }
}

export function fetchEvent(calendarId, id, currentUserEmail) {
  let path = `calendars/${calendarId}/events/${id}`;
  let url = constructRequestURL(path, isVersionV2());

  return Fetcher.get(url, {}, true, currentUserEmail).catch((err) =>
    handleError(err)
  );
}

export function hasChosenEventChanged(prevEvent, newEvent) {
  if (
    !isEmptyObjectOrFalsey(newEvent) &&
    !isEmptyObjectOrFalsey(prevEvent) &&
    prevEvent.uniqueEtag !== newEvent.uniqueEtag
  ) {
    return true;
  } else if (!isEmptyObjectOrFalsey(prevEvent) && isEmptyObjectOrFalsey(newEvent)) {
    return true;
  } else if (isEmptyObjectOrFalsey(prevEvent) && !isEmptyObjectOrFalsey(newEvent)) {
    return true;
  }

  return false;
}

export function constructQueryParams(params) {
  if (isEmptyObjectOrFalsey(params)) {
    return "";
  }

  const keysArray = Object.keys(params);
  return keysArray.length === 0 ? '' : keysArray
    .filter(key => !!params[key])
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");
}

export function WeekOfMonth(date) {
  let dayOfMonth = parseInt(format(date, "d"), 10);
  return Math.ceil(dayOfMonth / 7);
}

export function NumberToName(number) {
  switch (number) {
    case 1:
      return "first";
    case 2:
      return "second";
    case 3:
      return "third";
    case 4:
      return "fourth";
    case 5:
      return "fifth";
    case 6:
      return "sixth";
    default:
      return "none";
  }
}

export function RoundToClosestMinute(
  time,
  interval,
  format = "LT",
  skipFormatting = false
) {
  let start = moment(time);
  let remainder = interval - (start.minute() % interval);

  remainder = remainder === interval ? 0 : remainder;

  let dateTime;

  if (!skipFormatting) {
    dateTime = moment(start).clone().add(remainder, "minutes").format(format);
  } else {
    dateTime = moment(start).clone().add(remainder, "minutes");
  }

  return dateTime;
}

export function RoundToClosestMinuteJSDate(jsDate, interval) {
  let remainder = interval - (jsDate.getMinutes() % interval);

  remainder = remainder === interval ? 0 : remainder;

  return startOfMinute(addMinutes(jsDate, remainder));
}

export function nearestMinutes(interval, someMoment) {
  const roundedMinutes =
    Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(interval, someMoment) {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function RoundDownToClosestMinute({ jsDate, interval }) {
  // if 11:05 -> 11:00
  const roundedMinutes = Math.floor(getMinutes(jsDate) / interval) * interval;
  return startOfMinute(setMinutes(jsDate, roundedMinutes));
}

export function ConvertMinutesIntoDayHoursAndMinutes(minutes) {
  let days = Math.floor(minutes / (60 * 24));
  minutes -= days * (60 * 24);
  let hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  let dayString = days >= 1 ? String(days) + pluralize(days, " day") + " " : "";
  let hourString =
    hours >= 1 ? String(hours) + pluralize(hours, " hour") + " " : "";
  let minuteString =
    minutes >= 1 ? String(minutes) + pluralize(minutes, " minute") : "";

  return dayString + hourString + minuteString;
}

export function createLocationUrl(location) {
  if (!location || location.length === 0 || typeof location !== 'string') {
    return;
  }

  let wordsArray = location.split(" ");
  let filteredArray = wordsArray.filter((a) => isUrl(a));

  if (filteredArray.length === 1) {
    let locationURL = filteredArray[0];
    if (!locationURL.toLowerCase().includes("http")) {
      locationURL = "https://" + locationURL;
    }

    return locationURL;
  }

  let googleMapsDefaultSearchString = "https://www.google.com/maps/search/";
  let encodedValue = encodeURIComponent(location);

  let googleMapsURL = googleMapsDefaultSearchString + encodedValue;

  return googleMapsURL;
}

export function getPhoneNumberFromText(text, isWhatsApp = false) {
  if (!text || !isTypeString(text) || isUrl(text)) {
    return null;
  }

  let guessedRegionCode = PhoneNumber(text).getRegionCode() || "US";
  let pn = new PhoneNumber(text, guessedRegionCode);
  if (!pn.isValid()) {
    return null;
  }

  return isWhatsApp
    ? `${WHATSAPP_BASE_URL}${getPhoneNumberString(text, guessedRegionCode)}`
    : pn.getNumber("rfc3966");
}

export function OpenGoogleMapsLocation(location, currentUser) {
  if (!location) {
    return;
  }

  let guessedRegionCode = PhoneNumber(location).getRegionCode() || "US";
  let pn = new PhoneNumber(location, guessedRegionCode);

  if (pn.isValid() && !isUrl(location)) {
    if (isPhoneConferencingWhatsApp(currentUser)) {
      OpenLink(
        `${WHATSAPP_BASE_URL}${getPhoneNumberString(location, guessedRegionCode)}`
      );
      return;
    }
    OpenLink(pn.getNumber("rfc3966"));
    return;
  }

  let locationUrl = createLocationUrl(location);

  if (!locationUrl) {
    return;
  } else if (locationUrl.includes("zoom.us")) {
    openConferencingURL(locationUrl);
    return;
  }

  OpenLink(locationUrl);
}

export function shadeInputColor(color) {
  return ShadeColor(color, 20);
}

export function determineEventBackgroundColor(param) {
  const {
    status,
    color,
    otherStatuses,
    onlyPersonAttending,
    isDarkMode,
    event,
  } = param;
  if (isAllDayWorkLocationEvent(event)) {
    return "transparent";
  }

  if (onlyPersonAttending) {
    return getDefaultBackgroundColor(isDarkMode);
  } else if (isTemporaryAIEvent(event)) {
    return "rgba(80, 227, 194, 0.6)"; // "#50E3C2"
  } else if (isOutstandingSlotEvent(event)) {
    return undefined;
  } else if (otherStatuses === availability) {
    return StyleConstants.selectAvailabilityColor;
  } else if (
    [
      NO_RESPONSE_NEEDED,
      attendee_event_attending,
      temporary,
      attendee_event_tentative,
      self_response_confirmed,
    ].includes(status)
    || shouldHideEventResponseOptions(event)
  ) {
    return color;
  } else {
    return getDefaultBackgroundColor(isDarkMode);
  }
}

export function IsStartDateAfterEndDate(start, end) {
  return isAfterDay(start, end);
}

export function IsEventStartAfterEventEnd(param) {
  let {
    startDate,
    endDate,
    startTime,
    endTime,
    startTimeZone,
    endTimeZone,
    currentTimeZone,
  } = param;

  if (isAfterDay(startDate, endDate)) {
    return true;
  } else {
    // want compare date and time in one time zone to another
    // EXAMPLE:
    // if start date is April 16th 2021
    // start time is 9am and time zone is EDT
    // end date is April 16th 2021
    // end time is 8am and time zone is PDT
    // The start time here is not after end time because 8am PDT is actually 11am EDT which is still before end time

    // Algorithm:
    // 1. combine date and time
    // 2. find offset
    // 3. add offset

    let start = getTimeInAnchorTimeZone(
      CombineDateAndTimeJSDate(startDate, startTime),
      startTimeZone || currentTimeZone
    );

    let end = getTimeInAnchorTimeZone(
      CombineDateAndTimeJSDate(endDate, endTime),
      endTimeZone || currentTimeZone
    );

    return isAfterMinute(start, end);
  }
}

export function getTimeInAnchorTimeZone(
  jsDate,
  eventTimeZone,
  anchorTimeZone = null,
) {
  // if anchor time zone is pdt and jsDate is 3pm and eventTimeZone is edt
  // this is not to translate 3pm est -> pdt
  // rather, this is setting 3pm in pdt
  // need to do this since js date does not allow you to set time zone but only does local time zone calculations
  if (eventTimeZone === (anchorTimeZone || guessTimeZone())) {
    return jsDate;
  }

  const minutesDifference = minDifferentBetweenTimeZones(
    anchorTimeZone || guessTimeZone(),
    eventTimeZone || guessTimeZone(),
    jsDate
  );

  return addMinutes(jsDate, minutesDifference);
}

export function ValidateEventData(data, currentTimeZone) {
  if (
    data.mode !== TEMPLATE &&
    data.mode !== UPDATE_TEMPLATE &&
    IsStartDateAfterEndDate(data.eventStartDate, data.eventEndDate)
  ) {
    return {
      passValidation: false,
      errorMessage: "The event date ends after the start of the event",
    };
  } else if (
    data.mode !== TEMPLATE &&
    data.mode !== UPDATE_TEMPLATE &&
    !data.allDay &&
    IsEventStartAfterEventEnd({
      startDate: data.eventStartDate,
      endDate: data.eventEndDate,
      startTime: data.eventStartTime,
      endTime: data.eventEndTime,
      startTimeZone: data.startTimeZone,
      endTimeZone: data.endTimeZone,
      currentTimeZone,
    })
  ) {
    return {
      passValidation: false,
      errorMessage: "The event time ends after the start of the event",
    };
  }

  return { passValidation: true, errorMessage: "" };
}

export function ConvertDayOfWeekIntegerIntoWeekdayConstant(
  integer,
  weekOfMonth = null
) {
  // If it's monthly recurrence, then we need to return the week of the month which is the offset (+weekOfMonth), otherwise, return the day
  // Note: Moment starts week at Sunday (0) whereas rrule starts week at 1 so we have to subtract -1 if we're converting from moment
  switch (integer) {
    case 0:
      if (weekOfMonth) {
        return RRule.MO.nth(+weekOfMonth);
      } else {
        return RRule.MO;
      }
    case 1:
      if (weekOfMonth) {
        return RRule.TU.nth(+weekOfMonth);
      } else {
        return RRule.TU;
      }
    case 2:
      if (weekOfMonth) {
        return RRule.WE.nth(+weekOfMonth);
      } else {
        return RRule.WE;
      }
    case 3:
      if (weekOfMonth) {
        return RRule.TH.nth(+weekOfMonth);
      } else {
        return RRule.TH;
      }
    case 4:
      if (weekOfMonth) {
        return RRule.FR.nth(+weekOfMonth);
      } else {
        return RRule.FR;
      }
    case 5:
      if (weekOfMonth) {
        return RRule.SA.nth(+weekOfMonth);
      } else {
        return RRule.SA;
      }
    case 6:
      if (weekOfMonth) {
        return RRule.SU.nth(+weekOfMonth);
      } else {
        return RRule.SU;
      }
    default:
      return null;
  }
}

export function ConvertStringToWeekDayConstant(string) {
  // This function returns the constant that rRule uses to determine how frequently events should repeat
  switch (string) {
    case daysString:
      return RRule.DAILY;
    case weeksString:
      return RRule.WEEKLY;
    case monthsString:
      return RRule.MONTHLY;
    case yearsString:
      return RRule.YEARLY;
    default:
      return null;
  }
}

export function ConvertListIntoObject(list) {
  let array = [];
  let temporaryObject;

  for (let i = 0; i < list.length; i++) {
    temporaryObject = { value: list[i], label: list[i] };

    array.push(temporaryObject);
  }

  return array;
}

export function initializeCommandCenterIndex(options) {
  let index = FlexSearch.create({
    encode: "icase",
    token: "strict",
    depth: 2,
    threshold: 1,
    resolution: 3,
    doc: {
      id: "key",
      field: ["searchQueries", "title"],
    },
  });

  index.add(options);

  return index;
}

export function convertDateIntoEpochUnixWeek(date, useFloor = false) {
  if (!date) {
    return 0;
  }

  let unixDecimal;

  if (moment.isMoment(date)) {
    unixDecimal = moment(date).unix() / (60 * 60 * 24);
  } else if (
    (typeof date !== "string" || !date instanceof String) &&
    isValid(date)
  ) {
    // already in js date
    unixDecimal = getUnixTime(date) / (60 * 60 * 24);
  } else {
    // parseISO takes date form from backend (iso format)
    let parseDate;
    if (isValid(parseISO(date))) {
      parseDate = parseISO(date);
    } else if (isValid(parse(date, "yyyy-MM-dd", new Date()))) {
      // parses form of 2021-03-21
      parseDate = startOfDay(parse(date, "yyyy-MM-dd", new Date()));
    } else if (isValid(parse(date, "MMMM d, y", new Date()))) {
      // parse form of April 5, 2021
      parseDate = startOfDay(parse(date, "MMMM d, y", new Date()));
    } else {
      // still invalid -> nuclear option -> moment
      unixDecimal = moment(date).unix();
    }

    unixDecimal = getUnixTime(parseDate) / (60 * 60 * 24);
  }

  return useFloor ? Math.floor(unixDecimal) : Math.ceil(unixDecimal);
}

export function formatTimeForBackendJsDate(jsDate, weekStart, beginningOfWeek) {
  return beginningOfWeek
    ? getFirstDayOfWeekJsDate(jsDate, weekStart).toISOString()
    : getLastDayOfWeekJsDate(jsDate, weekStart).toISOString();
}

export function determineTemplateSummary(template) {
  const { summary, visibility } = template;

  if (summary) {
    return summary;
  } else if (visibility === visibilityPrivate) {
    return busySummary;
  } else if (template.raw_json?.summary) {
    return template.raw_json?.summary
  } else {
    return noTitle;
  }
}

export function determineEventTitle(event) {
  const eventTitle = getEventTitle(event);

  if (eventTitle) {
    return eventTitle;
  } else if (isEventPrivate(event)) {
    return busySummary;
  } else {
    return noTitle;
  }
}

export function formatEndDateJsDate(endDate) {
  // end date string is from google event, usually in the form of event.end.date
  const formatEnd = (jsDate) => {
    return set(subDays(jsDate, 1), { hours: 1 });
  };

  if (isValidJSDate(endDate)) {
    return formatEnd(endDate);
  }

  return formatEnd(parseISO(endDate));
}

export function createUniqueEtag(event, userEventId = null) {
  // userEventId is passed in for events that does not have user_event_id (cmd j events)
  if (!getEventEtag(event)) {
    return null;
  }

  // get rid of the extra pair of quotes in etag
  return `${removeCommaFromString(getEventEtag(event))}_${userEventId || getEventUserEventID(event)
    }`;
}

export function removeCommaFromString(string) {
  return string?.replace(/['"]+/gim, "") || "";
}

export function getEventStartEndFromRawJSONTimeString({
  event,
  currentTimeZone,
  eventStart,  // jsDate
  eventEnd, // jsDate
}) {
  if (isOutlookEvent(event)) {
    if (isAllDayOutlookEvent(event)) {
      return {
        eventStartJSDate: eventStart,
        eventEndJSDate: formatEndDateJsDate(eventEnd)
      }
    }

    return {
      eventStartJSDate: convertToTimeZone(eventStart, { timeZone: currentTimeZone }),
      eventEndJSDate: convertToTimeZone(eventEnd, { timeZone: currentTimeZone })
    };
  }

  return {
    eventStartJSDate: getEventStartAllDayDate(event)
      ? eventStart
      : convertToTimeZone(eventStart, { timeZone: currentTimeZone }),
    eventEndJSDate: getEventEndAllDayDate(event)
      ? formatEndDateJsDate(eventEnd)
      : convertToTimeZone(eventEnd, { timeZone: currentTimeZone })
  };
}

function getDefaultEndTime(event, eventEnd) {
  if (isOutlookEvent(event)) {
    return getEventEndValue(event);
  } else {
    return isAllDayEvent(event)
      ? format(subDays(eventEnd, 1), "yyyy-MM-dd")
      : getEventEndDateTime(event)
  }
}

export function formatEventForReactBigCalendar({
  event,
  currentTimeZone,
  calendarId,
  isPreviewOutlookEvent,
}) {
  const isAllDay = isAllDayEvent(event);

  if (isValidEvent(event)) {
    const { eventStart, eventEnd } = getEventStartAndEnd(event)

    const {
      eventStartJSDate,
      eventEndJSDate
    } = getEventStartEndFromRawJSONTimeString({
      event,
      currentTimeZone,
      eventStart,
      eventEnd,
    });

    return {
      ...event,
      summaryUpdatedWithVisibility: determineEventTitle(event),
      eventStart: eventStartJSDate,
      defaultStartTime: getEventStartValue(event),
      defaultEndTime: getDefaultEndTime(event, eventEnd),
      startTimeZone: getEventStartTimeZone(event),
      endTimeZone: getEventEndTimeZone(event),
      eventEnd: eventEndJSDate,
      rbcEventEnd: createRBCEventEnd(event, currentTimeZone, eventStart, eventEnd),
      allDay: isAllDay,
      epochUnixWeek: convertDateIntoEpochUnixWeek(eventStart),
      epochUnixWeekEnd: convertDateIntoEpochUnixWeek(eventEnd),
      displayAsAllDay:
        isAllDay || DaysDifferenceJSDate(eventStart, eventEnd, currentTimeZone),
      user_calendar_id: getEventUserCalendarID(event) || calendarId,
      conferenceUrl: GetConferenceURL(event),
      calendarId: calendarId || getEventUserCalendarID(event),
      uniqueEtag: createUniqueEtag(event),
      isWorkLocationEvent: isWorkplaceEvent(event),
      isPreviewOutlookEvent,
    };
  } else {
    return event;
  }
}

export function formatForEventTemplates(template) {
  const isAllDayEvent = !!getTemplateStartDate(template);

  if (isValidEventTemplate(template)) {
    const templateStartDate = getTemplateStartDate(template);
    const templateStartDateTime = getTemplateStartDateTime(template);
    const templateStartTimeZone = getTemplateStartTimeZone(template);

    const templateEndDate = getTemplateEndDate(template);
    const templateEndDateTime = getTemplateEndDateTime(template);
    const templateEndTimeZone = getTemplateEndTimeZone(template);

    return produce(template, (draftState) => {
      draftState["summaryUpdatedWithVisibility"] =
        determineTemplateSummary(template);
      draftState["eventStart"] = templateStartDate
        ? templateStartDate
        : mutateUTCTimeToDesiredTimeZoneTime(
          templateStartDateTime,
          templateStartTimeZone
        );
      draftState["defaultStartTime"] = getTemplateStartValue(template);
      draftState["defaultEndTime"] = templateEndDate
        ? format(addDays(parseISO(templateEndDate), -1), "yyyy-MM-dd")
        : templateEndDateTime;
      draftState["startTimeZone"] = templateStartTimeZone;
      draftState["endTimeZone"] = templateEndTimeZone;
      draftState["eventEnd"] = templateEndDate
        ? templateEndDate
        : mutateUTCTimeToDesiredTimeZoneTime(
          templateEndDateTime,
          templateEndTimeZone
        );
      draftState["rbcEventEnd"] = createTemplateRBCEventEnd(
        template,
        templateEndTimeZone
      );
      draftState["allDay"] = isAllDayEvent;
      draftState["displayAsAllDay"] =
        isAllDayEvent ||
        DaysDifferenceJSDate(
          parseISO(templateStartDate || templateStartDateTime),
          parseISO(templateEndDate || templateEndDateTime)
        );
    });
  } else {
    return produce(template, (draftState) => {
      draftState["summaryUpdatedWithVisibility"] =
        determineTemplateSummary(template);
    });
  }
}

export function FormatIntoJSDate(event, currentTimeZone) {
  if (isValidEvent(event)) {
    const { eventStart, eventEnd } = getEventStartAndEnd(event)

    const {
      eventStartJSDate,
      eventEndJSDate
    } = getEventStartEndFromRawJSONTimeString({
      event,
      currentTimeZone,
      eventStart,
      eventEnd
    });

    return {
      ...event,
      eventStart: eventStartJSDate,
      eventEnd: eventEndJSDate,
      rbcEventEnd: createRBCEventEnd(event, currentTimeZone, eventStart, eventEnd)
    };
  } else {
    return event;
  }
}

export function createRBCEventEnd(event, currentTimeZone, jsStart, jsEnd) {
  const isAllDay = isAllDayEvent(event);
  const eventEndDate = getEventEndAllDayDate(event);
  if (eventEndDate) {
    return formatEndDateJsDate(eventEndDate);
  } else if (isOutlookEvent(event) && isAllDayEvent(event)) {
    return jsEnd;
  } else {
    const eventStartDateTime = getEventStartDateTime(event);
    const eventEndDateTime = getEventEndDateTime(event);

    const jsDateStart = jsStart || parseISO(eventStartDateTime);
    const jsDateEnd = jsEnd || parseISO(eventEndDateTime);

    if (differenceInMinutes(jsDateEnd, jsDateStart) < 15) {
      // set minimum of 15 minutes
      const minimumEndTime = addMinutes(jsDateStart, 15);
      return protectMidnightCarryOver(
        convertToTimeZone(minimumEndTime, { timeZone: currentTimeZone })
      );
    } else if (isAllDay) {
      // should only be for outlook since eventEndDate if case covers google case
      return jsEnd;
    } else {
      return protectMidnightCarryOver(
        convertToTimeZone(jsEnd, { timeZone: currentTimeZone })
      );
    }
  }
}

export function determineRBCEventEndWithEventStart(eventStart, eventEnd) {
  let startEndDiff = differenceInMinutes(eventEnd, eventStart);

  let rbcEventEnd = eventEnd;

  if (startEndDiff < 15) {
    rbcEventEnd = addMinutes(eventStart, 15);
  }

  return rbcEventEnd;
}

export function DaysDifferenceJSDate(
  jsDateA,
  jsDateB,
  timeZone = null,
  displayMultiDate = false
) {
  if (displayMultiDate) {
    if (timeZone) {
      return !isSameDay(
        convertToTimeZone(jsDateB, { timeZone }),
        convertToTimeZone(jsDateA, { timeZone })
      );
    }

    return !isSameDay(jsDateB, jsDateA);
  }

  if (timeZone) {
    return differenceInDays(
      convertToTimeZone(jsDateB, { timeZone }),
      convertToTimeZone(jsDateA, { timeZone })
    );
  }

  return differenceInDays(jsDateB, jsDateA);
}

export function mutateUTCTimeToDesiredTimeZoneTime(time, timeZone) {
  // time can be iso string or datejs
  let newTime;

  if (timeZone) {
    newTime = convertToTimeZone(time, { timeZone });
  } else {
    newTime = convertToTimeZone(time, { timeZone: UTC_TIME_ZONE });
  }

  return newTime;
}

export function CombineDateAndTimeJSDate(date, time) {
  // date and time should both be js date
  // new Date(year, month, day, hours, minutes, seconds, milliseconds)
  return startOfMinute(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    )
  );
}

export function CreateJSDate(date) {
  let parts = date.split("-");

  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  let mydate = new Date(parts[0], parts[1] - 1, parts[2], 1);

  return mydate;
}

export function DoesEventHaveOtherAttendees(event, allCalendars) {
  const eventAttendees = getEventAttendees(event);
  if (!eventAttendees) {
    return false;
  }

  let email = getEmailBasedOnCalendarId(event, allCalendars);

  const attendees =
    eventAttendees.length > 0 ? filterOutResourceAttendees(eventAttendees) : [];

  if (attendees.length > 1) {
    return true;
  } else if (attendees.length === 1) {
    return attendees[0].email !== email;
  } else {
    return false;
  }
}

export function DoesAttendeesObjectHaveOtherAttendees(attendeesObject, email) {
  if (!attendeesObject || attendeesObject.length === 0) {
    return false;
  } else if (attendeesObject.length > 1) {
    return true;
  } else if (attendeesObject.length === 1) {
    return attendeesObject[0].email !== email;
  } else {
    return false;
  }
}

export function HoursDifferenceBetweenTimeZones(
  firstTimeZone = guessTimeZone(),
  secondTimeZone = guessTimeZone(),
  inputTime = null
) {
  return (
    minDifferentBetweenTimeZones(firstTimeZone, secondTimeZone, inputTime) / 60
  );
}

export function minDifferentBetweenTimeZones(
  firstTimeZone = guessTimeZone(),
  secondTimeZone = guessTimeZone(),
  inputTime = null
) {
  // set to end of day, otherwise some local time zones change time zone at 2am or 3pm
  const time = startOfHour(endOfDay(inputTime || new Date()));

  const firstTimeZoneTime = convertToTimeZone(time, { timeZone: firstTimeZone });
  const secondTimeZoneTime = convertToTimeZone(time, {
    timeZone: secondTimeZone,
  });

  return differenceInMinutes(firstTimeZoneTime, secondTimeZoneTime);
}

export function TurnCamelCaseIntoNormalString(string) {
  return string.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function getEventStatusColor(attendance) {
  switch (attendance) {
    case self_response_confirmed:
      return "#B8E986";
    case self_response_tentative:
      return "#F8E71C";
    default:
      return "#FC5343";
  }
}

export function MapRRuleFreqVariableToString(ruleFreq) {
  switch (ruleFreq) {
    case 0:
      return yearsString;
    case 1:
      return monthsString;
    case 2:
      return weeksString;
    case 3:
      return daysString;
    default:
      return null;
  }
}

export function DetermineHowRecurrenceEnds(rrule) {
  let endsOnType = never;
  let endsOnValue = null;

  if (rrule.count) {
    endsOnType = after;
    endsOnValue = rrule.count;
  } else if (rrule.until) {
    endsOnType = on;
    endsOnValue = rrule.until;
  }

  return [endsOnType, endsOnValue];
}

export function CreateRecurrentObject(rrule, eventStart) {
  let recurrence;

  if (!isEmptyObjectOrFalsey(rrule)) {
    recurrence = {
      interval: rrule.options.interval,
      freq: {
        value: MapRRuleFreqVariableToString(rrule.options.freq),
        label: MapRRuleFreqVariableToString(rrule.options.freq),
      },
      repeatInfoForMonthorWeek:
        MapRRuleFreqVariableToString(rrule.options.freq) === weeksString
          ? rrule.options.byweekday
          : rrule.options,
      endOnInformation: [
        DetermineHowRecurrenceEnds(rrule.options)[0],
        DetermineHowRecurrenceEnds(rrule.options)[1],
      ],
    };
  } else {
    recurrence = {
      interval: 1,
      freq: { value: weeksString, label: weeksString },
      repeatInfoForMonthorWeek: [parseInt(moment(eventStart).format("E")) - 1],
      endOnInformation: [never, null],
    };
  }

  return recurrence;
}

export function GetConferenceURL(event) {
  // if created by zoom integration in hangout
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  if (isZoomFromGSuiteIntegration(event)) {
    return determineNativeConferenceInfo(event).conferenceUrl;
  } else if (getEventConferenceURL(event)) {
    return getEventConferenceURL(event); // e.g. hangout link
  } else if (getEventConferenceData(event)) {
    return getConferenceURLFromNative(getEventConferenceData(event));
  } else {
    return pullURLFromEventLocationOrDescription(event);
  }
}

export function getConferenceURLFromNative(eventConferenceData) {
  if (!eventConferenceData?.entryPoints) {
    return;
  }

  const entryPoints = eventConferenceData.entryPoints;

  let videoLink;

  entryPoints.forEach((e) => {
    if (!videoLink && e.entryPointType === "video") {
      videoLink = e.uri;
    }
  });

  // Only check for phone if video not been set
  if (!videoLink) {
    entryPoints.forEach((e) => {
      if (!videoLink && e.entryPointType === "phone") {
        videoLink = e.uri;
      }
    });
  }

  return videoLink;
}

function pullURLFromEventLocationOrDescription(event) {
  // Different platforms have different ways of displaying conferencing URL
  // E.g. Microsfot teams hides url in <a href=
  const eventLocation = getEventLocation(event);
  const eventDescription = getEventDescription(event);

  const locationURL = extractConferenceURLFromString(eventLocation);
  if (locationURL && doesURLContainConferencingURL(locationURL)) {
    return locationURL;
  }

  // matches href attribute values of <a>
  const descriptionURLLinkTag = extractURLFromLinkTag(eventDescription);
  if (
    descriptionURLLinkTag &&
    doesURLContainConferencingURL(descriptionURLLinkTag)
  ) {
    return descriptionURLLinkTag;
  }

  const descriptionURL = extractConferenceURLFromString(eventDescription);
  if (descriptionURL && doesURLContainConferencingURL(descriptionURL)) {
    return descriptionURL;
  }

  const descriptionTag = extractConferencingURLfromTags(eventDescription);
  if (descriptionTag) {
    return descriptionTag;
  }

  if (getPhoneNumberFromText(eventLocation)) {
    // phone number in location
    return getPhoneNumberFromText(eventLocation);
  }

  return null;
}

export function doesURLContainConferencingURL(string) {
  if (!string || !isUrl(string)) {
    return false;
  }

  const loweredCase = string.toLowerCase();
  return CONFERENCING_OPTIONS_ARRAY.some(
    (c) =>
      loweredCase.includes(c) &&
      checkIfGoogleConferencingForHangoutAndMeet(loweredCase, c)
  );
}

export function checkIfGoogleConferencingForHangoutAndMeet(
  string,
  conferencingType = GOOGLE_CONFERENCING
) {
  if (conferencingType !== GOOGLE_CONFERENCING) {
    return true;
  }

  if (!string) {
    return false;
  }

  let loweredCase = string.toLowerCase();

  return (
    loweredCase.includes(GOOGLE_HANGOUT) || loweredCase.includes(GOOGLE_MEET)
  );
}

function extractConferencingURLfromTags(text) {
  if (!text || text.length === 0 || !isTypeString(text)) {
    return null;
  }

  // This regex captures URLs that are enclosed by angle brackets
  const urlRegex = /<([^>]+)>/g;

  // Use the matchAll method to get an iterator for all matches
  let matches = text.matchAll(urlRegex);
  let urls = Array.from(matches, m => m[1]);
  return urls.find(url => doesURLContainConferencingURL(url));
}

function extractURLFromLinkTag(string) {
  if (!string || string.length === 0) {
    return;
  }

  // matches: ['<a href="https://www.vimcal.com/', ...]
  const matches = string.match(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g);

  if (isEmptyArray(matches)) {
    return null;
  }

  const urls = matches
    .filter(match => !!match)
    .map(match => match.substring(9, match.length - 1));

  return urls.find(c => doesURLContainConferencingURL(c));
}

export function replaceStringInLinkTag(string) {
  if (!string || string.length === 0) {
    return "";
  }

  const matches = string.match(/\<(.*?)\>/g);

  if (!isEmptyArray(matches)) {
    const filteredMatches = matches.filter(match => !!match);
    let updatedString = string;

    filteredMatches.forEach((m) => {
      const matchWithoutBracket = m.substring(1, m.length - 1);

      if (isUrl(matchWithoutBracket)) {
        updatedString = updatedString.replace(
          m,
          `&lt<a href=${matchWithoutBracket} target="_blank">${matchWithoutBracket}</a>&gt`
        );
      }
    });

    return updatedString;
  } else {
    // No matches
    return string;
  }
}

export function extractConferenceURLFromString(string) {
  if (!string || string.length === 0 || !isTypeString(string)) {
    return;
  }

  let searchString = string
    .replace(/(<([^>]+)>)/gi, " ")
    .replace("<div", " ")
    .replace("<span", " ")
    .replace("<a", " ")
    .replace("<br", " ")
    .replace("<wbr", " ");

  let conferenceURL;

  CONFERENCING_OPTIONS_ARRAY.forEach((c) => {
    if (
      !conferenceURL &&
      searchString.toLowerCase().includes(c) &&
      checkIfGoogleConferencingForHangoutAndMeet(searchString, c)
    ) {
      let matches = searchString
        .split(/[\s'"`]+/)
        .filter((e) => e.toLowerCase().includes(c));

      matches.forEach((match, index) => {
        let trimmed = match.trim();

        if (
          !conferenceURL &&
          c === ZOOM_CONFERENCING &&
          stringIncludesZoomURLCombinations(trimmed)
        ) {
          conferenceURL = trimmed;
        } else if (
          !conferenceURL &&
          c !== ZOOM_CONFERENCING &&
          trimmed.toLowerCase().includes(c)
        ) {
          conferenceURL = trimmed;
        }
      });
    }
  });
  if (string.includes(WHATSAPP_BASE_URL))  {
    const whatsAppURL = extractWhatsappURL(string);
    if (whatsAppURL) {
      return whatsAppURL;
    }
  }

  if (conferenceURL) {
    conferenceURL = conferenceURL.includes("http")
      ? conferenceURL
      : "https://" + conferenceURL;

    if (conferenceURL.charAt(conferenceURL.length - 1) === ",") {
      conferenceURL = conferenceURL.substring(0, conferenceURL.length - 1);
    }

    return conferenceURL;
  }

  return null;
}

export function determineNativeConferenceInfo(event) {
  const eventConferenceData = getEventConferenceData(event);
  if (isEmptyObjectOrFalsey(eventConferenceData) || !eventConferenceData.entryPoints) {
    return;
  }

  let conferenceInfo = { fromGSuiteIntegration: true, phone: [] };

  let conferenceEntryWays = eventConferenceData.entryPoints;

  conferenceEntryWays.forEach((c) => {
    if (c.entryPointType && c.entryPointType.toLowerCase().includes("video")) {
      conferenceInfo.conferenceUrl = c.uri;
      conferenceInfo.id = c.meetingCode;
      conferenceInfo.password = c.password;
      conferenceInfo.passcode = c?.passcode;
    } else if (c.entryPointType === "phone") {
      conferenceInfo.phone = conferenceInfo.phone.concat(c);
    } else if (c.entryPointType === "more" && c.uri) {
      let moreInfoText = "Joining instructions";

      if (
        eventConferenceData.conferenceSolution?.name
          ?.toLowerCase()
          .includes("google")
      ) {
        moreInfoText = "More phone numbers";
      }

      conferenceInfo.moreInfo = { text: moreInfoText, url: c.uri };
    } else if (c.uri && c.uri.toLowerCase().includes("stream.meet")) {
      conferenceInfo.stream = {
        text: "Watch live stream",
        label: c.label,
        url: c.uri,
      };
    }
  });

  if (eventConferenceData.notes) {
    conferenceInfo.notes = eventConferenceData.notes;
  }

  const conferenceDataName = eventConferenceData.conferenceSolution?.name;
  if (conferenceDataName) {
    let joinText = conferenceDataName;

    if (!joinText.toLowerCase().includes("join")) {
      joinText = "Join " + joinText;
    }

    conferenceInfo.joinText = joinText;
  }

  return conferenceInfo;
}

export function isHangoutGSuiteIntegration(conferenceData) {
  const conferenceDataName = conferenceData?.conferenceSolution?.name;
  return (
    (isNameHangoutOrMeet(conferenceDataName) &&
      conferenceData?.entryPoints?.length > 0) ||
    !!conferenceData?.createRequest
  );
}

export function isNameHangoutOrMeet(url) {
  if (!url) {
    return false;
  }

  const loweredCaseName = url.toLowerCase();

  return (
    loweredCaseName.includes("hangout") ||
    loweredCaseName.includes("google") ||
    loweredCaseName.includes("meet")
  );
}

export function isUrlHangoutOrMeet(url) {
  let loweredCaseName = url.toLowerCase();

  return (
    loweredCaseName.includes("hangout") || loweredCaseName.includes("google")
  );
}

export function hasZoom(eventDescription) {
  if (eventDescription.includes(zoomUS)) {
    let matches = eventDescription.match(/\bhttps?:\/\/\S+/gi) || [];

    let zoomURL;

    matches.forEach((match, index) => {
      let loweredCase = match
        .toLowerCase()
        .replace(/(<([^>]+)>)/gi, "")
        .replace("<div", "")
        .replace("<span", "")
        .replace("<a", "")
        .replace("<br", "")
        .replace("<wbr", "");

      if (stringIncludesZoomURLCombinations(loweredCase) && !zoomURL) {
        zoomURL = loweredCase;
      }
    });

    if (zoomURL) {
      return true;
    }
  }

  return false;
}

export function stringIncludesZoomURLCombinations(string) {
  if (!string) {
    return;
  }

  let loweredCase = string.toLowerCase();

  return (
    loweredCase &&
    loweredCase.includes("zoom.us") &&
    ZOOM_SEARCH_STRINGS.some((s) => loweredCase.includes(s))
  );
}

export function GetHoursAndMinuteFromTimeString(time) {
  let timeSplit = time.split(":");

  let isTimeInThePM = time.toLowerCase().includes("pm");

  let hour = 0;
  let minute = 0;

  if (timeSplit[1]) {
    if (parseInt(timeSplit[0]) === 12) {
      hour = isTimeInThePM ? 12 : 0;
      minute = parseInt(timeSplit[1].substr(0, 2));
    } else {
      hour = isTimeInThePM
        ? parseInt(timeSplit[0]) + 12
        : parseInt(timeSplit[0]);
      minute = parseInt(timeSplit[1].substr(0, 2));
    }
  }

  return { hour, minute };
}

export function sortEventsJSDate(a, b, reverse = false) {
  if (isBeforeMinute(a.eventStart, b.eventStart)) {
    return reverse ? 1 : -1;
  } else if (isBeforeMinute(b.eventStart, a.eventStart)) {
    // if do not have this condition, breaks on firefox
    return reverse ? -1 : 1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isAfterMinute(a.eventEnd, b.eventEnd)
  ) {
    return reverse ? 1 : -1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isAfterMinute(b.eventEnd, a.eventEnd)
  ) {
    return reverse ? -1 : 1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isSameMinute(a.eventEnd, b.eventEnd) &&
    a.uniqueEtag > b.uniqueEtag
  ) {
    return reverse ? 1 : -1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isSameMinute(a.eventEnd, b.eventEnd) &&
    b.uniqueEtag > a.uniqueEtag
  ) {
    return reverse ? -1 : 1;
  }

  return 0;
}

export function SortEvents(a, b, customKey = null, reverse = false) {
  let eventA = moment(a[customKey || "eventStart"]);
  let eventB = moment(b[customKey || "eventStart"]);

  if (eventA.isBefore(eventB)) {
    return reverse ? 1 : -1;
  }
  if (eventB.isBefore(eventA)) {
    return reverse ? -1 : 1;
  }

  // names must be equal
  return 0;
}

export function SortArrayByDate(a, b) {
  let eventA = moment(a);
  let eventB = moment(b);

  if (eventA.isBefore(eventB, "day")) {
    return -1;
  }
  if (eventB.isBefore(eventA, "day")) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function sortDomainUsers(list, key, domain) {
  if (!domain) {
    return list || [];
  }

  let listWithDomain = [];
  let listWithoutDomain = [];
  let lowerCaseDomain = domain.toLowerCase();

  list.forEach((e) => {
    if (e[key] && e[key].toLowerCase().indexOf(lowerCaseDomain) >= 0) {
      listWithDomain = listWithDomain.concat(e);
    } else {
      listWithoutDomain = listWithoutDomain.concat(e);
    }
  });

  listWithDomain = listWithDomain.concat(listWithoutDomain);

  return listWithDomain;
}

export function SortName(a, b, customKey = null, reverse = false) {
  let nameA = a[customKey || "name"];
  let nameB = b[customKey || "name"];

  if (nameA < nameB) {
    return reverse ? 1 : -1;
  }
  if (nameB > nameA) {
    return reverse ? -1 : 1;
  }

  // names must be equal
  return 0;
}

export function TimeDifferenceBetweenTimeStartAndTimeEnd(start, end) {
  if (!start || !end) {
    return [0.5, 0];
  }

  let momentStart = moment(start).startOf("minute");
  let momentEnd = moment(end).startOf("minute");

  let duration = moment.duration(momentEnd.diff(momentStart));
  let hours = duration.asHours();
  let minutes = duration.asMinutes() - duration.asHours() * 60;

  return [hours, minutes];
}

export function PutCommaBetweenWordInString(word) {
  return word.split(" ").join(", ");
}

export function ReplaceSpaceWithUnderscore(string) {
  return string.replace(/ /g, "_");
}

export function createTimeZoneAbbrevationObject() {
  if (_staticTimeZoneAbbreviation[format(new Date(), "P")]) {
    return _staticTimeZoneAbbreviation[format(new Date(), "P")];
  }

  // creates object with time zone abbreviation as key and the timezone as the object
  let otherTimeZone = IANNA_TIME_ZONES;

  otherTimeZone = otherTimeZone.filter((t) => !POPULAR_TIME_ZONES.includes(t));

  let defaultList = POPULAR_TIME_ZONES.concat(otherTimeZone);

  let timeZoneObject = [];

  defaultList.forEach((t) => {
    timeZoneObject = timeZoneObject.concat({
      abbreviation: createAbbreviationForTimeZone(t).toLowerCase(),
      value: t,
      label: AddAbbrevationToTimeZone({timeZone: t}),
    });
  });

  const otherAbbreviatedTimeZone = [
    {
      abbreviation: "pt",
      value: "America/Los_Angeles",
      label: AddAbbrevationToTimeZone({timeZone: "America/Los_Angeles"}),
    },
    {
      abbreviation: "ct",
      value: "America/Chicago",
      label: AddAbbrevationToTimeZone({timeZone:"America/Chicago"}),
    },
    {
      abbreviation: "mt",
      value: "America/Denver",
      label: AddAbbrevationToTimeZone({timeZone:"America/Denver"}),
    },
    {
      abbreviation: "et",
      value: "America/New_York",
      label: AddAbbrevationToTimeZone({timeZone:"America/New_York"}),
    },
    {
      abbreviation: "pacific",
      value: "America/Los_Angeles",
      label: AddAbbrevationToTimeZone({timeZone:"America/Los_Angeles"}),
    },
    {
      abbreviation: "central",
      value: "America/Chicago",
      label: AddAbbrevationToTimeZone({timeZone:"America/Chicago"}),
    },
    {
      abbreviation: "mountain",
      value: "America/Denver",
      label: AddAbbrevationToTimeZone({timeZone:"America/Denver"}),
    },
    {
      abbreviation: "eastern",
      value: "America/New_York",
      label: AddAbbrevationToTimeZone({timeZone:"America/New_York"}),
    },
  ];

  const USTimeZones = [
    {
      abbreviation: "pst",
      value: "America/Los_Angeles",
      label: AddAbbrevationToTimeZone({timeZone:"America/Los_Angeles"}),
    },
    {
      abbreviation: "cst",
      value: "America/Chicago",
      label: AddAbbrevationToTimeZone({timeZone:"America/Chicago"}),
    },
    {
      abbreviation: "mst",
      value: "America/Denver",
      label: AddAbbrevationToTimeZone({timeZone:"America/Denver"}),
    },
    {
      abbreviation: "edt",
      value: "America/New_York",
      label: AddAbbrevationToTimeZone({timeZone:"America/New_York"}),
    },
    {
      abbreviation: "pdt",
      value: "America/Los_Angeles",
      label: AddAbbrevationToTimeZone({timeZone:"America/Los_Angeles"}),
    },
    {
      abbreviation: "cdt",
      value: "America/Chicago",
      label: AddAbbrevationToTimeZone({timeZone:"America/Chicago"}),
    },
    {
      abbreviation: "mdt",
      value: "America/Denver",
      label: AddAbbrevationToTimeZone({timeZone:"America/Denver"}),
    },
    {
      abbreviation: "edt",
      value: "America/New_York",
      label: AddAbbrevationToTimeZone({timeZone:"America/New_York"}),
    },
  ];

  timeZoneObject = otherAbbreviatedTimeZone
    .concat(USTimeZones)
    .concat(timeZoneObject);
  _staticTimeZoneAbbreviation[format(new Date(), "P")] = timeZoneObject;

  return timeZoneObject;
}

export function CreateTimeZoneList(currentTz) {
  if (_staticTimeZoneList[format(new Date(), "P")]) {
    return _staticTimeZoneList[format(new Date(), "P")];
  }

  let currentTimeZone = isEmptyObjectOrFalsey(currentTz) ? guessTimeZone() : currentTz;

  let defaultList = IANNA_TIME_ZONES;

  let popularTimeZone = POPULAR_TIME_ZONES.filter(
    (timeZone) => timeZone !== currentTimeZone
  );
  popularTimeZone.unshift(currentTimeZone);

  let defaultListWithoutPopularTimeZones = defaultList.filter(
    (timeZone) => !popularTimeZone.includes(timeZone)
  );

  let popularTimeZonesObject = popularTimeZone.map((timeZone) => {
    return { value: timeZone, label: AddAbbrevationToTimeZone({timeZone}) };
  });

  let defaultTimeZoneObject = defaultListWithoutPopularTimeZones.map(
    (timeZone) => {
      return { value: timeZone, label: AddAbbrevationToTimeZone({timeZone}) };
    }
  );

  let allTimeZones = popularTimeZonesObject.concat(defaultTimeZoneObject);

  let TIME_ZONE_SEARCH_QUERY_INDEX_KEYS = Object.keys(
    TIME_ZONE_SEARCH_QUERY_INDEX
  );

  let addAdditionalSearchQueries = [];
  allTimeZones.forEach((t) => {
    if (TIME_ZONE_SEARCH_QUERY_INDEX_KEYS.includes(t.value)) {
      let updatedObject = produce(t, (draftState) => {
        draftState.searchQueries = TIME_ZONE_SEARCH_QUERY_INDEX[t.value];
      });

      addAdditionalSearchQueries =
        addAdditionalSearchQueries.concat(updatedObject);
    } else {
      addAdditionalSearchQueries = addAdditionalSearchQueries.concat(t);
    }
  });

  _staticTimeZoneList = {}; // wipe out earlier entries
  _staticTimeZoneList[format(new Date(), "P")] = addAdditionalSearchQueries;

  return addAdditionalSearchQueries;
}

export function createAbbreviationForTimeZone(timeZone, date = null) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
  // breaks for Europe (CEST)
  const inputTimeZone = isValidTimeZone(timeZone) ? timeZone : guessTimeZone();
  const inputDate = isValidJSDate(date) ? date : new Date();
  try {
    const timeZoneAbbreviation = inputDate
      .toLocaleString(
        "en",
        safeGuardTimeZones({ timeZone: inputTimeZone, timeZoneName: "short" })
      )
      .split(" ")
      .pop();

    const isStringNumerical = stringHasNumber(timeZoneAbbreviation);

    if (isStringNumerical && TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX[timeZone]) {
      const isDayLightSavings = isDSTObserved(
        timeZone,
        isValidJSDate(date) ? date : new Date()
      );

      return isDayLightSavings
        ? TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX[timeZone].dayLightSavings
        : TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX[timeZone].standard;
    }

    return timeZoneAbbreviation;
  } catch (error) {
    handleError(error);
    devErrorPrint(error, "createAbbreviationForTimeZone_error");
    return "";
  }
}

export function formatCalendar(prevState, newState) {
  let calendar = newState.calendar;
  let last_synced_at = newState.last_synced_at;

  let lastSyncedAt = last_synced_at || prevState.lastSyncedAt;

  return {
    calendar,
    lastSyncedAt,
  };
}

export function constructIndexArray(events) {
  if (isEmptyArray(events)) {
    return [];
  }
  return events.map((event) => getEventUserEventID(event));
}

export function updateIndexOnEventDeletion(
  index,
  indexArray,
  eventsArray,
  removedEventUserEventId
) {
  let updatedEventsArray = eventsArray;
  let updatedIndexArray = indexArray;
  if (index >= 0 && indexArray[index] === removedEventUserEventId) {
    updatedEventsArray.splice(index, 1);
    updatedIndexArray.splice(index, 1);
  } else {
    updatedEventsArray = updatedEventsArray.filter(
      (event) => getEventUserEventID(event) !== removedEventUserEventId
    );

    updatedIndexArray = constructIndexArray(updatedEventsArray);
  }

  return { indexArray: updatedIndexArray, eventsArray: updatedEventsArray };
}

export function updateIndexOnEventCreation(
  indexArray,
  eventsArray,
  newEvent,
) {
  let updatedIndexArray = indexArray;
  let updatedEventsArray = eventsArray;

  if (indexArray.length === eventsArray.length) {
    let indexOfEvent = indexArray.indexOf(getEventUserEventID(newEvent));

    if (newEvent && indexOfEvent >= 0) {
      // event already exist and need to filter out original
      updatedEventsArray[indexOfEvent] = newEvent;

      updatedIndexArray[indexOfEvent] = getEventUserEventID(newEvent);
    } else {
      updatedEventsArray = eventsArray.concat(newEvent);
      updatedIndexArray = indexArray.concat(getEventUserEventID(newEvent));
    }
  } else {
    updatedIndexArray = constructIndexArray(updatedEventsArray);

    updatedEventsArray = updatedEventsArray.filter(
      (e) => getEventUserEventID(e) !== getEventUserEventID(newEvent)
    );

    updatedEventsArray = updatedEventsArray.concat(newEvent);
    updatedIndexArray = updatedIndexArray.concat(getEventUserEventID(newEvent));
  }

  return { indexArray: updatedIndexArray, eventsArray: updatedEventsArray };
}

export function updateIndexOnEventUpdate(
  index,
  indexArray,
  eventsArray,
  newEvent
) {
  if (
    index >= 0 &&
    eventsArray?.[index] &&
    getEventUserEventID(eventsArray[index]) === getEventUserEventID(newEvent)
  ) {
    // updating the same event, only replace if new event has a more recent update time provided by google
    // or if hold_details changed (specifically required for changing conferencing)
    if (
      isEventNewlyUpdated(eventsArray[index], newEvent) ||
      isEventHoldDetailsUpdated(eventsArray[index], newEvent)
    ) {
      eventsArray[index] = newEvent;
    }
  } else {
    // Error -> need to reconstruct eventsIndex
    eventsArray = eventsArray.filter(
      (event) => getEventUserEventID(event) !== getEventUserEventID(newEvent)
    );
    indexArray = constructIndexArray(eventsArray);

    eventsArray = eventsArray.concat(newEvent);
    indexArray = indexArray.concat(getEventUserEventID(newEvent));
  }

  return { indexArray: indexArray, eventsArray: eventsArray };
}

function isEventHoldDetailsUpdated(oldEvent, newEvent) {
  const oldHoldDetails = getEventHoldDetails(oldEvent);
  const newHoldDetails = getEventHoldDetails(newEvent);

  if (!oldHoldDetails && !newHoldDetails) {
    return false;
  }

  return Object.keys(oldHoldDetails).some(key => (
    oldHoldDetails[key] !== newHoldDetails[key]
  ));
}

export function isEventNewlyUpdated(oldEvent, newEvent) {
  // See if new event was updated after old event;
  let oldEventTimestamp = getEventUpdatedAt(oldEvent);
  let newEventTimestamp = getEventUpdatedAt(newEvent);

  if (oldEventTimestamp && newEventTimestamp) {
    if (oldEventTimestamp === newEventTimestamp) {
      // if strings match, by pass moment which is more expensive
      return false;
    }
    // default unit is millisecond

    return isAfter(parseISO(newEventTimestamp), parseISO(oldEventTimestamp));
  }

  // default true -> always update with new event
  return true;
}

export function AddAbbrevationToTimeZone({timeZone, returnNull = false, inputDate}) {
  if (!timeZone) {
    return returnNull ? null : "";
  }
  if (!isValidTimeZone(timeZone)) {
    return timeZone;
  }
  return (
    "(" +
    createAbbreviationForTimeZone(timeZone, inputDate) +
    ") " +
    timeZone.replace(/_/g, " ")
  );
}

export function ShadeColor(color, percent) {
  const cacheKey = `${color}_${percent}`;
  const cachedResult = _staticShadeColorIndex[cacheKey];
  if (cachedResult) {
    return cachedResult;
  }
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  const result = "#" + RR + GG + BB;
  _staticShadeColorIndex[cacheKey] = result;
  return result;
}

export function ComponentToHex(c) {
  let hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function RgbToHex(r, g, b) {
  return "#" + ComponentToHex(r) + ComponentToHex(g) + ComponentToHex(b);
}

export function RemoveElementInArrayIfContainsOtherwiseRemove(array, element) {
  if (array.includes(element)) {
    return array.filter((item) => item !== element);
  } else {
    return array.concat(element);
  }
}

export function GetLetterAndNumberHotKeyCombinations() {
  // 20 left hand keys
  const leftHandAlphabets = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "a",
    "s",
    "d",
    "f",
    "g",
    "z",
    "x",
    "c",
    "v",
    "b",
    2,
    3,
    4,
    5,
    6,
  ];

  // 12 right hand keys
  const rightHandAlphabets = [
    "n",
    "m",
    "h",
    "j",
    "k",
    "y",
    "u",
    "l",
    "p",
    7,
    8,
    9,
  ];

  const specialCharacters = ["-", `'`];

  const duplicateLetter = allLetterList.concat([2, 3, 4, 5, 6, 7, 8, 9]);

  const leftHandAlphabetsFiltered = leftHandAlphabets.filter(function (el) {
    return takenLetterList.indexOf(el) < 0;
  });

  const filteredDoubleCharacters = duplicateLetter.filter(function (el) {
    return takenLetterList.indexOf(el) < 0;
  });

  const regularCombinations = LoopThroughTwoArraysAndMakeCombinationsTogether(
    leftHandAlphabetsFiltered,
    rightHandAlphabets
  );
  const withSpecialCharacters = LoopThroughTwoArraysAndMakeCombinationsTogether(
    leftHandAlphabetsFiltered,
    specialCharacters
  );

  const duplicateKeyCombinations = createDuplicateLetterCombinations(
    filteredDoubleCharacters
  );

  // 88 regular combinations, 32 special characters, and
  // console.log('combinations', regularCombinations, duplicateKeyCombinations, withSpecialCharacters);
  const combined = regularCombinations
    .concat(duplicateKeyCombinations)
    .concat(withSpecialCharacters);

  // 127 total combo keys
  return combined;
}

export function createDuplicateLetterCombinations(letterArray) {
  if (!letterArray) {
    return [];
  }
  // letterArray = ['a', 'b', 'c']
  // return ["qn", "qm", "qh", "qj", "qk", "qy", "qu", "qo"]

  return letterArray.map((L) => {
    return `${L}${L}`;
  });
}

export function LoopThroughTwoArraysAndMakeCombinationsTogether(
  array1,
  array2
) {
  // array1 should be alphabets and array 2 should be numbers here
  let newList = [];
  let combinedElement;

  array1.forEach((element1) => {
    array2.forEach((element2) => {
      combinedElement = element1 + element2.toString();

      newList.push(combinedElement);
    });
  });

  return newList;
}

export function DoesEventLocationOrDescriptionContainZoom(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return false;
  }

  const location = getEventLocation(event) ?? "";
  const description = getEventDescription(event) ?? "";

  const combined = location + " " + description;

  return stringIncludesZoomURLCombinations(combined);
}

export function getFirstDayOfMonthlyCalendarJSDate(
  date = new Date(),
  weekStart = 0,
) {
  // get first day of the week for first day of the month
  return startOfWeek(startOfMonth(date), {
    weekStartsOn: weekStart ? parseInt(weekStart) : 0,
  });
}

export function getLastDayOfMonthlyCalendarJSDate(
  date = new Date(),
  weekStart = 0
) {
  // get last day of the week for last day of the month
  return endOfWeek(endOfMonth(date), {
    weekStartsOn: weekStart ? parseInt(weekStart) : 0,
  });
}

export function filterOutResourceAttendees(attendeeList) {
  if (isEmptyArray(attendeeList)) {
    return [];
  }

  return attendeeList.filter((a) => !isAttendeeResource(a));
}

export function isAttendeeResource(attendee) {
  return getObjectEmail(attendee)?.includes("resource.calendar");
}

export function generateConferenceRooms(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return [];
  }

  const attendees = getEventAttendees(event);

  let roomsArray = [];

  attendees &&
    attendees.length > 0 &&
    attendees.forEach((a) => {
      if (isAttendeeResource(a)) {
        roomsArray = roomsArray.concat(a.displayName);
      }
    });

  return roomsArray;
}

export function generateConferenceRoomsAndStatus(event) {
  const attendees = getEventAttendees(event);
  let roomsArray = [];

  attendees &&
    attendees.forEach((a) => {
      if (isAttendeeResource(a)) {
        roomsArray = roomsArray.concat({
          name: a.displayName,
          response: a.responseStatus,
        });
      }
    });

  return roomsArray;
}

export function filterForLocation(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return;
  }

  let location = getEventLocation(event);

  let conferenceRooms = generateConferenceRooms(event);

  location &&
    conferenceRooms.length > 0 &&
    conferenceRooms.forEach((r) => {
      let roomName;

      if (location.includes(", " + r)) {
        roomName = ", " + r;
      } else if (location.includes(r + ", ")) {
        roomName = r + ", ";
      } else if (location.includes(r + ",")) {
        roomName = r + ",";
      } else {
        roomName = r;
      }

      location = location.replace(roomName, "");
    });

  if (location?.length > 0) {
    // location = RemoveAllSpecialCharacters(location).trim();
  } else {
    location = null;
  }

  return location;
}

/**
 * @deprecated Use the new version in arrayFunctions.
 */
export function removeDuplicatesFromArray(array) {
  if (!array || array.length === 0) {
    return [];
  }

  let updatedArray = new Set(array);

  updatedArray = [...updatedArray];

  return updatedArray;
}

export function isOrganizer(attendees, email) {
  if (!email) {
    return false;
  }

  let isOrganizer = false;

  attendees.forEach((a) => {
    if (isEmptyObjectOrFalsey(a)) {
      return;
    }

    if (isEventAttendeeOrganizer(a) && isSameEmail(getObjectEmail(a), email)) {
      isOrganizer = true;
    }
  });

  return isOrganizer;
}

export function isUserEmailOrganizer({event, eventEmail}) {
  if (!eventEmail) {
    // skip below
    return false;
  }

  if (equalAfterTrimAndLowerCased(getEventOrganizer(event)?.email, eventEmail)) {
    // if user is organizer
    return true;
  }

  if (getEventAttendees(event) &&
    isOrganizer(getEventAttendees(event), eventEmail)
  ) {
    // user is an attendee and is marked as organizer
    return true;
  }

  return false;
}


export function isEditable({
  event,
  allCalendars,
  isCreateAvailabilityModeOn=false,
  isEditModeOn=false,
  reverseSlotsText,
  isPrivateProperty = false,
}) {
  if (isPreviewOutlookEvent(event)) {
    return false;
  }
  if (isWorkplaceEvent(event)) {
    return false;
  }
  if (isAppInTaskMode({
    isCreateAvailabilityModeOn,
    isEditModeOn,
    reverseSlotsText
  })
    || !event || isEmptyObjectOrFalsey(event)
    || isMeetWithEvent(event)
  ) {
    // default false
    return false;
  }

  if (isPrivateProperty
    && isOutlookEvent(event)
  ) {
    // only outlook events can be edited if no edit access
    return true;
  }

  const matchingCalendar = getCalendarFromUserCalendarID({
    userCalendarID: getEventUserCalendarID(event),
    allCalendars
  });

  const hasEditAccessOnCalendarLevel = editableRoles.includes(
    getCalendarEditRole(matchingCalendar)
  );

  if (!hasEditAccessOnCalendarLevel) {
    return false;
  }

  if (isOutlookEvent(event)
    && getEventAttendees(event)?.length > 0
    && !isOutlookEventAndOrganizer({
      event,
      allCalendars,
    })
  ) {
    // not organizer and has attendees on outlook -> hide
    return false;
  }
  return true;
}

export function emailIsAnAttendee(event, email) {
  if (!email) {
    return false;
  }

  const eventAttendees = getEventAttendees(event);
  if (isEmptyArray(eventAttendees)) {
    return false;
  }

  return eventAttendees.filter((e) => e?.email === email).length > 0;
}

export function canDisplayAttendees(event) {
  if (isOrganizerSelf(event)) {
    return true;
  }

  return (
    getEventGuestPermissions(event, GUESTS_CAN_MODIFY) ||
    getEventGuestPermissions(event, GUESTS_CAN_SEE_OTHER_GUESTS) !== false
  );
}

export function callFetchCalendar(
  fetchFunction,
  email,
  fetchStart,
  fetchEnd,
  momentStart,
  momentEnd,
  fetchId
) {
  if (
    !fetchStart.date &&
    !fetchEnd.date &&
    fetchStart.fetchData &&
    fetchEnd.fetchData
  ) {
    return fetchFunction(
      email,
      momentStart,
      momentEnd,
      fetchId,
      "agenda separate"
    );
  } else if (fetchStart.date) {
    return fetchFunction(
      email,
      momentStart,
      fetchStart.date,
      fetchId,
      "agenda left"
    );
  } else if (fetchEnd.date) {
    return fetchFunction(email, fetchEnd.date, momentEnd, fetchId, "right");
  }
}

export function getComponentLocation(
  id,
  height = null,
  additionalMarginTop = 0
) {
  let eventDom = document.getElementById(id);

  let top = 0;

  if (eventDom) {
    top = eventDom.getBoundingClientRect().top - 20;

    if (height && height + top > window.innerHeight) {
      top = window.innerHeight - height - 30;
    }
  }

  eventDom = null;

  if (top < additionalMarginTop) {
    top = additionalMarginTop;
  }

  return { top: top };
}

export function flattenResponseFromFetchEvents(response) {
  if (!(response?.calendars?.length > 0)) {
    return {};
  }

  const listOfCalendars = response.calendars;
  const flattedResponse = {};

  listOfCalendars.forEach((c) => {
    const userCalendarID = Object.keys(c)[0];
    const events = c[userCalendarID];
    if (events?.length > 0) {
      flattedResponse[userCalendarID] = events;
    }
  });

  return flattedResponse;
}

export function concatAllValue(calendarEvents) {
  // Expect {calendarId: value, calendarId2: value};
  let events = [];

  !isEmptyObjectOrFalsey(calendarEvents) &&
    Object.keys(calendarEvents).forEach((c) => {
      if (calendarEvents[c] && calendarEvents[c].length > 0) {
        events = events.concat(calendarEvents[c]);
      }
    });

  return events;
}

export function printOutCalendarIdAndNumberOfEvents(secondaryCalendar) {
  if (isEmptyObjectOrFalsey(secondaryCalendar)) {
    // console.log('empty secondaryCalendar', secondaryCalendar);
  } else {
    // console.log('calendar events number below: ');

    !isEmptyObjectOrFalsey(secondaryCalendar) &&
      Object.keys(secondaryCalendar).forEach((c) => {
        // console.log(secondaryCalendar[c].calendar.summary, secondaryCalendar[c].events && secondaryCalendar[c].events.length, getMaxMinDate(secondaryCalendar[c].events));
      });
  }
}

export function renderShortcutTiles(
  shortcut,
  backgroundColor = null,
  border = null,
  color = null
) {
  if (!shortcut) {
    return null;
  }

  const splitShortcutPhrase = shortcut.split(" ");

  return splitShortcutPhrase.map((s, index) => {
    if (["then", "and", "+"].includes(s)) {
      return (
        <div
          key={`shortcutPhrase${index}`}
          className={"display-flex-center"}
          style={{ fontSize: 12, marginLeft: 8, marginRight: 4 }}
        >
          <div className={"display-flex-center"}>{s}</div>
        </div>
      );
    } else {
      return (
        <ShortcutTile
          key={`shortcutTile${index}`}
          shortcut={s}
          backgroundColor={backgroundColor || "transparent"}
          border={border || "1px solid white"}
          color={color || ""}
        />
      );
    }
  });
}

export function createAttendeeNamesArray(attendee) {
  let attendeeNamesArray = [];

  if (!attendee) {
    return [];
  }

  attendee.forEach((a) => {
    if (a.email) {
      attendeeNamesArray = attendeeNamesArray.concat(a.email.toLowerCase());
    }
  });

  return attendeeNamesArray;
}

export function createDBInputObject(e) {
  return {
    user_event_id: getEventUserEventID(e),
    calendarId: getEventUserCalendarID(e) || "",
    date: createDateIndexForDB(e),
    summary: splitUpStringIntoArray(getEventTitle(e)),
    attendees: createAttendeeNamesArray(getEventAttendees(e)),
    location: splitUpStringIntoArray(getEventLocation(e)),
    event: e,
    masterEventID: getEventMasterEventID(e),
  };
}

export function splitUpStringIntoArray(string) {
  return string
    ? string.toLowerCase().split(" ").concat(string.toLowerCase())
    : "";
}

export function splitEmailIntoNames(email) {
  if (!email) {
    return [];
  }

  let splittedEmails = [];
  const loweredCaseEmail = email.toLowerCase();
  const splitByDot = loweredCaseEmail.split(".");
  const splitByDash = loweredCaseEmail.split("-");
  const splitByDomain = loweredCaseEmail.split("@");
  if (splitByDot.length > 1) {
    splittedEmails = splittedEmails.concat(splitByDot);
  }

  if (splitByDash.length > 1) {
    splittedEmails = splittedEmails.concat(splitByDash);
  }

  if (splitByDomain[1]) {
    splittedEmails = splittedEmails.concat(splitByDomain[1]);
  }

  return splittedEmails
}

export function updateEventsTime({ events, prevTimeZone, newTimeZone }) {
  let updatedList = [];
  const defaultTimeZone = guessTimeZone();

  events.forEach((e) => {
    if (hasEventTimeZoneBeenExpliciltySpecified(e)
      && newTimeZone === getExplicitlySpecifiedTimeZone(e)
    ) {
      updatedList = updatedList.concat(e);
      return;
    }
    if (isAllDayEvent(e)) {
      updatedList = updatedList.concat(e);
      return;
    }

    const minsDiff = minDifferentBetweenTimeZones(
      newTimeZone || defaultTimeZone,
      prevTimeZone || defaultTimeZone,
      e.eventStart
    );

    const updatedEvent = {
      ...e,
      eventStart: addMinutes(e.eventStart, minsDiff),
      eventEnd: addMinutes(e.eventEnd, minsDiff),
      rbcEventEnd: addMinutes(e.rbcEventEnd, minsDiff),
    };

    updatedList = updatedList.concat(updatedEvent);
  });

  return updatedList;
}

export function addHoursJSDate(time, param) {
  // param is {hours, minutes};
  return add(time, param);
}

export function createDateArray(startJSDate, endJSDate) {
  let currDate = startOfDay(parseISO(startJSDate));
  let lastDate = startOfDay(parseISO(endJSDate));

  let dates = [format(currDate, AGENDA_FORMAT_JS_DATE)];

  currDate = addDays(currDate, 1);

  while (differenceInDays(lastDate, currDate) > 0) {
    dates = dates.concat(format(currDate, AGENDA_FORMAT_JS_DATE));
    currDate = addDays(currDate, 1);
  }

  dates = dates.concat(format(lastDate, AGENDA_FORMAT_JS_DATE));

  return dates;
}

export function setDateInIndex(day, indexByDate, event) {
  let updatedIndexByDate = indexByDate;

  if (day in indexByDate) {
    updatedIndexByDate[day] = updatedIndexByDate[day].concat(event);
  } else {
    updatedIndexByDate[day] = [event];
  }

  return updatedIndexByDate;
}

export function isMac() {
  if (!isNullOrUndefined(_isMacCache)) {
    return _isMacCache;
  }

  const platform = navigator?.userAgentData?.platform?.toLowerCase();
  if (platform?.includes("mac")) {
    _isMacCache = true;
    return true;
  }

  const isClientMac = navigator.platform?.toLowerCase()?.includes("mac");
  _isMacCache = isClientMac;

  return isClientMac;
}

export function expandedDateAndTimeString(
  result,
  format24HourTime = false,
  dateFieldOrder = MOMENT_MDY_DATE_FORMAT
) {
  // January 9, 2020, 12:15am – January 10, 2020, 3:15am
  let startDate = format(
    result.eventStart,
    determineFormatJSDateDateString(dateFieldOrder)
  );
  let startTime = format(
    result.eventStart,
    getDateTimeFormatLowercaseAMPM(format24HourTime)
  );

  let endDate = format(
    result.eventEnd,
    determineFormatJSDateDateString(dateFieldOrder)
  );
  let endTime = format(
    result.eventEnd,
    getDateTimeFormatLowercaseAMPM(format24HourTime)
  );

  return startDate === endDate
    ? `${startDate}, ${startTime} - ${endTime}`
    : `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
}

export function determineFormatDateString(
  dateFieldOrder = MOMENT_MDY_DATE_FORMAT
) {
  if (dateFieldOrder === MOMENT_DMY_DATE_FORMAT) {
    return "DD MMM YYYY";
  } else if (dateFieldOrder === MOMENT_YMD_DATE_FORMAT) {
    return "YYYY MMM DD";
  } else {
    return "MMM D, YYYY";
  }
}

export function determineFormatJSDateDateString(
  dateFieldOrder = MOMENT_MDY_DATE_FORMAT
) {
  if (dateFieldOrder === MOMENT_DMY_DATE_FORMAT) {
    return "dd MMM yyyy";
  } else if (dateFieldOrder === MOMENT_YMD_DATE_FORMAT) {
    return "yyyy MMM dd";
  } else {
    return "MMM d, yyyy";
  }
}

export function determineFormatDateStringJSDate(
  dateFieldOrder = MOMENT_MDY_DATE_FORMAT
) {
  if (dateFieldOrder === MOMENT_DMY_DATE_FORMAT) {
    return "dd MMM yyyy";
  } else if (dateFieldOrder === MOMENT_YMD_DATE_FORMAT) {
    return "yyyy MMM dd";
  } else {
    return "MMM d, yyyy";
  }
}

export function isUserOnlyPersonWhoAcceptedAndRestDeclined(
  event,
  selfAttendingStatus
) {
  const eventAttendees = getEventAttendees(event);
  if (eventAttendees) {
    const acceptedGuests = eventAttendees.filter(
      (a) => a.responseStatus === attendee_event_attending
    );

    const declinedGuests = eventAttendees.filter(
      (a) => a.responseStatus === attendee_event_declined
    );

    if (
      acceptedGuests.length === 1 &&
      eventAttendees.length > 1 &&
      declinedGuests.length === eventAttendees.length - 1 &&
      selfAttendingStatus === attendee_event_attending
    ) {
      return true;
    }

    return false;
  }

  return false;
}

// TODO: going forward, if we need to add in new localStorage only values, add it into here as a key
export const LOCAL_STORAGE_KEYS = {
  IS_DARK_MODE: "isDarkMode"
};

function setLocalStorage({ key, value }) {
  try {
    localStorage?.setItem(key, value);
  } catch (error) {
    handleError(error);
  }
}

function getLocalStorage(key) {
  try {
    return localStorage?.getItem(key);
  } catch (error) {
    handleError(error);
  }
}

function localStorageHasProperty(key) {
  try {
    return localStorage?.hasOwnProperty(key);
  } catch (error) {
    handleError(error);
    return false;
  }
}

function localStorageRemoveItem(key) {
  try {
    localStorage?.removeItem(key);
  } catch (error) {
    handleError(error);
  }
}

function clearLocalStorage() {
  try {
    localStorage?.clear();
  } catch (error) {
    handleError(error);
  }
}


export function localData(method, key, value) {
  // TODO: delete console log below
  if (isElectron() && window?.vimcal) {
    switch (method) {
      case "set":
        try {
          setLocalStorage({ key, value });
          window.vimcal.setStoreValue(key, value);
        } catch (e) {
          handleError(e);
        }

        break;
      case "get":
        return window.vimcal.getStoreValue(key) || getLocalStorage(key);
      case "delete":
        window.vimcal.deleteStoreValue(key);
        localStorageRemoveItem(key);
        break;
      case "clear":
        window.vimcal.clearStore();
        clearLocalStorage();
        break;
      case "has":
        return (
          window.vimcal.hasStoreValue(key) || localStorageHasProperty(key)
        );
      default:
        break;
    }
  } else if (isElectron() && window && window.require) {
    let Store;
    try {
      Store = window.require("electron-store");
    } catch (error) {
      handleError(error);
    }

    if (!Store) {
      return localStorageData(method, key, value);
    }

    const store = new Store();

    switch (method) {
      case "set":
        try {
          setLocalStorage({ key, value });
          store.set(key, value);
        } catch (e) {
          handleError(e);
        }

        break;
      case "get":
        return store.get(key) || getLocalStorage(key);
      case "delete":
        store.delete(key);
        localStorageRemoveItem(key);
        break;
      case "clear":
        store.clear();
        clearLocalStorage();
        break;
      case "has":
        return store.has(key) || localStorageHasProperty(key);
      default:
        break;
    }
  } else {
    return localStorageData(method, key, value);
  }
}

function localStorageData(method, key, value) {
  if (isEmptyObjectOrFalsey(localStorage)) {
    return null;
  }

  switch (method) {
    case "set":
      setLocalStorage({ key, value });
      break;
    case "get":
      return getLocalStorage(key);
    case "delete":
      localStorageRemoveItem(key);
      break;
    case "clear":
      clearLocalStorage();
      break;
    case "has":
      return localStorageHasProperty(key);
    default:
      break;
  }
}

export function isElectron() {
  if (!isNullOrUndefined(_isElectronCache)) {
    return _isElectronCache;
  }

  const userAgent = navigator.userAgent.toLowerCase();
  const isClientElectron = userAgent.indexOf(" electron/") > -1;
  _isElectronCache = isClientElectron;

  return isClientElectron;
}

export function isCommandKeyPressed(keyMap) {
  if (isMac()) {
    return (
      keyMap[KEYCODE_COMMAND_LEFT] ||
      keyMap[KEYCODE_COMMAND_RIGHT] ||
      keyMap[KEYCODE_COMMAND_FIREFOX]
    );
  } else {
    return keyMap[KEYCODE_CONTROL];
  }
}

export function sortEventsByStartTime(events) {
  if (events && events.length > 0) {
    return events.sort(function (a, b) {
      if (isBeforeMinute(a.eventStart, b.eventStart)) {
        return -1; // a before b
      } else if (isSameMinute(a.eventStart, b.eventStart)) {
        // break tie on end
        if (
          !a.summaryUpdatedWithVisibility ||
          a.summaryUpdatedWithVisibility < b.summaryUpdatedWithVisibility
        ) {
          return -1; // a before b
        } else {
          return 1; // b before a
        }
      } else {
        return 1; // b before a
      }
    });
  } else {
    return [];
  }
}

export function shouldDisplayDeleteEventButton({
  event,
  allCalendars,
  isCreateAvailabilityModeOn,
  isEditModeOn,
  reverseSlotsText
}) {
  const userCalendarID = getEventUserCalendarID(event);
  if (isPreviewOutlookEvent(event)) {
    return false;
  }

  if (isAppInTaskMode({
    isCreateAvailabilityModeOn,
    isEditModeOn,
    reverseSlotsText
  })) {
    return false;
  }
  
  if (allCalendars[userCalendarID]) {
    const accessRole = getCalendarEditRole(allCalendars[userCalendarID]);
    return EDITABLE_ROLES.includes(accessRole);
  }

  return false;
}

export function filterExistingEmails(existingEmails, response) {
  let filteredEmails = response || [];
  if (
    existingEmails &&
    existingEmails.length > 0 &&
    response &&
    response.length > 0
  ) {
    filteredEmails = response.filter(
      (a) => a && !existingEmails.includes(a.email)
    );
  }

  return filteredEmails;
}

export function filterExistingEmailAndSortByDomain(
  existingEmails,
  response,
  domainName
) {
  let filteredEmails = filterExistingEmails(existingEmails, response);

  let sortedList = domainName
    ? sortDomainUsers(filteredEmails, "email", domainName)
    : filteredEmails;

  return sortedList;
}

export function removeDuplicateContactsBasedOnEmails(contactList) {
  if (isEmptyArray(contactList)) {
    return [];
  }

  let duplicateTrackerArray = [];

  let returnList = [];

  contactList.forEach((e) => {
    let contactEmail = e?.emailArray
      ? createEmailString(e.emailArray)
      : e?.email || "";
    if (!duplicateTrackerArray.includes(contactEmail)) {
      duplicateTrackerArray = duplicateTrackerArray.concat(contactEmail);

      returnList = returnList.concat(e);
    }
  });

  return returnList;
}

export function createLabelAndValueForReactSelect(list) {
  if (!list || list.length === 0) {
    return [];
  }

  return list.map((a) => {
    return { value: a, label: a };
  });
}

// TODO: This returns true for float values as well. Either rename or fix.
export function isInt(value) {
  return !isNaN(value) && !isNaN(parseInt(value, 10));
}

export function removeLeadingZeros(value) {
  return parseInt(value, 10);
}

export function blurInputOnEscape(event, blurElement) {
  // ESC key
  if (event && event.keyCode === 27 && blurElement?.blur) {
    blurElement.blur();
  }
}

export function getEmailBasedOnCalendarId(event, allCalendars) {
  if (isMeetWithEvent(event)) {
    // for cmd j calendars
    return event.calendarId;
  } else {
    if (isEmptyObjectOrFalsey(allCalendars)) {
      return null;
    }

    return getCalendarEmail(allCalendars[getEventUserCalendarID(event)]);
  }
}

export function doesEventHaveModifyPermissionAndIsNotAnOrangizer(
  event,
  eventCalendarEmail
) {
  if (!eventCalendarEmail || !event) {
    return false;
  }

  const eventAttendees = getEventAttendees(event);

  if (!(eventAttendees?.length > 0) || isMeetWithEvent(event)) {
    return false;
  }

  let listOfOrganizers = [];

  eventAttendees.forEach((a) => {
    if (isEventAttendeeOrganizer(a) && getObjectEmail(a)) {
      listOfOrganizers = listOfOrganizers.concat(getObjectEmail(a));
    }
  });

  return (
    !listOfOrganizers.includes(eventCalendarEmail.toLowerCase()) &&
    getEventGuestPermissions(event, GUESTS_CAN_MODIFY)
  );
}

export function updateStringForTime(stringInput) {
  if (
    !stringInput ||
    stringInput.length === 0 ||
    !isInt(stringInput) ||
    stringInput.length > 4
  ) {
    return;
  }

  let integerInput = parseInt(stringInput, 10);
  let updatedString;

  if (stringInput.length === 1) {
    updatedString = stringInput + ":00";
  } else if (stringInput.length === 2) {
    if (integerInput >= 10 && integerInput <= 24) {
      updatedString = stringInput + ":00";
    } else {
      updatedString = stringInput[0] + ":" + stringInput[1] + "0";
    }
  } else if (stringInput.length === 3) {
    updatedString = stringInput[0] + ":" + stringInput.substring(1);
  } else if (stringInput.length === 4) {
    updatedString =
      stringInput.substring(0, 2) + ":" + stringInput.substring(2);
  }

  return updatedString;
}

export function chronoParseHasKnownValues(chronoParse, element = 0) {
  return (
    chronoParse &&
    chronoParse[element] &&
    chronoParse[element].start &&
    chronoParse[element].start.knownValues &&
    !isEmptyObjectOrFalsey(chronoParse[element].start.knownValues)
  );
}

export function chronoParseHasKnownEndValues(chronoParse, element = 0) {
  return (
    chronoParse &&
    chronoParse[element] &&
    chronoParse[element].end &&
    chronoParse[element].end.knownValues &&
    !isEmptyObjectOrFalsey(chronoParse[element].end.knownValues)
  );
}

export function chronoHasKnownTime(chronoParse) {
  return (
    chronoParseHasKnownValues(chronoParse) &&
    ["hour", "minute"].every((i) =>
      Object.keys(chronoParse[0].start.knownValues).includes(i)
    )
  );
}

export function chronoHasKnownDate(chronoParse) {
  return (
    chronoParseHasKnownValues(chronoParse) &&
    ["day", "month"].every((i) =>
      Object.keys(chronoParse[0].start.knownValues).includes(i)
    )
  );
}

export function chronoHasKnownOffset(chronoParse) {
  return (
    chronoParseHasKnownValues(chronoParse) &&
    chronoParse[0].start.knownValues.timezoneOffset
  );
}

export function chronoHasKnownText(chronoParse, element = 0) {
  return chronoParse && chronoParse[element] && chronoParse[element].text;
}

export function updateRecentlySearchedContactsArray(
  recentlySearchedContacts,
  contact
) {
  let currentRecentlySearchedContacts = recentlySearchedContacts || [];
  let updatedRecentlySearchContacts = [contact].concat(
    currentRecentlySearchedContacts
  );
  let filterForDuplicates = removeDuplicateContactsBasedOnEmails(
    updatedRecentlySearchContacts
  ).filter(c => !!c.email);

  if (filterForDuplicates.length > 30) {
    filterForDuplicates = filterForDuplicates.slice(0, 30);
  }

  return filterForDuplicates;
}

export function combinedContactAndDomainResponse(
  recentContacts,
  domainResponse,
  contactResponse,
  existingEmails
) {
  let combinedResponse = [];

  if (recentContacts?.length > 0) {
    // do not need to sort. Array already keeps track of order
    combinedResponse = combinedResponse.concat(recentContacts);
  }

  if (domainResponse?.length > 0) {
    combinedResponse = combinedResponse.concat(domainResponse); // no need to apply sort on domain
  }

  if (contactResponse?.length > 0) {
    combinedResponse = combinedResponse.concat(sortContacts(contactResponse));
  }
  const filteredExistingEmails = filterExistingEmails(existingEmails, combinedResponse);
  return removeDuplicateContactsBasedOnEmails(filteredExistingEmails);
}

export function createNearestForwardDayOfWeek(eventStart, dayINeed) {
  let currentEventDay = getISODay(eventStart);

  // if we haven't yet passed the day of the week that I need:
  if (currentEventDay <= dayINeed) {
    // then just give me this week's instance of that day
    return setISODay(eventStart, dayINeed);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return setISODay(addWeeks(eventStart, 1), dayINeed);
  }
}

export function getDescriptionConferenceUrl(description) {
  if (!description || description.length === 0) {
    return null;
  }

  const descriptionURL = extractConferenceURLFromString(description);

  if (descriptionURL && doesURLContainConferencingURL(descriptionURL)) {
    return descriptionURL;
  }

  const descriptionTag = extractConferenceURLFromString(description);
  if (descriptionTag) {
    return descriptionTag;
  }

  const descriptionURLLinkTag = extractURLFromLinkTag(description);
  if (
    descriptionURLLinkTag &&
    doesURLContainConferencingURL(descriptionURLLinkTag)
  ) {
    return descriptionURLLinkTag;
  }

  return null;
}

export function determineMultipleConferenceWarning(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return;
  }

  let locationConferenceUrl = extractConferenceURLFromString(
    getEventLocation(event)
  );
  let descriptionConferenceUrl = getDescriptionConferenceUrl(
    getEventDescription(event)
  );
  let conferenceDataUrl = getConferenceURLFromNative(
    getEventConferenceData(event)
  );

  let locationConference = determineConferenceType(locationConferenceUrl);
  let descriptionConference = determineConferenceType(descriptionConferenceUrl);
  let conferenceDataConference = determineConferenceType(conferenceDataUrl);

  return createMultipleConferencingWarningString({
    locationConference,
    descriptionConference,
    conferenceDataConference,
    locationConferenceUrl,
    descriptionConferenceUrl,
    conferenceDataUrl,
  });
}

export function createMultipleConferencingWarningString(conferenceInfo) {
  const {
    locationConference,
    descriptionConference,
    conferenceDataConference,
    locationConferenceUrl,
    conferenceDataUrl,
    descriptionConferenceUrl,
  } = conferenceInfo;

  if (
    !locationConference &&
    !descriptionConference &&
    !conferenceDataConference
  ) {
    return null;
  } else if (
    conferenceDataConference &&
    locationConference &&
    descriptionConference &&
    conferenceDataConference !== locationConference &&
    conferenceDataConference !== descriptionConference
  ) {
    return "This meeting has a different conferencing link in the description and location.";
  } else if (
    conferenceDataConference &&
    locationConference &&
    conferenceDataConference !== locationConference
  ) {
    return "This meeting has a different conferencing link in the location.";
  } else if (
    conferenceDataConference &&
    descriptionConference &&
    conferenceDataConference !== descriptionConference
  ) {
    return "This meeting has a different conferencing link in the description.";
  } else if (
    locationConference &&
    descriptionConference &&
    locationConference !== descriptionConference
  ) {
    return "This meeting has a different conferencing link in the description.";
  } else if (
    locationConference === ZOOM_CONFERENCING &&
    conferenceDataConference === ZOOM_CONFERENCING &&
    determineIfLinksAreDifferent(locationConferenceUrl, conferenceDataUrl)
  ) {
    return "This meeting has a different Zoom link in the location.";
  } else if (
    descriptionConference === ZOOM_CONFERENCING &&
    conferenceDataConference === ZOOM_CONFERENCING &&
    determineIfLinksAreDifferent(descriptionConferenceUrl, conferenceDataUrl)
  ) {
    return "This meeting has a different Zoom link in the description.";
  }
}

function determineIfLinksAreDifferent(linkA, linkB) {
  const linkAStripped = stripOutCommonUrl(linkA);
  const linkBStripped = stripOutCommonUrl(linkB);

  return linkAStripped !== linkBStripped;
}

function stripOutCommonUrl(link) {
  if (!link) {
    return "";
  }

  const strippedOutCommonLink = link
    .trim()
    .replace("https://", "")
    .replace("www.", "");
  let strippedOutPwd = strippedOutCommonLink;

  let pwdLocation = strippedOutCommonLink.toLowerCase().indexOf("?pwd=");

  if (pwdLocation >= 0) {
    strippedOutPwd = strippedOutPwd.substring(0, pwdLocation);
  }

  return strippedOutPwd;
}

export function hasArrayChangedSize(prevArray, currentArray) {
  if (prevArray && !currentArray) {
    return true;
  } else if (!prevArray && currentArray) {
    return true;
  } else if (
    prevArray &&
    currentArray &&
    prevArray.length !== currentArray.length
  ) {
    return true;
  } else {
    return false;
  }
}

export function replaceVimcalSignature(string, masterAccount) {
  if (!string) {
    return "";
  }
  return string
    .replace(renderDefaultSignatureWithEmptyLinesAbove(masterAccount), "")
    .replace(renderDefaultSignatureWithEmptyLinesAbove(), "")
    .replace(getVimcalRichTextSignature(masterAccount), "")
    .replace(getVimcalRichTextSignature(), "");
}

export function translateValueFromChronoToMoment(
  key,
  value,
  allKeys,
  format24HourTime = false
) {
  if (key === "meridiem" || key === "weekday") {
    return null;
  } else if (key === "day") {
    return { key: "date", value };
  } else if (key === "month") {
    return { key, value: value - 1 };
  } else if (
    !allKeys.includes("meridiem") &&
    key === "hour" &&
    value < 8 &&
    !format24HourTime
  ) {
    return { key, value: value + 12 };
  } else {
    return { key, value };
  }
}

export function createAttendeeSuggestionsLabel(contact) {
  if (contact?.hasMultiple) {
    return contact.name;
  }

  return contact.name && contact.name !== contact.email
    ? `${contact.name} (${contact.email?.toLowerCase()})`
    : `${contact.email?.toLowerCase()}`;
}

export function stringContainsAnyWordInArray(string, array) {
  let doesContain = false;
  let loweredCaseString = string.toLowerCase();

  array.forEach((w) => {
    if (loweredCaseString.indexOf(w.toLowerCase()) >= 0) {
      doesContain = true;
    }
  });

  return doesContain;
}

export function hasEventPreventDefault(e) {
  if (e && typeof e.preventDefault === "function") {
    e.preventDefault();
  }
}

export function stopNativeEventPropagation(e) {
  if (e?.nativeEvent?.stopImmediatePropagation) {
    // https://stackoverflow.com/questions/24415631/reactjs-syntheticevent-stoppropagation-only-works-with-react-events
    e.nativeEvent.stopImmediatePropagation();
  }
}

export function hasStopEventPropagation(e) {
  if (e && typeof e.stopPropagation === "function") {
    e.stopPropagation();
  }
  stopNativeEventPropagation(e);
}

export function hasStateOrPropsChanged(
  oldState,
  newState,
  oldProps,
  newProps,
  excludedKeys = [],
  shouldPrint = false
) {
  // Might need to change for when changing URL is important
  let stateKeys = newState ? Object.keys(newState) : [];
  let propKeys = newProps ? Object.keys(newProps) : [];

  let hasStateChanged = stateKeys.some((k) => {
    if (oldState[k] !== newState[k] && !excludedKeys.includes(k)) {
      shouldPrint &&
        console.log(`${shouldPrint} State ${k}: `, oldState[k], newState[k]);

      return true;
    }
  });

  if (hasStateChanged) {
    return true;
  }

  let hasPropsChanged = propKeys.some((k) => {
    if (oldProps[k] !== newProps[k] && !excludedKeys.includes(k)) {
      shouldPrint &&
        console.log(`${shouldPrint} Prop ${k}: `, oldProps[k], newProps[k]);

      return true;
    }
  });

  return hasPropsChanged;
}

export function calculateMarginTop(
  shouldShowTopBar,
  additionalTop = 0,
  checkForIsElectron = true
) {
  let marginTop = shouldShowTopBar
    ? TOP_BAR_HEIGHT + additionalTop
    : additionalTop;

  if (isElectron() && checkForIsElectron) {
    marginTop = marginTop + DESKTOP_TITLE_BAR_HEIGHT;
  }

  return marginTop;
}

export function calculateMarginTopClassname(shouldShowTopBar) {
  if (isElectron()) {
    if (shouldShowTopBar) {
      return "desktop-title-bar-and-top-bar-margin-top";
    } else {
      return "desktop-title-bar-margin-top";
    }
  } else {
    if (shouldShowTopBar) {
      return "margin-top-30";
    } else {
      return "mt-0";
    }
  }
}

export function removeGoogleAutoGeneratedKeys(eventObject) {
  let updatedObject = _.clone(eventObject);
  [
    "organizer",
    "creator",
    "recurringEventId",
    "iCalUID",
    "id",
    "htmlLink",
    "kind",
    "sequence",
    "updated",
    "created",
    "etag",
    "originalStartTime",
    "status",
  ].forEach((k) => {
    updatedObject = _.omit(updatedObject, k);
  });

  return updatedObject;
}

export function omitNullOrUndefinedProps(obj, recurrent = false) {
  return Object
    .keys(obj)
    .filter(k => obj[k] !== undefined && obj[k] !== null)
    .reduce((out, k) => {
      const isObj = typeof obj[k] === 'object' && !Array.isArray(obj[k]);
      out[k] = recurrent && isObj ? omitNullOrUndefinedProps(obj[k], true) : obj[k];
      return out;
    }, {})
}

export function filterOutModalFromArray(
  currentModalArray,
  content,
  keyName = null
) {
  if (!currentModalArray || currentModalArray.length === 0) {
    return [];
  }

  let key = keyName || "modalContent";

  return currentModalArray.filter((m) => m[key] !== content);
}

export function createFreeSlotsBasedOnBusySlots(freeStart, freeEnd, busySlots) {
  //All time slots come in as utc

  let start = moment(freeStart).startOf("minute");
  let end = moment(freeEnd).startOf("minute");

  // console.log('start out', start.format('LLLL'));
  // console.log('end out', end.format('LLLL'));

  let currentTime = moment();
  if (
    currentTime.isAfter(start, "minute") &&
    currentTime.isBefore(end, "minute")
  ) {
    start = currentTime;
  } else if (currentTime.isSameOrAfter(end)) {
    return [];
  }

  if (isEmptyArray(busySlots)) {
    //Default case where there's no busy slots
    return [{ start_time: start.toISOString(), end_time: end.toISOString() }];
  }

  // Scenerios: top = busy, bottom = free
  let freeSlots = [];
  let shouldSkip = false;

  busySlots.forEach((s, index) => {
    // console.log('start', start.format('LLLL'));
    // console.log('end', end.format('LLLL'));
    // console.log('index', index);
    // console.log('shouldSkip', shouldSkip);
    // console.log('start', start.format('LTS'));
    // console.log('end', end.format('LTS'));
    // console.log('s.start', moment(s.start).format('LTS'));
    // console.log('s.end', moment(s.end).format('LTS'));

    let busyStart = moment(s.start).startOf("minute");
    let busyEnd = moment(s.end).startOf("minute");

    // console.log('busyStart', busyStart.format('LLLL'));
    // console.log('busyEnd', busyEnd.format('LLLL'));

    if (shouldSkip) {
      // console.log('here0');
      //  Do nothing
    } else if (
      busyStart.isSameOrBefore(start, "minute") &&
      busyEnd.isSameOrAfter(end, "minute")
    ) {
      // console.log('here1');
      // |------|       |------------|       |--------------------|       |-------------|
      // |------| or          |------|    or     |---------|         or   |----|
      // Skip this time slot altogether
      shouldSkip = true;
    } else if (
      busyStart.isSameOrBefore(start, "minute") &&
      busyEnd.isBefore(end, "minute") &&
      busyEnd.isAfter(start, "minute")
    ) {
      // console.log('here2');
      // |-------|            |---------|
      //       |------|   or  |--------------|
      // have to use isAfter otherwise case where busy ends right as free start would fail
      start = busyEnd;
    } else if (
      busyEnd.isSameOrAfter(end, "minute") &&
      busyStart.isAfter(start, "minute") &&
      busyStart.isBefore(end, "minute")
    ) {
      // console.log('here3');
      //           |---------|              |-----|
      //     |----------|        or    |----------|

      end = busyStart;
    } else if (
      busyEnd.isBefore(end, "minute") &&
      busyStart.isAfter(start, "minute")
    ) {
      // console.log('here5');
      //         |---|
      //   |-----------------|
      let slot = {
        start_time: start.toISOString(),
        end_time: busyStart.toISOString(),
      };
      freeSlots = freeSlots.concat(slot);

      start = busyEnd;
    } else {
      // console.log('here6');
      // nothing
    }
  });

  if (!shouldSkip) {
    let slot = { start_time: start.toISOString(), end_time: end.toISOString() };
    freeSlots = freeSlots.concat(slot);
  }

  return freeSlots;
}

/**
 * @param {{ start_time: string, end_time: string, availableLinkTokens?: string[] }[]} timeSlots
 * @param {number} duration
 * @returns {Record<string, { start: string, end: string, availableLinkTokens?: string[] }[]>}
 */
export function createSlotsForSelection(timeSlots, duration) {
  // timeSlots come in the time zone provided
  // end_time come in utc

  // Algorithm
  // 1. convert slots to local time zone
  // 2. find closest 30min/hour interval and start slashing duration from each slot
  // 3. add slot to day index

  // Convert slots to local timezone
  if (!timeSlots || timeSlots.length === 0) {
    return {};
  }

  let convertedTimeZoneSlots = [];

  timeSlots.forEach((s) => {
    let { start_time, end_time, availableLinkTokens } = s;
    let updatedStart = moment(start_time).local();
    let updatedEnd = moment(end_time).local();

    convertedTimeZoneSlots = convertedTimeZoneSlots.concat({
      start: updatedStart,
      end: updatedEnd,
      ...(isEmptyArrayOrFalsey(availableLinkTokens)
        ? {}
        : { availableLinkTokens }
      ),
    });
  });

  // Create day index and add duration slots to each day
  let dayIndex = {};

  // iterate through each slot and add to dayIndex
  convertedTimeZoneSlots.forEach((s) => {
    const slotStart = s.start.startOf("minute");
    const slotEnd = s.end.startOf("minute");

    const slotStartRounded15 = RoundToClosestMinute(slotStart, 15, "", true);
    const slotStartRounded30 = RoundToClosestMinute(slotStart, 30, "", true);
    let slotStartRounded = slotStartRounded30; // default to 30

    if (shouldRoundToNearest15(duration)) {
      // Already set to round to 15
      slotStartRounded = slotStartRounded15;
    }

    if (
      slotEnd.diff(slotStart, "minutes") < duration ||
      slotEnd.diff(slotStartRounded, "minutes") < duration
    ) {
      // Do nothing because there's not enough time
    } else {
      // Slot are bigger than duration -> start slicing duration off each one
      dayIndex = sliceDurationAndReturnSlot(
        duration,
        slotStartRounded,
        slotEnd,
        dayIndex,
        isEmptyArrayOrFalsey(s.availableLinkTokens)
          ? {}
          : { availableLinkTokens: s.availableLinkTokens },
      );
    }
  });

  return dayIndex;
}

export function sortTimeSlots(dayTimeObject) {
  let updatedDaySlot = {};

  Object.keys(dayTimeObject).forEach((k) => {
    let list = dayTimeObject[k].sort((a, b) =>
      SortEvents(a, b, "start", false)
    );

    updatedDaySlot[k] = list;
  });

  return updatedDaySlot;
}

export function sliceDurationAndReturnSlot(duration, start, end, dayIndex, additionalDetails = {}) {
  let updatedDayIndex = dayIndex || {};

  if (end.diff(start, "minutes") < duration) {
    // Sanity check
    return updatedDayIndex;
  }

  let sliceTime = shouldRoundToNearest15(duration) ? 15 : 30;

  // StartClone is mutable
  let startClone = start.clone();

  while (end.diff(startClone, "minutes") >= duration) {
    startClone = RoundToClosestMinute(startClone, 15, "", true);
    let startClone30 = RoundToClosestMinute(startClone, 30, "", true);

    if (shouldRoundToNearest15(duration)) {
      // startClone already rounded up to 15
    } else if (end.diff(startClone30, "minutes") >= duration) {
      startClone = startClone30;
    }

    let startTime = startClone.format("LLL");
    let startTimeMoment = startClone.clone();

    let startDay = startClone.format("dddd LL");

    let endTime = startClone.clone().add(duration, "minute").format("LLL");

    startClone.add(sliceTime, "minute").format("LLL");

    if (end.diff(startTimeMoment, "minutes") < duration) {
      // Not enough space -> ignore
    } else if (Object.keys(updatedDayIndex).includes(startDay)) {
      updatedDayIndex[startDay] = updatedDayIndex[startDay].concat({
        ...additionalDetails,
        start: startTime,
        end: endTime,
      });
    } else {
      updatedDayIndex[startDay] = [{ ...additionalDetails, start: startTime, end: endTime }];
    }
  }

  return updatedDayIndex;
}

export function determineScrollToHour(time = null) {
  if (!time) {
    let today = new Date();
    return createScrollHour(today.getHours());
  }

  if (isValidJSDate(time)) {
    return createScrollHour(time.getHours());
  }

  return createScrollHour(moment(time).hour());
}

export function createScrollHour(hour) {
  if (hour <= 3) {
    return hour;
  } else if (hour <= 14) {
    return hour - 3;
  } else {
    return hour - 5;
  }
}

/**
 * @import Modal from "react-modal"
 * @returns {Modal.Styles}
 */
export function determineDefaultModalStyle(isDarkMode, isTeamPlan = false) {
  return {
    overlay: {
      position: "fixed",
      inset: 0,
      backgroundColor: "rgba(0, 0, 0, 0.70)",
      zIndex: 100,
      overflow: isTeamPlan ? "hidden" : "",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderColor: "transparent",
      borderRadius: MODAL_BORDER_RADIUS,
      boxShadow: "0 0px 30px 3px rgba(0, 0, 0, 0.2)",
      zIndex: 5,
      backgroundColor: getModalBackgroundColor(isDarkMode),
      color: isDarkMode
        ? StyleConstants.darkModeModalTextColor
        : StyleConstants.defaultFontColor,
      maxHeight: "90vh",
      backdropFilter: MODAL_BLUR,
      WebkitBackdropFilter: MODAL_BLUR,
      padding: isTeamPlan ? "0" : "20px",
      overflow: isTeamPlan ? "hidden" : "auto",
    },
  };
}

export function loadTheme(theme, skipSettingInLocalData = false) {
  document.documentElement.setAttribute("data-theme", theme);
  if (isMenuBar()) {
    document.documentElement.setAttribute("menu-bar", true);
  }

  if (skipSettingInLocalData) {
    return;
  }

  const value = theme === DARK_MODE_THEME;

  setLocalStorage({
    key: LOCAL_STORAGE_KEYS.IS_DARK_MODE,
    value,
  });
}

export function determineConferenceType(string) {
  if (!string || !isTypeString(string) || !string.toLowerCase){
    return null;
  }

  const loweredCase = string.toLowerCase();

  if (string === GOOGLE_CONFERENCING) {
    return GOOGLE_CONFERENCING;
  }

  return CONFERENCING_OPTIONS_ARRAY.find(
    (t) =>
      loweredCase.includes(t) &&
      checkIfGoogleConferencingForHangoutAndMeet(loweredCase, t)
  );
}

export function hasConferencingData(event) {
  return !!getEventConferenceData(event);
}

export function isEventConferencingZoom(event) {
  const eventConferenceDataName =
    getEventConferenceData(event)?.conferenceSolution?.name;
  return (
    eventConferenceDataName &&
    eventConferenceDataName.toLowerCase().includes("zoom")
  );
}

export function handleError(error, context) {
  handleIndexDBError({ error, context }); // if index db error  -> handles it via refresh
  if (isLocal()) {
    console.error("dev error: ", error);
    if (context) {
      console.error(context);
    }
    return;
  }
  if (error?.message?.includes("e.target.className.includes is not a function")) {
    // no point in tracking this
    return;
  }
  if (error?.message?.includes("Unexpected token '<', \"<!DOCTYPE \"")) {
    return;
  }
  if (error?.message?.includes("Unexpected end of JSON input")) {
    return;
  }
  if (error?.message?.includes("'transaction' on 'IDBDatabase'")) {
    return;
  }
  if (lowerCaseAndTrimString(error?.message)?.includes("failed to fetch")) {
    // no reason to add this to sentry
    return;
  }
  Sentry.captureException(error, context);
  sendErrorToTracking(error);
}

// can not use trackError from tracking.js because of import cycle
export function sendErrorToTracking(error, userEmail) {
  try {
    const getErrorMessage = () => {
      return error?.message || error?.error;
    };
    const errorMessage = getErrorMessage();

    if (!errorMessage) {
      return;
    }
    const currentUserEmail = getCurrentUserEmail();
    if (!useAllLoggedInUsers.getState) {
      return;
    }
    if (userEmail) {
      // skip early return
    } else if (!currentUserEmail) {
      return;
    }

    const matchingUser = getMatchingUserFromAllUsers({ 
      allUsers: useAllLoggedInUsers.getState()?.allLoggedInUsers, 
      userEmail: userEmail ?? currentUserEmail
    });
    const userToken = getUserToken(matchingUser);
    if (!userToken) {
      return;
    }

    trackError({
      category: "handleError",
      errorMessage,
      userToken
    });
  } catch(e) {
    // ignore
  }
}

export function sendMessageToSentry(message1, message2) {
  let environment = process.env.REACT_APP_CLIENT_ENV;

  let menuBarWarning = isMenuBar() ? "menu-bar " : "";

  if (message2 === "TypeError: Failed to fetch") {
    // no need to capture this
    return;
  }

  if (!environment || environment === ENVIRONMENTS.DEV) {
    console.error(
      `Log message error: ${menuBarWarning}${message1} ${message2}`
    );
  } else {
    Sentry.captureMessage(`${menuBarWarning}${message1}: ${message2}`);
    sendErrorToTracking({message: `${menuBarWarning}${message1}: ${message2}`});
  }
}

export function sendBreadcrumbToSentry({ category, message, data, level }) {
  let environment = process.env.REACT_APP_CLIENT_ENV;

  if (!environment || environment === ENVIRONMENTS.DEV) {
    //* uncomment before merge
    // console.log(`sendBreadcrumbToSentry_Breadcrumb: ${category} ${message}`);
  } else {
    Sentry.addBreadcrumb({
      category,
      message,
      data,
      level: level || Sentry.Severity.Info,
    });
  }
}

export function updateToStartOfNextDayIfLastSlot(slotEnd) {
  // Pass back JS date
  let timeEnd = slotEnd;

  if (isSameMinute(slotEnd, endOfDay(slotEnd))) {
    let startOfNextDay = startOfDay(addDays(timeEnd, 1));
    timeEnd = startOfNextDay;
  }

  return timeEnd;
}

export function determineFurthestMinute(array) {
  if (!array || array.length === 0) {
    return 10;
  }

  let reminderMin;

  array.forEach((m) => {
    if (!reminderMin || m?.minutes > reminderMin) {
      reminderMin = m.minutes;
    }
  });

  return reminderMin ?? 10;
}

export function hasPermissionToModify(event, allCalendars) {
  const eventAttendees = getEventAttendees(event);
  if (!eventAttendees) {
    return true;
  }

  if (isOrganizerSelf(event)) {
    return true;
  } else if (allCalendars[getEventUserCalendarID(event)]) {
    const email = formatEmail(getCalendarEmail(allCalendars[getEventUserCalendarID(event)]));
    const organizerList = eventAttendees
      .filter((a) => isEventAttendeeOrganizer(a))
      .map((a) => getObjectEmail(a));
    if (organizerList.includes(email)) {
      return true;
    }
    if (isSameEmail(getObjectEmail(getEventOrganizer(event)), email)) {
      return true;
    }
    return false;
  } else if (getEventGuestPermissions(event, GUESTS_CAN_MODIFY)) {
    return false; // Bug with google where we can't edit modify permission bug
  }
}

export function shouldReduceTextSize(event, minutes = 15) {
  if (!event) {
    return false;
  }

  if (event.displayAsAllDay) {
    return false;
  }

  return (
    differenceInMinutes(
      event.end || event.eventEnd,
      event.start || event.eventStart
    ) <= minutes
  );
}

export function formatRFCPhoneNumber(phoneNumber, regionCode) {
  if (!isTypeString(phoneNumber) || !isTypeString(regionCode)) {
    sendMessageToSentry("Phone Number Error", `Phone number is not a string. Region: ${regionCode} Phone Number: ${phoneNumber}`);
    return "";
  }

  const pn = new PhoneNumber(phoneNumber, regionCode);
  const formattedPhoneNumber = pn.getNumber("rfc3966");

  return formattedPhoneNumber;
}

export function IsValidPhoneNumber(number, regionCode) {
  if (!number || number.length === 0) {
    return false;
  }

  let pn = new PhoneNumber(number, regionCode);

  return pn.isValid();
}

export function formatPhoneNumberHumanReadable(number, regionCode) {
  // create (415)816-3363
  return new PhoneNumber(number, regionCode).getNumber("national");
}

export function convertTrueFalseStringIntoValue(val) {
  return val === "true" || val === true;
}

export function getLoggedInEmails(currentUserEmail = null) {
  let unformattedLoggedInAccounts = localData(LOCAL_DATA_ACTION.GET, LOGGED_IN_ACCOUNTS);

  let loggedInAccountsEmails = [];

  if (unformattedLoggedInAccounts) {
    let formattedLoggedInAccounts = unformattedLoggedInAccounts
      ? safeJSONParse(unformattedLoggedInAccounts)
      : [];

    formattedLoggedInAccounts.forEach((a) => {
      if (a && a.email && a.email !== currentUserEmail) {
        loggedInAccountsEmails = loggedInAccountsEmails.concat(a.email);
      }
    });
  }

  return loggedInAccountsEmails;
}

export function createStartEndDateForSyncingOtherCalendars() {
  let startDate = subDays(new Date(), 2).toISOString(); // event could have started in the past
  let endDate = addDays(new Date(), 2).toISOString();

  return { startDate, endDate };
}

export function textToNumber(s) {
  // Turn numbered words (two) into numerical numbers (2)
  if (!s || s.length === 0 || s.trim().length === 0) {
    return "";
  }

  let formattedString = s
    .toLowerCase()
    .replace(new RegExp(String.fromCharCode(160), "g"), " ");

  Object.keys(DIGIT_TO_NUMBER_OBJECT).forEach((k) => {
    if (formattedString.includes(k)) {
      // \b is to search for word in text
      let re = new RegExp("\\b" + k + "\\b", "g");

      formattedString = formattedString.replace(
        re,
        ` ${DIGIT_TO_NUMBER_OBJECT[k]} `
      );
    }
  });

  return formattedString;
}

export function customChronoWordDetection({
  text,
  referenceDate,
  dateFormat = "MDY",
}) {
  const result = customChronoDetection({
    text, 
    referenceDate, 
    dateFormat, 
  });

  if (hasWeekDayAndImpliedDate(result)) {
    return moveImpliedDateToKnownValue(result);
  }

  return result;
}

export function parseChronoDMY(text, referenceDate, forwardDate = true) {
  // https://github.com/wanasit/chrono/issues/75
  // https://wanasit.github.io/chrono/modules/en.html#createcasualconfiguration
  const chronoDMY = createChronoDMYConfiguration();
  return chronoDMY.parse(text, referenceDate || new Date(), {
    forwardDate: forwardDate,
  });
}

export function parseDateChronoDMY(text, referenceDate, forwardDate = true) {
  // https://github.com/wanasit/chrono/issues/75
  // https://wanasit.github.io/chrono/modules/en.html#createcasualconfiguration
  const chronoDMY = createChronoDMYConfiguration();
  return chronoDMY.parseDate(text, referenceDate || new Date(), {
    forwardDate: forwardDate,
  });
}

function createChronoDMYConfiguration() {
  // sometimes for some reason chrono.createCasualConfiguration doesn't exist
  // https://vimcal.sentry.io/issues/4980761929/?project=2190664&query=typeerror&referrer=issue-stream&statsPeriod=14d&stream_index=0
  try {
    // https://github.com/wanasit/chrono/issues/75#issuecomment-751830717
    if (!chrono?.en?.createConfiguration) {
      return chrono.en ?? chrono;
    }
    return new chrono.Chrono(chrono.en.createConfiguration(true, true));
  } catch (error) {
    handleError(error);
    return chrono.en ?? chrono;
  }
}

function customChronoDetection({
  text, 
  referenceDate, 
  dateFormat = "MDY",
}) {
  if (dateFormat === MOMENT_DMY_DATE_FORMAT) {
    let chronoResult = parseChronoDMY(text, referenceDate);

    if (isEmptyArray(chronoResult)) {
      return customChrono.parse(text, referenceDate || new Date(), {
        forwardDate: true,
      });
    } else {
      return chronoResult;
    }
  }

  return customChrono.parse(text, referenceDate || new Date(), {
    forwardDate: true,
  });
}

export function hasWeekDayAndImpliedDate(chronoResult) {
  return (
    chronoResult &&
    chronoResult[0] &&
    chronoResult[0].start &&
    chronoResult[0].start.knownValues &&
    chronoResult[0].start.impliedValues &&
    chronoHasImpliedDateButNotKnownDate(chronoResult)
  );
}

export function chronoHasImpliedDateButNotKnownDate(chronoResult) {
  return (
    Object.keys(chronoResult[0].start.knownValues).includes("weekday") &&
    ["day", "month", "year"].every(
      (val) => !Object.keys(chronoResult[0].start.knownValues).includes(val)
    ) &&
    ["day", "month", "year"].every((val) =>
      Object.keys(chronoResult[0].start.impliedValues).includes(val)
    )
  );
}

export function moveImpliedDateToKnownValue(chronoResult) {
  let updatedChrono = _.clone(chronoResult);

  ["day", "month", "year"].forEach((d) => {
    updatedChrono[0].start.knownValues[d] =
      chronoResult[0].start.impliedValues[d];
  });

  updatedChrono[0].start.knownValues = _.omit(
    updatedChrono[0].start.knownValues,
    "weekday"
  );

  return updatedChrono;
}

export function createAmPmBarWithHourDiff({
  timeZone,
  otherTimeZone,
  masterAccount,
  user,
}) {
  // Note: there is a problem here if there are multiple matching times.
  // e.g. Hawaii, et, and paris
  // hawaii and paris match on multiple times
  const shouldPrint = false;
  if (shouldPrint) {
    console.log("timeZones_", { timeZone, otherTimeZone });
  }
  if (!timeZone || !otherTimeZone) {
    return {};
  }
  const {
    startWorkHour,
    endWorkHour,
  } = getWorkHours({ masterAccount, user });

  const isWithinWorkHours = (hour) => {
    return hour >= startWorkHour && hour <= endWorkHour;
  };

  const timeZoneDiff = getOffsetBetweenTimeZones(otherTimeZone, timeZone);
  const currentTimeZoneStart = startOfMinute(set(new Date(), { hours: startWorkHour, minutes: 0 }));
  const currentTimeZoneEnd = startOfMinute(set(new Date(), { hours: endWorkHour, minutes: 0 }));

  const startWorkHourCurrentTZToOther = addMinutes(currentTimeZoneStart, timeZoneDiff).getHours();
  const endWorkHourCurrentTZToOther = addMinutes(currentTimeZoneEnd, timeZoneDiff).getHours();

  const startWorkHourOtherTZToCurrent = convertToTimeZone(
    getTimeInAnchorTimeZone(
      currentTimeZoneStart,
      otherTimeZone,
    ),
    { timeZone }
  ).getHours();
  const endWorkHourOtherTZToCurrent = convertToTimeZone(
    getTimeInAnchorTimeZone(
      currentTimeZoneEnd,
      otherTimeZone,
    ),
    { timeZone }
  ).getHours();
  if (shouldPrint) {
    console.log("currentTimeZoneStart_", currentTimeZoneStart);
    console.log("currentTimeZoneEnd_", currentTimeZoneEnd);
    console.log("startWorkHourCurrentTZToOther_", startWorkHourCurrentTZToOther);
    console.log("endWorkHourCurrentTZToOther_", endWorkHourCurrentTZToOther);
    console.log("startWorkHourOtherTZToCurrent_", startWorkHourOtherTZToCurrent);
    console.log("endWorkHourOtherTZToCurrent_", endWorkHourOtherTZToCurrent);
  }

  let startBar;
  let endBar;
  if (isWithinWorkHours(startWorkHourCurrentTZToOther) && isWithinWorkHours(startWorkHourOtherTZToCurrent)) {
    if (isWithinWorkHours(startWorkHourCurrentTZToOther + 1) && !isWithinWorkHours(startWorkHourOtherTZToCurrent + 1)) {
      shouldPrint && console.log("start_0_1")
      startBar = startWorkHour;
    } else if (isWithinWorkHours(startWorkHourCurrentTZToOther + 1) && !isWithinWorkHours(startWorkHourOtherTZToCurrent + 1)) {
      shouldPrint && console.log("start_0_2")
      startBar = startWorkHourOtherTZToCurrent;
    } else {
      shouldPrint && console.log("start_0_3")
      startBar = Math.max(startWorkHour, startWorkHourOtherTZToCurrent);
    }
    shouldPrint && console.log("start_0", { startBar, startWorkHour, startWorkHourOtherTZToCurrent });
  } else if (isWithinWorkHours(startWorkHourCurrentTZToOther) && !isWithinWorkHours(startWorkHourOtherTZToCurrent)) {
    startBar = startWorkHour;
    shouldPrint && console.log("start_1", startBar);
  } else if (!isWithinWorkHours(startWorkHourCurrentTZToOther) && isWithinWorkHours(startWorkHourOtherTZToCurrent)) {
    startBar = startWorkHourOtherTZToCurrent
    shouldPrint && console.log("start_2", startBar);
  } else {
    startBar = 12;
    shouldPrint && console.log("start_3", startBar);
  }

  if (isWithinWorkHours(endWorkHourCurrentTZToOther) && isWithinWorkHours(endWorkHourOtherTZToCurrent)) {
    const closestBiggerNumber = findClosestBigger(startBar, endWorkHour, endWorkHourOtherTZToCurrent);
    if (closestBiggerNumber) {
      shouldPrint && console.log("end_0_1")
      endBar = closestBiggerNumber;
    } else {
      shouldPrint && console.log("end_0_2")
      endBar = Math.min(endWorkHour, endWorkHourOtherTZToCurrent);
    }

    shouldPrint && console.log("end_0", { endBar, endWorkHour, endWorkHourOtherTZToCurrent });
  } else if (isWithinWorkHours(endWorkHourCurrentTZToOther) && !isWithinWorkHours(endWorkHourOtherTZToCurrent)) {
    endBar = endWorkHour;
    shouldPrint && console.log("end_1", endBar);
  } else if (!isWithinWorkHours(endWorkHourCurrentTZToOther) && isWithinWorkHours(endWorkHourOtherTZToCurrent)) {
    endBar = endWorkHourOtherTZToCurrent;
    shouldPrint && console.log("end_2", endBar);
  } else {
    endBar = 12;
    shouldPrint && console.log("end_3", endBar);
  }
  shouldPrint && console.log("result_", { startBar, endBar })

  return { start: startBar, end: endBar };
}

function findClosestBigger(n0, n1, n2) {
  // check if both n1 and n2 are bigger than n0
  if (n1 > n0 && n2 > n0) {
    // if both are bigger, return the smaller one
    return Math.min(n1, n2);
  }
  // if only one of n1 or n2 is bigger, return that one
  else if (n1 > n0) {
    return n1;
  }
  else if (n2 > n0) {
    return n2;
  }
  // if neither are bigger, return null or some appropriate value
  else {
    return null;
  }
}

export function breadcrumbData(data) {
  return {data}; // sentry only takes object and not array
}

/**
 * @deprecated Use isDictionary in typeGuards.ts.
 */
export function isObject(data) {
  // Array is also typpeof object in js

  return data && !Array.isArray(data) && typeof data === "object";
}

export function isValidAttendeeString(text) {
  if (!text) {
    return false;
  }

  let attendeeEmailWithoutWhiteSpaces = text ? text.trim() : "";

  let attendeesStringFiltered = attendeeEmailWithoutWhiteSpaces
    .replace(/,/g, " ")
    .replace(/\t/g, " ")
    .replace(/</g, " ")
    .replace(/>/g, " ");

  // Split out so we know if something is pasted in or manually entered
  let attendeesArray = attendeesStringFiltered.split(" ");

  if (attendeesArray.length > 1) {
    return true;
  } else {
    return isValidEmail(attendeeEmailWithoutWhiteSpaces);
  }
}

export function determineDateFormat(dateFormat) {
  if (dateFormat === MOMENT_DMY_DATE_FORMAT) {
    return "DD-MM-YYYY";
  } else if (dateFormat === MOMENT_YMD_DATE_FORMAT) {
    return "YYYY-MM-DD";
  } else {
    return "L";
  }
}

export function determineMomentDateFormat(dateFormat) {
  // for parsing moment
  if (dateFormat === MOMENT_DMY_DATE_FORMAT) {
    return "DD-MM-YYYY";
  } else if (dateFormat === MOMENT_YMD_DATE_FORMAT) {
    return "YYYY-MM-DD";
  } else {
    return "MM-DD-YYYY";
  }
}

// get the matches from recent contacts based on query
export function getRecentContactsMatches(recentContacts, query) {
  if (
    isEmptyArray(recentContacts) ||
    !query
  ) {
    return [];
  }

  let matchingRecentContacts = [];
  let loweredCaseQuery = query ? query.toLowerCase() : "";

  recentContacts.forEach((c) => {
    if (
      c &&
      ((c.email && c.email.toLowerCase().includes(loweredCaseQuery)) ||
        (c.name && c.name.toLowerCase().includes(loweredCaseQuery)))
    ) {
      matchingRecentContacts = matchingRecentContacts.concat(c);
    }
  });

  return matchingRecentContacts;
}

export function getPrimaryCalendarColor(primaryCalendar) {
  if (isEmptyObjectOrFalsey(primaryCalendar)) {
    return DEFAULT_PRIMARY_CALENDAR_COLOR;
  }

  const colorId = getCalendarColorID(primaryCalendar);
  const backgroundColor = getCalendarBackgroundColor(primaryCalendar);

  if (!isNullOrUndefined(colorId) || !isNullOrUndefined(backgroundColor)) {
    if (shouldUseBackgroundColorInsteadOfColorId(colorId, backgroundColor)) {
      // default background is different
      return backgroundColor;
    } else if (
      colorId &&
      GoogleColors.calendar[colorId] &&
      GoogleColors.calendar[colorId].background
    ) {
      return GoogleColors.calendar[colorId].background;
    }
  }

  return DEFAULT_PRIMARY_CALENDAR_COLOR;
}

export function shouldUseBackgroundColorInsteadOfColorId(
  colorId,
  backgroundColor
) {
  if (!colorId && backgroundColor) {
    return true;
  }

  if (
    colorId &&
    GoogleColors.old_calendar_colors[colorId] &&
    backgroundColor &&
    GoogleColors.old_calendar_colors[colorId].background !== backgroundColor
  ) {
    return true;
  }

  return false;
}

export function getFadedColor(
  color,
  defaultColor = DEFAULT_PRIMARY_CALENDAR_COLOR
) {
  if (!color) {
    return (
      GoogleColors.colorToFadeColorIndex[defaultColor] ||
      DEFAULT_PRIMARY_CALENDAR_FADED_COLOR
    );
  }

  if (_staticFadeColorIndex[color]) {
    return _staticFadeColorIndex[color];
  }

  if (GoogleColors.colorToFadeColorIndex[color]) {
    return GoogleColors.colorToFadeColorIndex[color].fadedColor;
  }

  let fadedColor = new TinyColor(color).tint(60).toHexString();
  _staticFadeColorIndex[color] = fadedColor;

  return fadedColor;
}

export function getBrightness(color) {
  if (_staticBrightnessIndex[color]) {
    return _staticBrightnessIndex[color];
  }

  let brightness = new TinyColor(color).getBrightness();
  _staticBrightnessIndex[color] = brightness;

  return brightness;
}

export function darkenColor(color) {
  if (color && !!_staticDarkenColorIndex[color]) {
    return _staticDarkenColorIndex[color];
  }

  let darkenedColor = new TinyColor(color || DEFAULT_PRIMARY_CALENDAR_COLOR)
    .darken(12)
    .toHexString();
  _staticDarkenColorIndex[color] = darkenedColor;

  return darkenedColor;
}

export function lightenColor(color) {
  if (color && !!_staticLightenColor[color]) {
    return _staticLightenColor[color];
  }

  const ligthenedColor = new TinyColor(color || DEFAULT_PRIMARY_CALENDAR_COLOR)
    .lighten(10)
    .toHexString();
  _staticLightenColor[color] = ligthenedColor;

  return ligthenedColor;
}

export function dimDarkModeColor(color) {
  if (!color) {
    return color;
  }

  if (_staticDimDarkModeColors[color]) {
    return _staticDimDarkModeColors[color];
  }

  let dimmedColor = new TinyColor(color).tint(20).shade(60).toHexString();

  _staticDimDarkModeColors[color] = dimmedColor;

  return dimmedColor;
}

export function setDebounceBackendTimer() {
  localData(LOCAL_DATA_ACTION.SET, DEBOUNCE_BACKEND_TIMER, new Date().toISOString());
}

export function getEventsEtag(eventsList) {
  // use to compare if list of events have been updated
  if (!eventsList || eventsList.length === 0) {
    return [];
  }

  let etagList = [];

  eventsList.forEach((e) => {
    const etag = getEventEtag(e);
    if (etag) {
      etagList = etagList.concat(etag);
    }
  });

  return etagList;
}

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function arrayContainSameItems(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every((val) => array2.includes(val))
  );
}

export function hasEventsArrayUpdated(eventsA, eventsB) {
  let eventsAEtag = getEventsEtag(eventsA);
  let eventsBEtag = getEventsEtag(eventsB);

  return !arrayEquals(eventsAEtag, eventsBEtag);
}

export function getDiffEvents(previousEventsList, updatedEventsList) {
  if (!updatedEventsList || (!previousEventsList && !updatedEventsList)) {
    return [];
  }

  if (
    (isEmptyArray(previousEventsList)) &&
    updatedEventsList
  ) {
    return updatedEventsList;
  }

  const previousListEtag = getEventsEtag(previousEventsList);

  let newEvents = [];
  updatedEventsList.forEach((e) => {
    const etag = getEventEtag(e);
    if (!previousListEtag.includes(etag)) {
      newEvents = newEvents.concat(e);
    }
  });

  return newEvents;
}

export function isMenuBar() {
  if (isNullOrUndefined(_isMenuBar)) {
    const isAppMenuBarApp = window?.location?.href?.toLowerCase().includes("menu") &&
      isElectron();

    _isMenuBar = isAppMenuBarApp;
    return isAppMenuBarApp;
  }

  return _isMenuBar;
}

export function resetGuessTimeZone() {
  _guessedTimeZone = getDeviceTimeZone();
}

export function guessTimeZone() {
  if (_guessedTimeZone) {
    return _guessedTimeZone;
  }
  return getDeviceTimeZone();
}

export function guessTimeZoneFromLocation() {
  if (hasBrowserTimeZone) {
    // so we don't ask for extra permission
    // only guess and call function below if we have to since we have to ask for geo location
    return;
  }
  try {
    if (!navigator?.geolocation?.getCurrentPosition) {
      return;
    }
    navigator.geolocation.getCurrentPosition(function(position) {
      const latitude = position?.coords?.latitude;
      const longitude = position?.coords?.longitude;
      if (!isInt(latitude) || !isInt(longitude)) {
        return;
      }
      const timeZone = tzlookup(latitude, longitude);
      if (isValidTimeZone(timeZone)) {
        _guessedTimeZoneBasedOnLocation = timeZone;
        saveLocationTimeZone(timeZone);
      }
    }, function(error) {
      devErrorPrint(error, "from_guessTimeZoneFromLocation_0");
    });
  } catch (error) {
    devErrorPrint(error, "from_guessTimeZoneFromLocation_1");
  }
}

function isFloatingPoint(num) {
  return num !== Math.floor(num);
}

// something ike 6.75, 6.25, 6.5
function roundToNearestQuarter(num) {
  return Math.round(num * 4) / 4;
}

function getBackupDetectedTimeZone() {
  try {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;
    if (isFloatingPoint(offsetHours)) {
      const roundedOffsetHours = roundToNearestQuarter(offsetHours);
      if (getValidFloatingPointGMTTimeZone(roundedOffsetHours)) {
        return getValidFloatingPointGMTTimeZone(roundedOffsetHours);
      }
    }

    switch (offsetHours) {
      case 0:
        return "UTC";
      case 13:
        return "Pacific/Tongatapu";
      case 14:
        return "Pacific/Kiritimati";
      default:
        const timeZone = `Etc/GMT${offsetHours >= 0 ? '-' : '+'}${Math.abs(offsetHours)}`;
        if (!ALL_VALID_GMT_OFFSET_TIMEZONES.includes(timeZone)) {
          return null;
        }
        return timeZone;
    }
  } catch {
    return POPULAR_TIME_ZONES_INDEX.LOS_ANGELES
  }
}

export function getDeviceTimeZone() {
  const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (isValidTimeZone(detectedTimeZone)) {
    return detectedTimeZone;
  }

  if (_guessedTimeZoneBasedOnLocation) {
    return _guessedTimeZoneBasedOnLocation;
  }

  const backupDetectedTimeZone = getBackupDetectedTimeZone();
  if (backupDetectedTimeZone) {
    return backupDetectedTimeZone;
  }
  return POPULAR_TIME_ZONES_INDEX.LOS_ANGELES; // this way it doesn't return undefined which would break redux
}

export function getEmailsFromAllLoggedInEmails() {
  const unformattedLoggedInAccounts = localData(LOCAL_DATA_ACTION.GET, LOGGED_IN_ACCOUNTS);

  if (!unformattedLoggedInAccounts) {
    return "no_logged_in_accounts";
  }

  const formattedLoggedInAccounts = safeJSONParse(unformattedLoggedInAccounts);
  let emailString = "";

  if (formattedLoggedInAccounts.length === 0) {
    return "empty_logged_in_acccounts_array";
  }

  formattedLoggedInAccounts.forEach((a) => {
    if (emailString.length === 0) {
      emailString = emailString + a.email;
    } else {
      emailString = emailString + `, ${a.email}`;
    }
  });

  return emailString;
}

export function getGoogleSettings(currentUser) {
  let settingsObject = {};

  if (hasUserSettings(currentUser)) {
    let settingItems = getUserSettings(currentUser);

    settingItems.forEach((s) => {
      settingsObject[s.id] = s.value;
    });
  }

  return settingsObject;
}

export function getGoogleEventId(event) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  if (getGCalEventId(event)) {
    return getGCalEventId(event);
  } else if (getEventID(event)) {
    return getEventID(event);
  }

  return null;
}

export function isMobileOS() {
  if (!navigator.userAgent) {
    return false;
  }
  const lowerCased = navigator.userAgent.toLowerCase();
  return (
    lowerCased.includes("iphone") ||
    lowerCased.includes("ipad") ||
    lowerCased.includes("ipod") ||
    lowerCased.includes("android")
  );
}

export function openConferencingURL(url, email) {
  const shouldPrint = isInternalTeamUserEmail(email); // TODO: delete once we figure out zoom not opening on desktop app
  shouldPrint && console.log("openConferencingURL_0", {url, email});
  if (!url) {
    shouldPrint && console.log("openConferencingURL_1");
    return;
  }
  shouldPrint && console.log("openConferencingURL_2");

  if (isUrlHangoutOrMeet(url)) {
    shouldPrint && console.log("openConferencingURL_3");
    if (!email) {
      OpenLink(url);
      return;
    }

    try {
      // Use try in case url is not valid in new URL
      const jsURL = new URL(url);
      const updatedUrl = appendUserDataToGoogleLinks(jsURL, email);

      OpenLink(updatedUrl);
    } catch (error) {
      OpenLink(url);
      handleError(error);
    }
  } else {
    shouldPrint && console.log("openConferencingURL_4");
    const loweredCaseURL = url.toLowerCase();
    shouldPrint && console.log("openConferencingURL_5");
    if (loweredCaseURL.includes("web.zoom.us") && !isMobileOS()) {
      shouldPrint && console.log("openConferencingURL_6");
      // companies could have zooms like this which needs browser to redirect: https://kajabi.zoom.us/my/first_name.last_name
      // otherwise we get blank loading screen
      // https://apple.stackexchange.com/questions/331348/how-to-open-a-zoom-link-in-a-non-browser-app-without-being-prompted

      // on join company zoom ("https://airbnb.zoom.us/my/brian.hou?pwd=thisismyurlpassword“)
      // zoom needs to redirect and give you actual meeting id:
      // 'zoommtg://airbnb.zoom.us/join?action=join&confno=7872623908&confid=dXRpZD1VVElEX2VkNjBmYjc2NWJlZDQ1ZTFiMDA5YWFhYzA3ZGEzZWUzJnVzcz1PZUUzZE5oWXZVQ01KdWo3REJ0Yno4djBOdjVGUHVTbmVkNVBMMjMyZGJkNU1WaTY4NHJIcVZLSlUyN1A2Q0NfWnJTTG02UERRNGYwZWlyeWdxZm9VODltRncuUkZaVFFaMHBZQmlrRWIzUCZ0aWQ9ZTM0YTczNGI2OGQzNDkyOWFiMDI5MWRjOGE2YTlkYjA%3D&browser=chrome'.
      // `/my` also need to reroute to get full url
      const ZOOM_LAUNCHERS = ["zoom.us/j/", "zoom.us/w/"];
      const searchString = ZOOM_LAUNCHERS.find((s) =>
        loweredCaseURL.includes(s)
      );
      if (!isNullOrUndefined(searchString)) {
        shouldPrint && console.log("openConferencingURL_7");
        const {
          password,
          meetingID
        } = getMeetingIDAndPassword({ url });

        if (meetingID && password) {
          shouldPrint && console.log("openConferencingURL_8");
          const joinURl = `zoommtg://zoom.us/join?confno=${meetingID}&pwd=${password}`;
          OpenLink(joinURl);
          return;
        }

        if (meetingID) {
          shouldPrint && console.log("openConferencingURL_9");
          // password does not exist
          const joinURl = `zoommtg://zoom.us/join?confno=${meetingID}`;
          OpenLink(joinURl);
          return;
        }
      }
    }
    shouldPrint && console.log("openConferencingURL_10");
    OpenLink(url);
  }
}

// https://stackoverflow.com/questions/12571650/catching-all-javascript-unhandled-exceptions
export function wrapperErrorHandler(message, file, line, col, error) {
  handleIndexDBError({message});
  sendMessageToSentry(
    "wrapperErrorHandler",
    `${message}, ${file}, ${line}, ${col}, ${error}`
  );
  console.error("Error: ", `${message}, ${file}, ${line}, ${col}, ${error}`);
  sendErrorToTracking({message: `${message}, ${file}, ${line}, ${col}, ${error}`});

  return true;
}

export function windowOnErrorHandler(e) {
  handleError(e);
  if (e) {
    console.error("Window error: ", e.message);
  }
  return true;
}

export function doesSentenceIncludeWord(sentence, word) {
  if (!sentence || !word) {
    return false;
  }
  // does not include word if word is part of another word
  // e.g. (if word is "focus", "unifocus" and "focusing" should not trigger it but "focus time" and "group focus" should
  // regex: /\bfocus\b/i
  // \b assert position at a word boundary: (^\w|\w$|\W\w|\w\W)
  //   focus matches the characters focus literally (case insensitive)
  // \b assert position at a word boundary: (^\w|\w$|\W\w|\w\W)
  // Global pattern flags
  // i modifier: insensitive. Case insensitive match (ignores case of [a-zA-Z])
  const re = new RegExp("\\b" + word + "\\b", "i");
  return sentence.match(re);
}

export function determineGoogleSettingsLink(email) {
  let jsURL = new URL("https://calendar.google.com/r/settings");
  let updatedUrl = appendUserDataToGoogleLinks(jsURL, email);
  return updatedUrl;
}

export function openGoogleLink(type, email) {
  if (!email) {
    return;
  }

  let url;
  switch (type) {
    case GMAIL:
      url = "https://mail.google.com";
      break;
    case GOOGLE_DRIVE:
      url = "https://drive.google.com";
      break;
    case GOOGLE_CALENDAR:
      url = "https://calendar.google.com";
      break;
    case NEW_GOOGLE_MEET:
      url = "https://meet.google.com/new";
      break;
    default:
      break;
  }

  if (!url) {
    return;
  }

  let jsURL = new URL(url);
  let updatedUrl = appendUserDataToGoogleLinks(jsURL, email);

  OpenLink(updatedUrl);
}

export function appendUserDataToGoogleLinks(jsURL, email) {
  let search_params = jsURL.searchParams;

  // make sure to use lower case authuser, authUser will error out
  search_params.set("authuser", email);

  jsURL.search = search_params.toString();

  //return updatedUrl
  return jsURL.toString();
}

export function createEventTextFromSuggestion(suggestion, format24HourTime) {
  // suggestion is an object and we need suggestion.value (moment)(startTime) and suggestion.duration
  let eventStart = suggestion.value;
  eventStart = eventStart.startOf("minute");

  let eventEnd = eventStart
    .clone()
    .add(suggestion.duration, "minutes")
    .startOf("minute");

  let dateText = createEventText(eventStart, eventEnd, format24HourTime);

  return { dateTimeText: dateText, eventStart, eventEnd };
}

export function createEventText(eventStart, eventEnd, format24HourTime) {
  let dateText;
  let timeFormat = format24HourTime ? DATE_TIME_24_HOUR_FORMAT : "h:mm A"; // need to keep this format for moment

  let year =
    eventStart.isSame(moment(), "year") && eventEnd.isSame(moment(), "year")
      ? ""
      : "YYYY";

  if (!eventStart.isSame(eventEnd, "day")) {
    dateText = `${eventStart.format(
      `ddd, MMM Do ${year} ${timeFormat}`
    )} - ${eventEnd.format(`ddd, MMM Do ${year} ${timeFormat}`)}`;
  } else {
    // Same day
    dateText = eventStart.format(`ddd, MMM Do ${year}`);

    let isSameAmPM = eventStart.format("A") === eventEnd.format("A");
    let timeFormatWithoutA = format24HourTime ? DATE_TIME_24_HOUR_FORMAT : "h:mm";

    dateText =
      dateText +
      ` ${eventStart.format(
        isSameAmPM ? timeFormatWithoutA : timeFormat
      )} - ${eventEnd.format(timeFormat)}`;
  }

  return dateText;
}

export function ConvertMinutesIntoDayHoursAndMinutesAbbreviated(minutes) {
  // 1440minutes = 1 day
  if (minutes >= 1440) {
    let num = minutes / 1440;
    let roundedNum = Math.round(num * 10) / 10;

    return `${roundedNum} ${pluralize(roundedNum, "day")}`;
  } else if (minutes >= 60) {
    let num = minutes / 60;
    let roundedNum = Math.round(num * 10) / 10;

    return `${roundedNum} ${pluralize(roundedNum, "hour")}`;
  } else {
    return `${minutes} ${pluralize(minutes, "minute")}`;
  }
}

export function convertInvalidMDYToDMY(chronoParse, dateFieldOrder) {
  if (
    !chronoParseHasKnownValues(chronoParse) ||
    dateFieldOrder !== MOMENT_DMY_DATE_FORMAT
  ) {
    // check if is DMY match
    return chronoParse;
  }

  let searchText = chronoParse[0].text || "";

  let regexResults = searchText.match(DATE_REGEX);

  if (isEmptyArrayOrFalsey(regexResults)) {
    // check if there is a date match
    // if 11-30-2020 -> no match with regex, so we return original chronoparse
    return chronoParse;
  }

  let startMonth = chronoParse[0].start.knownValues.month;
  let startDay = chronoParse[0].start.knownValues.day;

  if (!startMonth || !startDay || startDay > 12) {
    return chronoParse;
  }

  chronoParse[0].start.knownValues.month = startDay;
  chronoParse[0].start.knownValues.day = startMonth;

  if (chronoParse[0].end && chronoParse[0].end.knownValues) {
    const endMonth = chronoParse[0].end.knownValues.month;
    const endDay = chronoParse[0].end.knownValues.day;

    if (endMonth && endDay && endDay <= 12) {
      chronoParse[0].end.knownValues.month = endDay;
      chronoParse[0].end.knownValues.day = endMonth;
    }
  } else if (getChronoKnownEndFromStart(chronoParse)) {
    const knownEnd = getChronoKnownEndFromStart(chronoParse);
    const endMonth = knownEnd.month;
    const endDay = knownEnd.day;

    if (endMonth && endDay && endDay <= 12) {
      chronoParse[0].start.knownValues.knownEnd.month = endDay;
      chronoParse[0].start.knownValues.knownEnd.day = endMonth;
    }
  }

  // Only have to worry about DD-MM-YYYY order
  return chronoParse;
}

export function findFreeTimes(availableSlots, busySlots) {
  // TODO: Implement
  return;
}

export function renderTimeSlotsLine(content) {
  if (!content) {
    return null;
  }

  let slotIndex = 0;
  let timeSlotCount = 0;

  return (
    <div>
      {content.split("\n").map((i, key) => {
        const isTimeText = doesTextIncludeTime(i);
        slotIndex = timeSlotCount;

        if (isTimeText) {
          timeSlotCount += 1;
        }

        if (i.length === 0) {
          return (
            <div key={`availability_empty_line_${key}`}>
              <br />
            </div>
          );
        } else {
          return renderEachLine(i, key, slotIndex, isTimeText);
        }
      })}
    </div>
  );
}

export function doesTextIncludeTime(text) {
  return text.includes(" - ");
}

export function renderEachLine(line, key, timeSlotIndex, isTimeText) {
  const containBulletPoint = line.includes("\u2022");

  const trimmedLine = line.trimStart();
  if (containBulletPoint) {
    const withoutBullet = line.replace("\u2022", "");
    return (
      <div key={`without_bullet_point_text_${key}`}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>

        <span>{"\u2022"}</span>

        <span>&nbsp;</span>

        {renderEachLine(withoutBullet, key, timeSlotIndex, isTimeText)}
      </div>
    );
  } else if (isTimeText) {
    return (
      <span
        key={`div_time_line_${key}`}
        className="cursor-pointer"
        target="_blank"
      >
        {trimmedLine}
      </span>
    );
  } else {
    return <span key={`time_line_${key}`}>{trimmedLine}</span>;
  }
}

export function createAvailabilityTextFromEvent(data) {
  const {
    eventList,
    currentTimeZone,
    format24HourTime,
    hideDate,
    capDuration,
    duration,
    isRichText,
    preSlotsCopy,
    extraTimeZones,
    showAllTimeZones,
    skipMerge = false,
    skipPreSlotText
  } = data;
  const prefilteredTimeZone = extraTimeZones?.length > 0
    ? sortMultipleTimeZonesForSlots({ timeZones: removeDuplicatesFromArray([currentTimeZone, ...extraTimeZones]) })
    : [currentTimeZone];
  const allTimeZones = filterOutInvalidTimeZones(prefilteredTimeZone);

  const shouldAddMultipleTimeZones = showAllTimeZones;

  if (isEmptyArray(eventList)) {
    return "";
  }

  const selectedAvailabilitySlots = eventList.filter(
    (event) => event.isAvailability
  );

  const uniqueSlots = getUniqueSlots({ listOfSlots: selectedAvailabilitySlots });

  // sort immutably 
  const availabilitySlots = uniqueSlots.slice().sort((a, b) =>
    sortEventsJSDate(a, b, false)
  );
  const firstDate = availabilitySlots?.[0]?.eventStart;

  const abbreviatedTimeZone = shouldAddMultipleTimeZones
    ? getListOfTimeZoneAbbreviationsForSlotsHeader({ timeZones: allTimeZones, date: firstDate })
    : createAbbreviationForTimeZone(currentTimeZone, firstDate);

  let availabilityString;
  if (skipPreSlotText) {
    availabilityString = "";
  } else if (isRichText) {
    if (preSlotsCopy) {
      availabilityString = `${preSlotsCopy}\n\n`;
    } else {
      availabilityString = `Do any of these times (${abbreviatedTimeZone}) work for you? If it's easier, you can click on a slot below to book. \n\n`;
    }
  } else {
    if (preSlotsCopy) {
      availabilityString = `${preSlotsCopy}\n\n`;
    } else {
      availabilityString = hideDate
        ? ""
        : `Do any of these times (${abbreviatedTimeZone}) work for you?\n\n`;
    }
  }

  let availableSlotObject = {};
  const firstTimeZone = shouldAddMultipleTimeZones ? allTimeZones?.[0] : currentTimeZone;
  availabilitySlots.forEach((e) => {
    const {
      eventStart,
      eventEnd,
    } = e;
    const firstTimeZoneEventStart = firstTimeZone && currentTimeZone
      ? getTimeInAnchorTimeZone(eventStart, currentTimeZone, firstTimeZone)
      : eventStart;
    const date = format(firstTimeZoneEventStart, "MMMM d"); // August 19th
    const dayOfWeek = format(firstTimeZoneEventStart, "EEEE"); // Friday

    let datePlusDayOfWeek = date + " (" + dayOfWeek + "):";

    if (hideDate) {
      datePlusDayOfWeek = dayOfWeek;
    }

    if (datePlusDayOfWeek in availableSlotObject) {
      availableSlotObject[datePlusDayOfWeek] = [
        ...availableSlotObject[datePlusDayOfWeek],
        { eventStart, eventEnd },
      ];
    } else {
      availableSlotObject[datePlusDayOfWeek] = [
        { eventStart, eventEnd },
      ];
    }
  });

  // Merge slots that are together

  if (!skipMerge) {
    Object.keys(availableSlotObject).forEach((date) => {
      let eventStart = availableSlotObject[date][0].eventStart;
      let eventEnd = availableSlotObject[date][0].eventEnd;

      let slotsInDate = [{ eventStart, eventEnd }];

      availableSlotObject[date].forEach((slot) => {
        if (
          isSameOrAfterMinute(slot.eventStart, eventStart) &&
          isSameOrBeforeMinute(slot.eventEnd, eventEnd)
        ) {
        } else if (
          isSameOrBeforeMinute(slot.eventStart, eventEnd) &&
          isAfterMinute(slot.eventEnd, eventEnd)
        ) {
          eventEnd = slot.eventEnd;
          slotsInDate[slotsInDate.length - 1] = { eventStart, eventEnd };
        } else {
          eventStart = slot.eventStart;
          eventEnd = slot.eventEnd

          slotsInDate.push({ eventStart, eventEnd });
        }
      });

      availableSlotObject[date] = slotsInDate;
    });
  }

  Object.keys(availableSlotObject).forEach((date) => {
    availabilityString = availabilityString + date + "\n";

    // TODO: check pasting on everything (google doc, evernote, onenote, notion, email, etc) and online and offline things (without internet)
    availableSlotObject[date].forEach((slot, index) => {
      const {
        eventStart,
        eventEnd
      } = slot;
      if (capDuration && duration) {
        // right now this is only for personal links
        const cappedDurationEnd = capDurationEnd({
          start: eventStart,
          end: eventEnd,
          duration,
        });

        availabilityString =
          availabilityString +
          "   " +
          "\u2022 " +
          formatSlotsTextForTime({ time: eventStart, format24HourTime }) +
          " - " +
          formatSlotsTextForTime({ time: cappedDurationEnd, format24HourTime }) +
          "\n";
      } else if (shouldAddMultipleTimeZones) {
        // when users pass in multiple time zones
        const getSeperator = (index) => {
          return index === 0 ? "" : " / "
        };

        const allTimeZonesAvailabilityString = allTimeZones.reduce((accumulator, currentValue, currentIndex) => {
          return accumulator + getSeperator(currentIndex) + createTimeZoneAvailabilityLine({
            slot,
            timeZone: currentValue,
            format24HourTime,
            currentTimeZone,
            firstTimeZone,
            date: firstDate
          });
        }, "");

        availabilityString =
          availabilityString
          + "   " +
          "\u2022 " +
          allTimeZonesAvailabilityString +
          "\n";
      } else {
        availabilityString =
          availabilityString +
          "   " +
          "\u2022 " +
          formatSlotsTextForTime({ time: eventStart, format24HourTime }) +
          " - " +
          formatSlotsTextForTime({ time: eventEnd, format24HourTime }) +
          "\n";
      }
    });
  });

  return availabilityString;
}

// given a list of array of jsdates, return a list of unique dates
export function getUniqueListOfDates({ listOfJSDates }) {
  if (!listOfJSDates || listOfJSDates.length === 0) {
    return [];
  }

  return listOfJSDates.filter((date, index) => {
    return listOfJSDates.indexOf(date) === index;
  });
}

// given a list of array of slots (with eventStart and eventEnd), return a list of unique slots
export function getUniqueSlots({ listOfSlots }) {
  if (!listOfSlots || listOfSlots.length === 0) {
    return [];
  }

  return listOfSlots.filter((event, index) => {
    return listOfSlots.findIndex(item => {
      return item.eventStart.getTime() === event.eventStart.getTime() && item.eventEnd.getTime() === event.eventEnd.getTime();
    }) === index;
  });
}

export function isCreator(event, allCalendars) {
  const eventCreator = getEventCreator(event);
  if (!eventCreator) {
    return false;
  }

  const email = getEmailBasedOnCalendarId(event, allCalendars);
  const creatorEmail = eventCreator.email;

  return email === creatorEmail;
}

export function determineConferencingFromCurrentUser(currentUser, allCalendars = {}) {
  // not set in availability settings
  const defaultConference = getDefaultUserConferencing({ user: currentUser });

  if (defaultConference === BACKEND_ZOOM) {
    return ZOOM_CONFERENCING_OPTION;
  } else if (defaultConference === BACKEND_HANGOUT) {
    return HANGOUT_CONFERENCING_OPTION;
  } else if (defaultConference === BACKEND_NO_CONFERENCING) {
    return NO_CONFERENCING_OPTION;
  } else if (defaultConference === BACKEND_PHONE) {
    // if phone type is whatsapp -> set to whatsapp
    if (isPhoneConferencingWhatsApp(currentUser)) {
      return WHATS_APP_CONFERENCING_OPTION;
    }
    return PHONE_CONFERENCING_OPTION;
  } else if (defaultConference === BACKEND_WHATS_APP) {
    // if phone type is phone -> set to phone
    if (!isPhoneConferencingWhatsApp(currentUser)) {
      return PHONE_CONFERENCING_OPTION;
    }
    return WHATS_APP_CONFERENCING_OPTION;
  } else if (
    defaultConference === BACKEND_CUSTOM_CONFERENCING &&
    isValidCustomConferencing(currentUser)
  ) {
    return createCustomConferencingOption(currentUser);
  } else if (defaultConference === BACKEND_OUTLOOK_CONFERENCING) {
    return getDefaultOutlookUserConferencing({allCalendars, currentUser});
  }

  if (isOutlookUser(currentUser)) {
    return getDefaultOutlookUserConferencing({allCalendars, currentUser});
  }

  return HANGOUT_CONFERENCING_OPTION;
}

function getDefaultOutlookUserConferencing({allCalendars, currentUser}) {
  if (!currentUser?.email || isEmptyObjectOrFalsey(allCalendars)) {
    return NO_CONFERENCING_OPTION;
  }

  // Get the default conferencing option which resides on primary calendar for Outlook
  const outlookDefaultConferencing = getPrimaryCalendarConferencing(allCalendars, currentUser.email);

  // Return no conferencing if not found
  if (!outlookDefaultConferencing) {
    return NO_CONFERENCING_OPTION
  };

  return {
    label: convertOutlookConferencingToHumanReadable(outlookDefaultConferencing),
    value: outlookDefaultConferencing
  }
}

export function createInputTextBlock(
  text = "default",
  isDarkMode = false,
  borderColor,
  removeSpace = false
) {
  const createTextBlock = () => {
    return `${additionalBlankSpace()}<span contenteditable="false" style="margin-left: ${removeSpace ? "2" : "3"
      }px; margin-right: ${removeSpace ? "2" : "3"
      }px; padding: 3px; padding-top: 5px; border-radius: 4px; border: solid; border-width: 1px; border-color: ${borderColor || (isDarkMode ? StyleConstants.defaultFontColor : "#bfbfbf")
      };">${text}</span>${removeSpace ? "" : additionalSpaceSpan()}`;
  };

  return `<span contenteditable="false"/>${createTextBlock()}</span>`;
}

export function additionalBlankSpace() {
  return '<span contenteditable="false" style="display: inline-block;"></span>';
}

export function additionalSpaceSpan() {
  return '<span contenteditable="false" style="display: inline-block;">&nbsp;</span>';
}

export function findCursorPositionOnChange(a, b) {
  if (!a || !b || a.length === 0 || b.length === 0) {
    return 0;
  }

  let shorterLength = Math.min(a.length, b.length);

  for (let i = 0; i < shorterLength; i++) {
    if (a[i] !== b[i]) {
      return i;
    }
  }

  if (a.length !== b.length) {
    return shorterLength;
  }

  return -1;
}

export function extractTextFromHtml(s) {
  if (!s) {
    return "";
  }

  let span = document.createElement("span");
  span.innerHTML = s;

  let result = span.innerText || "";
  span = null;
  return result;
}

export function createTimeFromDayOfWeek({
  date,
  timeZone,
  hour,
  minute = 0,
  shouldFormatIntoUTC = true,
  bufferBefore,
  bufferAfter,
}) {
  // 1 is Monday
  // 0 is current sunday, 7 is next sunday
  if (timeZone) {
    const formattedTime = startOfMinute(
      getTimeInAnchorTimeZone(
        set(date, { hours: hour, minutes: minute || 0 }),
        timeZone
      )
    );

    if (shouldFormatIntoUTC) {
      if (bufferBefore) {
        return subMinutes(formattedTime, bufferBefore).toISOString();
      } else if (bufferAfter) {
        return addMinutes(formattedTime, bufferAfter).toISOString();
      }

      return formattedTime.toISOString();
    } else {
      if (bufferBefore) {
        return formatISO(subMinutes(formattedTime, bufferBefore));
      } else if (bufferAfter) {
        return formatISO(addMinutes(formattedTime, bufferAfter));
      }

      return formatISO(formattedTime);
    }
  } else {
    const formattedTime = startOfMinute(
      set(date, { hours: hour, minutes: minute || 0 })
    );

    if (shouldFormatIntoUTC) {
      if (bufferBefore) {
        return subMinutes(formattedTime, bufferBefore).toISOString();
      } else if (bufferAfter) {
        return addMinutes(formattedTime, bufferAfter).toISOString();
      }

      return formattedTime.toISOString();
    } else {
      if (bufferBefore) {
        return formatISO(subMinutes(formattedTime, bufferBefore));
      } else if (bufferAfter) {
        return formatISO(addMinutes(formattedTime, bufferAfter));
      }

      return formatISO(formattedTime);
    }
  }
}

export function determineSaveButtonShortcut(isSubmitting, isMac) {
  if (isSubmitting) {
    return (
      <div
        style={{
          width: 50,
          height: 50,
          position: "absolute",
          right: -20,
          top: -40,
        }}
      >
        {renderSpinner()}
      </div>
    );
  } else {
    return `${isMac ? COMMAND_KEY : "Ctrl"} C`;
  }
}

export function renderSpinner(className = null) {
  return <Spinner useSmallSpinner={true} className={className} />;
}

export function reformatMinDuration(mins) {
  if (!mins) {
    return { hours: 0, minutes: 0 };
  }

  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;

  return { hours, minutes };
}

export function convertDurationBackToMinutes(data) {
  // data = {hours: x, minutes: y}
  if (isEmptyObjectOrFalsey(data)) {
    return 0;
  }
  let totalMinutes = 0;
  if (data.hours) {
    totalMinutes = totalMinutes + data.hours * 60;
  }

  if (data.minutes) {
    totalMinutes = totalMinutes + data.minutes;
  }

  return totalMinutes;
}

export function determineDurationString(duration) {
  // duration format: {hours: int, minutes: int}
  let durationString = "";
  let minutes = duration.minutes || 0;
  let hours = duration.hours || 0;

  if (minutes > 60) {
    let minReminder = minutes % 60;
    let additionalHours = Math.floor(minutes / 60);
    hours = hours + additionalHours;
    minutes = minReminder;
  }

  if (hours > 0) {
    durationString = `${hours} ${pluralize(hours, "hr")} `;
  }

  if (minutes > 0) {
    durationString =
      durationString + `${minutes} ${pluralize(minutes, "min")} `;
  }

  if (!durationString) {
    return "0 minute meeting";
  }

  return durationString;
}

export function generateBookableSlotsFromObj({
  slots,
  timeZone,
  daysForward,
  shouldFormatIntoUTC = true,
  bufferBefore,
  bufferAfter,
  currentDate = new Date(),
  upcomingTrips
}) {
  let freeSlots = [];

  let daysArray = [...Array(daysForward).keys()]; // [0, 1, 2...]

  if (isEmptyObjectOrFalsey(slots)) {
    return [];
  }

  daysArray.forEach((d) => {
    const date = addDays(currentDate, d);
    const dayOfWeek = format(date, "EEEE");
    const dayOfWeekTimes = slots[dayOfWeek];
    const tripForDate = getCurrentTrip(upcomingTrips, date);
    const timeZoneToUse = tripForDate && getTripTimeZone(tripForDate)
      ? getTripTimeZone(tripForDate)
      : timeZone;

    if (!dayOfWeekTimes) {
      return;
    }

    dayOfWeekTimes.forEach((t) => {
      const start_utc = createTimeFromDayOfWeek({
        date,
        timeZone: timeZoneToUse,
        hour: t.start.hour,
        minute: t.start.minute,
        shouldFormatIntoUTC,
        bufferBefore,
      });

      let end_utc = createTimeFromDayOfWeek({
        date,
        timeZone: timeZoneToUse,
        hour: t.end.hour,
        minute: t.end.minute,
        shouldFormatIntoUTC,
        bufferAfter,
      });

      if (moment(start_utc).isAfter(moment(end_utc), "minute")) {
        end_utc = moment(end_utc).add(1, "day").format();
      }

      freeSlots = freeSlots.concat({
        start_time: start_utc,
        end_time: end_utc,
        index: freeSlots.length,
      });
    });
  });

  return freeSlots;
}

// Create free slots from busy slots
export function addBufferToEvents({
  time,
  isAllDay,
  shouldAddBuffer,
  isBufferBefore,
  param,
  isOutlook = false
}) {
  let { bufferBefore, bufferAfter } = param;
  const parsedTime = isOutlook ? parseJSON(time) : parseISO(time);

  if (!shouldAddBuffer) {
    // bug with utcToZonedTime
    // https://github.com/marnusw/date-fns-tz/issues/75
    return parsedTime.toISOString();
  }

  if (isBufferBefore) {
    return subMinutes(parsedTime, bufferBefore).toISOString();
  } else {
    return addMinutes(parsedTime, bufferAfter).toISOString();
  }
}

/**
 * @param {Object} options
 * @param {{ start: string, end: string }[]=} options.busySlots
 * @param {Record<string, {
 *  start: { hour: string, minute: string }
 *  end: { hour: string, minute: string }
 * }[]>} options.slots
 * @param {number} options.daysForward
 * @param {number} options.durationMinutes
 * @param {string} options.timeZone
 * @param {number=} options.bufferBefore
 * @param {number=} options.bufferAfter
 * @param {Date=} options.currentDate
 * @param {unknown[]} options.upcomingTrips
 * @returns {{ start_time: string, end_time: string }[]}
 */
export function generateFreeSlotsFromBusySlots({
  busySlots = [],
  slots,
  daysForward,
  durationMinutes,
  timeZone,
  bufferBefore = 0,
  bufferAfter = 0,
  currentDate = new Date(),
  upcomingTrips,
}) {
  let freeSlots = [];
  let roundUpInterval = shouldRoundToNearest15(durationMinutes) ? 15 : 30;
  let roundDownInterval = 15;

  // generateBookableSlotsFromObj -> array as utc
  // {
  //   "start_time": "2024-01-22T08:00:00.000Z",
  //   "end_time": "2024-01-22T16:00:00.000Z",
  //   "index": 0
  // }
  let dayOfWeekSlots = generateBookableSlotsFromObj({
    slots,
    timeZone,
    daysForward,
    bufferBefore,
    bufferAfter,
    currentDate,
    upcomingTrips,
  });

  dayOfWeekSlots.forEach((s) => {
    // function below is the problem
    const freeSlot = createFreeSlotsBasedOnBusySlots(
      s.start_time,
      s.end_time,
      busySlots
    );

    if (isEmptyArray(freeSlot)) {
      return;
    }

    freeSlot.forEach((f) => {
      const { start_time, end_time } = f;
      const roundedUpStartTime = RoundToClosestMinute(
        start_time,
        roundUpInterval,
        null,
        true
      ).startOf("minute");
      const roundedUpEndTime = RoundDownToClosestMinute({
        jsDate: moment(end_time).toDate(),
        interval: roundDownInterval
      });

      if (
        differenceInMinutes(roundedUpEndTime, roundedUpStartTime.toDate()) >= durationMinutes
      ) {
        freeSlots = freeSlots.concat({
          start_time: roundedUpStartTime.toISOString(),
          end_time: roundedUpEndTime.toISOString(),
        });
      }
    });
  });

  return freeSlots;
}

function createBusyBlockerForCurrentTime({
  roundUpInterval,
  bufferFromNow,
  currentDate = new Date()
}) {
  // add 2 hours from now
  const bufferWithSafety = bufferFromNow ?? 3;

  const currentStart = startOfMinute(
    RoundToClosestMinuteJSDate(
      subMinutes(currentDate, bufferWithSafety),
      roundUpInterval
    )
  );
  const currentEnd = startOfMinute(
    RoundToClosestMinuteJSDate(
      addMinutes(currentDate, bufferWithSafety),
      roundUpInterval
    )
  );

  return { start: currentStart.toISOString(), end: currentEnd.toISOString() };
}

export function getAllBusySlots({
  response,
  roundUpInterval,
  bufferBefore,
  bufferAfter,
  bufferFromNow,
  currentDate = new Date(),
}) {
  if (!response?.free_busy) {
    return [];
  }

  let busySlots = [createBusyBlockerForCurrentTime({
    roundUpInterval,
    bufferFromNow,
    currentDate
  })];

  const bufferParam = { bufferBefore, bufferAfter };

  response.free_busy.forEach((info) => {
    if (!info.busy_slots) {
      return;
    }

    info.busy_slots.forEach((r) => {
      let slot = {
        start: addBufferToEvents({
          time: r.start,
          isAllDay: false,
          shouldAddBuffer: true,
          isBufferBefore: true,
          param: bufferParam,
          isOutlook: r.is_outlook
        }),
        end: addBufferToEvents({
          time: r.end,
          isAllDay: false,
          shouldAddBuffer: true,
          isBufferBefore: false,
          param: bufferParam,
          isOutlook: r.is_outlook
        }),
      };

      busySlots = busySlots.concat(slot);
    });
  });

  busySlots = mergeBusySlots(busySlots);

  return busySlots;
}

export function generateRandomId(length = 32) {
  if (!length) {
    return null;
  }
  // 32 is the default length of google event ids;
  let alphabet = "abcdefghijklmnopqrstuv".split("");
  let numbers = "1234567890".split("");

  let options = alphabet.concat(numbers);

  let result = "";

  let i;
  for (i = 0; i < length; i++) {
    let item = options[Math.floor(Math.random() * options.length)];

    result = result + item;
  }

  return result;
}

export function blurOnEscape(event, elementId) {
  if (event && event.keyCode === KEYCODE_ESCAPE) {
    let element = document.getElementById(elementId);
    element && element.blur();
    element = null;
  }
}

export function sortPersonalLinks(links) {
  if (!links || links.length === 0) {
    return [];
  }

  return links.sort((a, b) => SortEvents(a, b, "created_at"));
}

export function createDefaultPersonalLinkEventSummary({ user, masterAccount }) {
  const {
    fullName
  } = getUserName({ masterAccount, user });
  if (
    isEmptyObjectOrFalsey(user) ||
    !(fullName && user.email)
  ) {
    return "30 Minute Meeting";
  }

  return `${fullName || user.email
    } <> ${INVITEE_NAME_BLOCK}`;
}

export function checkIfBookableSlotsAreValid(slots) {
  // array with start_time and end_time
  if (!slots || slots.length === 0) {
    return false;
  }

  let isValid = false;

  slots.forEach((s) => {
    if (
      (!isValid && moment(s.start_time).isAfter(moment(), "minute")) ||
      moment(s.end_time).isAfter(moment(), "minute")
    ) {
      isValid = true;
    }
  });

  return isValid;
}

function capDurationEnd({ start, end, duration }) {
  const durationMinutes = differenceInMinutes(end, start)
  const maxDuration = Math.max(4 * duration, 120);

  if (durationMinutes <= maxDuration) {
    return end;
  } else {
    const updatedEnd = addMinutes(start, maxDuration);
    return RoundDownToClosestMinute({
      jsDate: updatedEnd,
      interval: shouldRoundToNearest15(duration) ? 15 : 30
    });
  }
}

export function determineTimeRemaining(nextEvent) {
  if (isEmptyObjectOrFalsey(nextEvent)) {
    return {
      timeRemaining: 10,
      timeRemainingText: "starts in 10 minutes",
      trayText: null,
    };
  }

  const eventStart = startOfMinute(parseUTCDate(nextEvent.defaultStartTime));
  const currentTime = startOfMinute(new Date());
  const timeRemaining = differenceInMinutes(eventStart, currentTime);

  if (!isBeforeMinute(currentTime, eventStart) || timeRemaining === 0) {
    return { timeRemaining: 0, timeRemainingText: "", trayText: "NOW" };
  }

  let displayTimeRemaining = `${timeRemaining} ${pluralize(timeRemaining, "min")}`;

  if (timeRemaining > 60) {
    const hour = Math.floor(timeRemaining / 60);
    const minute = timeRemaining % 60;

    displayTimeRemaining = `${hour} hr`;

    if (minute > 0) {
      displayTimeRemaining += ` ${minute} ${pluralize(minute, "min")}`;
    }
  }

  return {
    timeRemaining,
    timeRemainingText: `starts in ${displayTimeRemaining}`,
    trayText: `${displayTimeRemaining}`,
  };
}

export function determineElectronLoginEnv() {
  const env = process.env.REACT_APP_CLIENT_ENV;
  if (!env) {
    /* Set electron env */
    // return "production";
    return ENVIRONMENTS.LOCAL;
  }

  return env;
}

export function isTemplateZoomConferencing(conferenceData) {
  return (
    conferenceData?.isTemplate &&
    conferenceData?.conferenceType === GoogleCalendarService.zoomString
  );
}

export function currentTimeInUTCZFormat() {
  return new Date().toISOString();
}

export function determineSyncWindow(params) {
  const {
    selectedDay,
    selectedCalendarView,
    shouldSyncTodayWindow, // default to false
    weekStart,
    isGoogleInitialSync
  } = params;
  // Creates window that always shows events for the day that is currently selected.
  // for monthly calendar, we pull the current month
  // for weekly calendar, we grab the +- 2 weeks
  // if user moves left or right on calendar, we always pull indexdb and always hit the backend to fetch for events
  let currentSelectedDay =
    !shouldSyncTodayWindow && selectedDay && isValidJSDate(selectedDay)
      ? selectedDay
      : new Date();

  if (isMainCalendarMonthView(selectedCalendarView)) {
    let minDate = getFirstDayOfMonthlyCalendarJSDate(
      currentSelectedDay,
      weekStart
    );
    let maxDate = getLastDayOfMonthlyCalendarJSDate(
      currentSelectedDay,
      weekStart
    );

    // if (differenceInDays(new Date(), minDate) < 7) {
    //   // if at the beginning of the month, add another week backward
    //   minDate = subDays(minDate, 7);
    // }

    // if (differenceInDays(maxDate, new Date()) > 7) {
    //   // if at the end of the month, add another week forward
    //   maxDate = addDays(maxDate, 7);
    // }

    return { minDate, maxDate };
  } else if (isGoogleInitialSync) {
    const {
      weeksBackward,
      weeksForward
    } = getInitialSyncWindowForGoogle({
      windowJSDate: currentSelectedDay,
      weekStart,
      selectedCalendarView
    });
    return { minDate: weeksBackward, maxDate: weeksForward };
  } else {
    const {
      weeksBackward,
      weeksForward
    } = getFetchWindowStartAndEnd({
      windowJSDate: currentSelectedDay,
      weekStart,
      selectedCalendarView
    });
    return { minDate: weeksBackward, maxDate: weeksForward };
  }
}

export function isMainCalendarMonthView(selectedCalendarView) {
  return selectedCalendarView === BACKEND_MONTH;
}

export function filterOtherPeoplesCalendarsAndReport(param) {
  let { events, location, allCalendarIds, currentUserEmail } = param;

  if (!events || events.length === 0) {
    return [];
  }

  let eventsWithoutUserCalendarId = [];
  let eventsNotInUserCalendars = [];
  let safeEvents = [];

  events.forEach((e) => {
    if (!e || !getEventUserCalendarID(e)) {
      eventsWithoutUserCalendarId = eventsWithoutUserCalendarId.concat(e);
    } else if (!allCalendarIds.includes(getEventUserCalendarID(e))) {
      eventsNotInUserCalendars = eventsNotInUserCalendars.concat(e);
    } else {
      safeEvents = safeEvents.concat(e);
    }
  });

  let environment = process.env.REACT_APP_CLIENT_ENV;

  if (!environment || environment === ENVIRONMENTS.DEV) {
    if (eventsWithoutUserCalendarId.length > 0) {
      console.error(
        "detect other peoples calendar!!! noUserCalendarIdList",
        eventsWithoutUserCalendarId
      );
      console.error("noUserCalendarIdList allCalendarId", allCalendarIds);
      console.error("location", location);
      console.error("currentUserEmail", currentUserEmail);
    }

    if (eventsNotInUserCalendars.length > 0) {
      console.error(
        "detect other peoples calendar!!! notInCombinedCalendarsList",
        eventsNotInUserCalendars
      );
      console.error("notInCombinedCalendarsList allCalendarId", allCalendarIds);
      console.error("location", location);
      console.error("currentUserEmail", currentUserEmail);
    }
  } else {
    if (eventsWithoutUserCalendarId.length > 0) {
      // https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
      const reportedError = new Error(
        "Getting other user's events - events without user_calendar_id"
      );
      Sentry.captureException(
        reportedError,
        {
          tags: {
            error: "getting other user's events",
            type: "events without user_calendar_id",
            location,
            currentUserEmail: currentUserEmail || "no email",
          },
          extra: {
            events: eventsWithoutUserCalendarId,
            type: "events without user_calendar_id",
            location,
            allCalendarIds,
            currentUserEmail,
          },
        }
      );
      sendErrorToTracking(reportedError, currentUserEmail);
    }

    if (eventsNotInUserCalendars.length > 0) {
      const reportedError = new Error(
        "Getting other user's events - events not included in all calendar id list"
      );
      Sentry.captureException(
        reportedError,
        {
          tags: {
            error: "getting other user's events",
            type: "events not included in all calendar id list",
            location,
            currentUserEmail: currentUserEmail || "no email",
          },
          extra: {
            events: eventsNotInUserCalendars,
            location,
            type: "events not included in all calendar id list",
            allCalendarIds,
            currentUserEmail,
          },
        }
      );
      sendErrorToTracking(reportedError, currentUserEmail);
    }
  }

  return safeEvents;
}

export function hasEditPermission(event) {
  return getEventGuestPermissions(event, GUESTS_CAN_MODIFY);
}

export function sentryHandleFailToCreateOrUpdate({
  originalEvent,
  response,
  currentUserEmail,
  isRecurring,
  location = "Not provided",
  lastState,
  isUpdate,
}) {
  Sentry.setUser({ email: currentUserEmail });
  const reportedError = new Error(
    `Failed to ${isUpdate ? "update" : "create"} ${isRecurring ? "Recurring" : "single"
    } event`
  );
  Sentry.captureException(
    reportedError,
    {
      tags: {
        error: "Failed to update",
        type: isRecurring ? "Recurring" : "Single_event",
        location,
        currentUserEmail: currentUserEmail || "no email",
        isUpdate: isUpdate,
      },
      extra: {
        events: originalEvent,
        type: isRecurring ? "Recurring" : "Single_event",
        location,
        currentUserEmail,
        response,
        lastState,
        isUpdate: isUpdate,
        isOrganizer: isOrganizerSelf(originalEvent),
        hasEditPermission: hasEditPermission(originalEvent),
      },
    }
  );

  sendErrorToTracking(reportedError, currentUserEmail);
}

export function getTodayDate(timeZone = null) {
  // need to check for today's date in different time zone
  return convertToTimeZone(new Date(), {
    timeZone: timeZone || guessTimeZone(),
  });
}

export function isEventInThePast({
  event,
  currentTimeZone,
}) {
  const currentDate = getCurrentTimeInCurrentTimeZone(currentTimeZone);
  if (isAvailabilityEvent(event)
    && event.eventStart
    && event.rbcEventEnd
  ) {
    const {
      eventStart,
      rbcEventEnd
    } = event;
    return (
      isBefore(eventStart, currentDate) &&
      isBefore(rbcEventEnd, currentDate)
    );
  }

  if (!event || !event.defaultStartTime || !event.defaultEndTime) {
    return false;
  }

  if (getEventStartAllDayDate(event)) {
    return (
      !isAfter(endOfDay(parseISO(event.defaultStartTime)), currentDate) &&
      !isAfter(endOfDay(parseISO(event.defaultEndTime)), currentDate)
    );
  } else {
    const parsedStart = parseISO(event.defaultStartTime);
    const parsedEnd = parseISO(event.defaultEndTime);
    if (isValidTimeZone(currentTimeZone)) {
      return (
        !isAfter(convertToTimeZone(parsedStart, {
          timeZone: currentTimeZone,
        }), currentDate) &&
        !isAfter(convertToTimeZone(parsedEnd, {
          timeZone: currentTimeZone,
        }), currentDate)
      );
    }

    return (
      !isAfter(parsedStart, currentDate) &&
      !isAfter(parsedEnd, currentDate)
    );
  }
}

export function createFadedColorIfIndexDoesNotExist(color, shouldFade) {
  if (!color) {
    return color;
  }

  const lowerCaseColor = color.toLowerCase();

  if (GoogleColors.colorToFadeColorIndex[lowerCaseColor]) {
    return shouldFade
      ? GoogleColors.colorToFadeColorIndex[lowerCaseColor].fadedColor
      : color;
  } else {
    if (shouldFade) {
      if (_staticFadeColorIndex[lowerCaseColor]) {
        return _staticFadeColorIndex[lowerCaseColor];
      } else {
        const fadedColor = new TinyColor(color).tint(60).toHexString();
        _staticFadeColorIndex[lowerCaseColor] = fadedColor;

        return fadedColor;
      }
    }

    return color;
  }
}

export function getFirstDayOfWeekJsDate(date, weekStart = 0) {
  return startOfWeek(date, {
    weekStartsOn: weekStart ? parseInt(weekStart) : 0,
  });
}

export function getLastDayOfWeekJsDate(date, weekStart = 0) {
  return endOfWeek(date, { weekStartsOn: weekStart ? parseInt(weekStart) : 0 });
}

export function isBeforeMinute(JSDateA, JSDateB) {
  return isBefore(startOfMinute(JSDateA), startOfMinute(JSDateB));
}

export function isSameOrBeforeMinute(JSDateA, JSDateB) {
  let timeA = startOfMinute(JSDateA);
  let timeB = startOfMinute(JSDateB);

  return isSameMinute(timeA, timeB) || isBefore(timeA, timeB);
}

export function isSameOrAfterDay(JSDateA, JSDateB) {
  let timeA = startOfDay(JSDateA);
  let timeB = startOfDay(JSDateB);

  return isSameDay(timeA, timeB) || isAfter(timeA, timeB);
}

export function isSameOrAfterMinute(JSDateA, JSDateB) {
  let timeA = startOfMinute(JSDateA);
  let timeB = startOfMinute(JSDateB);

  return isSameMinute(timeA, timeB) || isAfter(timeA, timeB);
}

export function isAfterMinute(JSDateA, JSDateB) {
  return isAfter(startOfMinute(JSDateA), startOfMinute(JSDateB));
}

export function isBeforeDay(JSDateA, JSDateB) {
  return isBefore(startOfDay(JSDateA), startOfDay(JSDateB));
}

export function isSameOrBeforeDay(JSDateA, JSDateB) {
  return (
    isSameDay(JSDateA, JSDateB) ||
    isBefore(startOfDay(JSDateA), startOfDay(JSDateB))
  );
}

export function isAfterDay(JSDateA, JSDateB) {
  return isAfter(startOfDay(JSDateA), startOfDay(JSDateB));
}

export function convertDateFieldOrderToJSDateOrder(dateFieldOrder) {
  switch (dateFieldOrder) {
    case MOMENT_DMY_DATE_FORMAT:
      return "dd-MM-yyyy";
    case MOMENT_MDY_DATE_FORMAT:
      return "MM-dd-yyy";
    case MOMENT_YMD_DATE_FORMAT:
      return "yyyy-MM-dd";
    default:
      return "MM-dd-yyyy";
  }
}

export function getRRuleStringFromRecurrence(originalEvent) {
  // recurrence should be array
  // sometimes, recurrence array also hold time zone info so need to look for RRule
  const eventRecurrence = getEventRecurrence(originalEvent);
  if (!eventRecurrence || eventRecurrence.length === 0) {
    return "";
  }

  if (isTypeString(eventRecurrence)) {
    return cleanRRuleString(eventRecurrence);
  }

  let rRuleString =
    eventRecurrence.find((element) =>
      element?.toLowerCase().includes("rrule")
    ) ?? "";

  if (!rRuleString) {
    rRuleString = eventRecurrence[0] || "";
  }

  return cleanRRuleString(rRuleString);
}

export function determineDateFnsKeyName(key) {
  // date-fns uses hours and minutes instead of hour and minute (pluralize);
  if (key === "hour") {
    return "hours";
  } else if (key === "minute") {
    return "minutes";
  }

  return key;
}

export function isValidJSDate(date) {
  if (!date) {
    return false;
  }
  return (typeof date !== "string" || !date instanceof String) && isValid(date);
}

export function isDSTObserved(inputTimeZone = null, inputDate = null) {
  let timeZone = inputTimeZone || guessTimeZone();
  if (timeZone === "Australia/Brisbane") {
    // there's a bug where we can't tell when Brisbane is in DTS;
    timeZone = "Australia/Sydney";
  }

  const date = inputDate || new Date();
  // https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
  return getTimeZoneOffSet(date, timeZone) < stdTimezoneOffset(date, timeZone);
}


function stdTimezoneOffset(date, timeZone) {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);

  return Math.max(
    getTimeZoneOffSet(jan, timeZone),
    getTimeZoneOffSet(jul, timeZone)
  );
}

export function isValidTimeZone(tz) {
  if (!tz) {
    return false;
  }
  if (_validTimeZones?.[tz]) {
    return true;
  }
  if (_invalidTimeZones?.[tz]) {
    return false;
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    if (!_validTimeZones) {
      // sanity check before setting
      _validTimeZones = {};
    }
    _validTimeZones[tz] = true;
    return true;
  } catch (ex) {
    if (!_invalidTimeZones) {
      // sanity check before setting
      _invalidTimeZones = {};
    }
    _invalidTimeZones[tz] = true;
    return false;
  }
}

export function getTimeZoneOffSet(date, timeZone) {
  const converted = convertToTimeZone(date, { timeZone });
  const utcTimeZone = convertToTimeZone(date, { timeZone: UTC_TIME_ZONE });

  return -1 * differenceInMinutes(converted, utcTimeZone);
}

// return Date object
export function convertToTimeZone(date, options, log = false) {
  if (!isValidTimeZone(options?.timeZone) || options.timeZone === guessTimeZone()) {
    return parseUTCDate(date);
  }
  // options = {timeZone: tz};
  // https://stackoverflow.com/questions/10087819/convert-date-to-another-timezone-in-javascript
  try {
    const convertedTimeInTimeZone = new Date(
      parseUTCDate(date).toLocaleString(
        "en-US",
        safeGuardTimeZones(options)
      )
    );
    if (isNaN(convertedTimeInTimeZone.getTime())) {
      return new Date();
    }

    if (options.timeZone === "Pacific/Fiji") {
      // fiji government recently (2022) stopped supporting day light savings but browsers haven't fixed this yet
      // below checks for dls without calling convertToTimeZone which causes endless loop
      const currentTime = new Date();
      const fijiTime = new Date(currentTime.toLocaleString("en-US", { timeZone: 'Pacific/Fiji' }));
      const utcTime = new Date(currentTime.toLocaleString("en-US", { timeZone: UTC_TIME_ZONE }));
      const diff = differenceInHours(fijiTime, utcTime);
      if (diff !== 12) {
        return subHours(convertedTimeInTimeZone, 1);
      }
    }

    return convertedTimeInTimeZone;
  } catch (error) {
    handleError(error);
    devErrorPrint(error, "convertToTimeZone_error")
    return new Date();
  }
}

export function parseUTCDate(dateInput) {
  const isString = (typeof dateInput === 'string');
  // return isString ? new Date(Date.parse(dateInput)) : dateInput; // Bruno edits
  return isString ? new Date(parseISO(dateInput)) : dateInput;
}

export function emojiAtBeginningOfString(string) {
  const regex = EmojiRegex();
  let match = regex.exec(string);

  return match && match.index === 0 ? match["0"] : null;
}

export function getPhoneNumberString(phoneNumber, regionCode) {
  // '+46707123456'
  let pn = new PhoneNumber(phoneNumber, regionCode);

  return pn.getNumber();
}

export function getNextUpcomingEvent(listOfEvents) {
  if (!listOfEvents || listOfEvents.length === 0) {
    return null;
  }

  let upcomingEvent;
  listOfEvents.forEach((e) => {
    if (isSameOrAfterMinute(e.eventStart, new Date())) {
      if (!upcomingEvent) {
        upcomingEvent = e;
      } else if (isBeforeMinute(e.eventStart, upcomingEvent.eventStart)) {
        upcomingEvent = e;
      }
    }
  });

  return upcomingEvent;
}

export function getLastPreviousEvent(listOfEvents) {
  // get last event that's before now
  if (!listOfEvents || listOfEvents.length === 0) {
    return null;
  }

  let upcomingEvent;
  listOfEvents.forEach((e) => {
    if (isBeforeMinute(e.eventStart, new Date())) {
      if (!upcomingEvent) {
        upcomingEvent = e;
      } else if (isAfterMinute(e.eventStart, upcomingEvent.eventStart)) {
        upcomingEvent = e;
      }
    }
  });

  return upcomingEvent;
}

export function isChronoAllDay(chrono) {
  return (
    chrono &&
    chrono[0] &&
    chrono[0].start &&
    chrono[0].start.knownValues &&
    chrono[0].start.knownValues.isAllDay
  );
}

export function convertEmailToName({
  email,
  currentUser,
  emailToNameIndex,
  masterAccount,
  allLoggedInUsers,
  attendee, // so we can get clearBit information
  allCalendars, // there are places where we don't want to prioritize calendar name (extenral like attendee name) -> avoid passing in on these.
  doNotReturnEmailByDefault = false,
  checkForPrimaryOnly = true,
  checkForMatchingCalendarOnProviderID = false, // also check based on providerID and not just owner_email. Should be used carefully
  distroListDictionary,
}) {
  if (getDistroListName({email, distroListDictionary})) {
    return getDistroListName({email, distroListDictionary});
  }

  const matchingCalendar = getMatchingCalendarWithCalendarOwnerFromEmail({
    email,
    allCalendars,
    checkForPrimaryOnly, // multiple calendars with same owner email
    checkForMatchingCalendarOnProviderID,
  });
  if (matchingCalendar) {
    // if we find matching calendar with name -> prioritize this since it's more accurate and something the user 
    // has controlled more explicitly in the past
    const calendarName = getCalendarName({
      calendar: matchingCalendar,
      emailToNameIndex,
      currentUser,
      masterAccount,
    });
    if (calendarName) {
      // only return if the name and email are different. Otherwise, we could alwys just show email
      return calendarName;
    }
  }

  const matchingUser = allLoggedInUsers?.find(user => email && equalAfterTrimAndLowerCased(getUserEmail(user), email));
  if (matchingUser) {
    const {
      fullName,
      firstName,
      lastName,
    } = getUserName({ user: matchingUser, masterAccount });
    if (fullName) {
      return fullName;
    }

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
  }

  if (email && equalAfterTrimAndLowerCased(getUserEmail(currentUser, email))) {
    const { fullName, firstName, lastName } = getUserName({ user: currentUser, masterAccount });
    // do not need to use `getUserName` here because this is used to get the current user first_name and last_name on display attendee
    if (fullName) {
      return fullName;
    }

    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
  }

  if (emailToNameIndex?.[email] && !isValidEmail(emailToNameIndex?.[email])) {
    return emailToNameIndex[email];
  }

  const clearbitName = getClearbitNameFromAttendee(attendee)?.trim();
  if (clearbitName) {
    return clearbitName;
  }

  if (doNotReturnEmailByDefault) {
    return "";
  }
  return email || "";
}

export function getCurrentTimeInCurrentTimeZone(currentTimeZone) {
  // used to compare to current time since moment converts on a timezone basis
  if (!isValidTimeZone(currentTimeZone)) {
    return new Date();
  }
  return convertToTimeZone(new Date(), {
    timeZone: currentTimeZone,
  });
}

export function clearCommonUsefulFunctionCache() {
  _staticDarkenColorIndex = {};
  _staticFadeColorIndex = {};
  _staticBrightnessIndex = {};
  _staticDimDarkModeColors = {};
  _staticTimeZoneAbbreviation = {};
  _staticTimeZoneList = {};
  _staticLightenColor = {};
}

export function shouldCalendarBeShown(calendar) {
  return !getCalendarIsDeleted(calendar) && !getCalendarIsHidden(calendar);
}

export function isSmallMobileClient() {
  if (!window || !window.innerWidth) {
    return false;
  }

  return window?.innerWidth <= 450; //roughly iphone 13 pro max
}

export function isMobile() {
  if (!window || !window.innerWidth) {
    return false;
  }

  return window?.innerWidth <= 600;
}

export function isMobileHorizontally() {
  if (!window || !window.innerWidth) {
    return false;
  }

  return window?.innerWidth <= 600;
}

export function getMemoryUsageData() {
  if (!window?.performance?.memory) {
    return {
      totalJSHeapMB: "n/a",
      usedJSHeapMB: "n/a",
      jsHeapLimitMB: "n/a",
      tier: "n/a",
    };
  }

  const BYTE_IN_MB = 1000000;

  // https://stackoverflow.com/questions/2530228/jquery-or-javascript-to-find-memory-usage-of-page
  // window.performance.memory.usedJSHeapSize/1000000
  const { totalJSHeapSize, usedJSHeapSize, jsHeapSizeLimit } =
    window.performance.memory;

  const totalMBUsed = Math.round(usedJSHeapSize / BYTE_IN_MB);

  let memoryTier = "n/a";
  if (totalMBUsed >= 0 && totalMBUsed < 50) {
    memoryTier = "0_to_50";
  } else if (totalMBUsed >= 50 && totalMBUsed < 100) {
    memoryTier = "50_to_100";
  } else if (totalMBUsed >= 100 && totalMBUsed < 200) {
    memoryTier = "100_to_200";
  } else if (totalMBUsed >= 200 && totalMBUsed < 300) {
    memoryTier = "200_to_300";
  } else if (totalMBUsed >= 300 && totalMBUsed < 400) {
    memoryTier = "300_to_400";
  } else if (totalMBUsed >= 400 && totalMBUsed < 1000) {
    memoryTier = "400_1000";
  } else {
    memoryTier = "higher_than_1000";
  }

  return {
    totalJSHeapMB: Math.round(totalJSHeapSize / BYTE_IN_MB),
    usedJSHeapMB: totalMBUsed,
    jsHeapLimitMB: Math.round(jsHeapSizeLimit / BYTE_IN_MB),
    memoryTier,
  };
}

export function getActiveCommandCentersKey() {
  return isMac() ? COMMAND_KEY : PC_CONTROL_KEY;
}

export function getActiveCommandCentersKeyForMousetrap() {
  return isMac() ? "command" : "control";
}

export function isOnHomePage() {
  return window?.location?.href?.toLowerCase()?.includes("home");
}

export function isOnAddNewEventPage() {
  return window?.location?.href?.toLowerCase()?.includes("new");
}

export function isOnboardingMode() {
  return (
    window?.location?.href?.toLowerCase()?.includes("welcome") ||
    window?.location?.href?.toLowerCase()?.includes(MAGIC_LINK_PATH)
  );
}

export function isInFocusMode() {
  return window?.location?.href?.toLowerCase()?.includes("focus");
}

export function doesWindowLocationIncludeString(text) {
  return window?.location?.href?.includes(text);
}

export function generateRandomString(stringLength) {
  let randomString = "";
  const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].concat(
    "abcdefghijklmnopqrstuvwxyz".split("")
  );
  [...Array(stringLength).keys()].forEach((k) => {
    let randomInteger = _.random(0, options.length - 1);
    randomString = randomString + options[randomInteger];
  });

  return randomString;
}

export function generateAvailabilityToken(currentUser) {
  // sample token generated by backend
  // https://book.vimcal.com/t/jAFu79sWSvDjt4XYNY9dZvK8
  let nameWithTime = "";
  if (currentUser?.first_name?.length < 18) {
    nameWithTime = `${currentUser.first_name}`;
  }

  if (
    currentUser?.last_name &&
    `${nameWithTime}${currentUser.last_name}`.length < 18
  ) {
    nameWithTime = `${nameWithTime}${currentUser.last_name}`;
  }

  if (!nameWithTime || nameWithTime.length === 0) {
    // name's too long
    return generateRandomString(24);
  }

  nameWithTime = `${nameWithTime}${new Date().toISOString()}`;

  return md5(nameWithTime).substr(0, 24).toLowerCase();
}

export function isChrome() {
  return getBrowserType() === CHROME;
}

export function isFirefox() {
  if (isNullOrUndefined(_isFirefox)) {
    const isBrowserFireFox = navigator.userAgent.toLowerCase().includes("firefox");
    _isFirefox = isBrowserFireFox;
    return isBrowserFireFox;
  }

  return _isFirefox;
}

export function detectBrowser() {
  if (_browserType) {
    // use the saved version so we don't repeatedly call this.
    // this will never change
    return _browserType;
  }

  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    _browserType = OPERA;
    return OPERA;
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    _browserType = CHROME;
    return CHROME;
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    _browserType = SAFARI;
    return SAFARI;
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    _browserType = FIREFOX;
    return FIREFOX;
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    _browserType = INTERNET_EXPLORE;
    return INTERNET_EXPLORE; //crap
  } else {
    _browserType = "UnknownBrowser";
    return "UnknownBrowser";
  }
}

export function isProduction() {
  return doesWindowLocationIncludeString("calendar.vimcal.com");
}

function parseFreeBusyTimeSlot(timeSlot) {
  if (timeSlot.is_outlook) {
    return { start: parseJSON(timeSlot.start), end: parseJSON(timeSlot.end) }
  }

  return { start: parseISO(timeSlot.start), end: parseISO(timeSlot.end) }
}

export function mergeBusySlots(busySlots) {
  // incase slots do not come back as chunks that are grouped together
  // chunks: 8:30 - 11:30 and 7 - 10 -> chunking together would be 7- 11:30
  if (!busySlots || busySlots.length === 0) {
    return [];
  } else if (busySlots.length === 1) {
    return busySlots;
  }

  const formattedSlots = busySlots.map((s) => {
    // Outlook returns UTC which we parse with parseJSON
    return parseFreeBusyTimeSlot(s);
  });
  const sorted = formattedSlots.sort((a, b) => SortEvents(a, b, "start"));

  // Algorithm:
  // 1. keep going through the array until the start time of the next event  is after the end of the last event
  // if start time of the next event is not after the end time of the last event -> merge event together
  // if start time of the next event is after, put the last merged event into the new array
  // if last element in array -> put current slot in

  let mergedSlots = [];
  let currentSlot = sorted[0];
  sorted.forEach((s, index) => {
    if (index === 0) {
      // skip since we already set it as the initial value
      return;
    }

    if (isAfterMinute(s.start, currentSlot.end)) {
      // if after, put the curentSlot into mergedSlots and set current slot to s.start
      mergedSlots = mergedSlots.concat(currentSlot);
      currentSlot = s;
    } else if (
      isSameOrBeforeMinute(s.start, currentSlot.end) &&
      isAfterMinute(s.end, currentSlot.end)
    ) {
      // if s. start is before the end of current slot -> merge the slot together
      currentSlot.end = s.end;
    }

    if (index === sorted.length - 1) {
      // if last slot, add the curent slot in
      mergedSlots = mergedSlots.concat(currentSlot);
      return;
    }
  });

  return mergedSlots.map((s) => {
    return { start: s.start.toISOString(), end: s.end.toISOString() };
  });
}

export function getSearchParams() {
  const queryString = window.location.search;
  if (!URLSearchParams) {
    // does not exist on certain browsers
    return null;
  }
  return new URLSearchParams(queryString);
}

export function parseRRuleStringToHumanReadableText(rRuleString) {
  if (!rRuleString) {
    return "";
  }

  try {
    const rrule = rrulestr(cleanRRuleString(rRuleString));
    return capitalizeFirstLetter(rrule.toText());
  } catch (error) {
    handleError(error);
    sendMessageToSentry("unsupported_rrule", rRuleString);
    return "";
  }
}

export function isOSSchemeDarkMode() {
  const osMatchMedia = window.matchMedia("(prefers-color-scheme: dark)");
  return osMatchMedia && osMatchMedia.matches;
}

export function duplicateArray(originalArray, multiplyBy) {
  // https://stackoverflow.com/questions/12503146/create-an-array-with-same-element-repeated-multiple-times
  return Array(multiplyBy).fill(originalArray).flat();
}

export function isEmailAGroupContact(email) {
  if (!email) {
    return false;
  }

  return email.includes("group.v.calendar") || email.includes("group.calendar");
}

export function createEmailAttendeeList(
  currentUser,
  selectedGuests,
  shouldSendCopyToMe
) {
  if (!selectedGuests || selectedGuests.length === 0) {
    return null;
  }

  let recipients = selectedGuests.map((g) => {
    return g.value ?? g.email;
  });

  if (shouldSendCopyToMe) {
    recipients = recipients.concat(currentUser.email);
  }

  return recipients;
}

export function formatTimeZone(inputTimeZone = null, currentTimeZone) {
  // Old timezone = America/Los_Angeles
  // New timezone = Pacific Time - Los Angeles
  let timeZone = inputTimeZone || currentTimeZone;

  timeZone = timeZone.replace("/", " - ");
  timeZone = timeZone.replace("_", " ");

  return timeZone;
}

export function createAttendeeEmailArray(event, currentUser) {
  const attendees = getEventAttendees(event);

  if (!attendees || attendees.length === 0) {
    return null;
  }

  let attendeeArray = [];

  attendees.forEach((a) => {
    if (!a.resource && a.email !== currentUser.email) {
      attendeeArray = attendeeArray.concat(a.email);
    }
  });

  return attendeeArray;
}

export function constructEmailData({
  event,
  currentUser,
  subject,
  message,
  shouldSendCopyToMe,
  currentTimeZone,
  selectedGuests,
  masterAccount,
  hideAttendeesEmail,
  draftEmailId,
  isHTML
}) {
  let emailData = {};

  // Need
  // 1) time_zone (formatted string): Pacific Time - Los Angeles
  // 2) date_time (formatted string): Thursday, July 23rd, 2020 at 7:00 PM – 8:15 PM
  // 3) joining_info: basically formatted conference data. should we just send over HTML?
  //   // PLEASE NORMALIZE: back end shouldn't have to do anything different if it's microsoft teams vs zoom vs meet, etc.
  // 4) location_string (without conference rooms): address
  // 5) location URL: google maps query of above address in #4
  // 6) conference_rooms: array
  // 7) attendees (what google gives us): send over array without conference rooms. include 'organizer'
  // 8) event description
  // 9) subject
  // 10) message
  // 11) user_event_id
  // 12) user_calendar_id
  // 13) reply_to_email: email of the sender who other people should see the email is coming from. if they reply to the email,
  //  it will reply to this person. note: if you are logged in on vimcal but sending it for an event on gmail,
  //  i think it should be the gmail. but if you are a secondary calendar like 165 conference room, is it the current user? Match what google does for both
  // 14) 13) reply_to_full_name: Full name belong to email above
  // 15) send copy to me
  // 16) recipients
  // 17) attendees
  // 18) title

  // 1) Time Zone
  emailData.time_zone = formatTimeZone(
    null,
    currentTimeZone || event?.startTimeZone
  );

  // 2) date_time
  const { eventStart, eventEnd } = event;

  const EMAIL_DATE_FORMAT = "EEEE, MMMM do, yyyy";

  if (getEventStartAllDayDate(event)) {
    // all day
    // all day event that starts and end on same day
    if (isSameDay(eventStart, eventEnd)) {
      emailData.date_time = format(eventStart, EMAIL_DATE_FORMAT);
    } else {
      emailData.date_time =
        format(eventStart, EMAIL_DATE_FORMAT) +
        " - " +
        format(eventEnd, EMAIL_DATE_FORMAT);
    }
  } else {
    // not all day event
    // not all day event that starts and end on same day
    if (isSameDay(eventStart, eventEnd)) {
      emailData.date_time =
        format(eventStart, EMAIL_DATE_FORMAT) +
        " at " +
        format(eventStart, "p") +
        " - " +
        format(eventEnd, "p");
    } else {
      // None all day event that spans multiple days
      emailData.date_time =
        format(eventStart, EMAIL_DATE_FORMAT) +
        " at " +
        format(eventStart, "p") +
        " - " +
        format(eventEnd, EMAIL_DATE_FORMAT) +
        " at " +
        format(eventEnd, "p");
    }
  }

  // 3) join info
  const conferenceUrlLocationOrDescription = GetConferenceURL(event);
  const conferenceDataInfo = determineNativeConferenceInfo(event);

  if (conferenceDataInfo) {
    emailData.joining_info = conferenceDataInfo;
  } else if (conferenceUrlLocationOrDescription) {
    // we detect conferencing from location or description
    emailData.joining_info = {
      conferenceUrl: conferenceUrlLocationOrDescription,
      joinText: `Join ${determineConferenceText(conferenceUrlLocationOrDescription) ||
        "video conference"
        }`,
    };
  }

  // 4) location_string (without conference rooms): address
  const location = filterForLocation(event);
  if (location?.length > 0) {
    emailData.location_string = location;

    // 5)
    let locationUrl = createLocationUrl(location);
    if (locationUrl) {
      emailData.location_url = locationUrl;
    }
  }

  // 6) conference_rooms: array
  const conferenceRooms = generateConferenceRoomsAndStatus(event);
  if (conferenceRooms?.length > 0) {
    let conferenceRoomNames = conferenceRooms.map((c) => {
      return c.name;
    });
    emailData.conference_rooms = conferenceRoomNames;
  }

  // 8) event description
  const eventDescription = getEventDescription(event);
  if (eventDescription?.length > 0) {
    emailData.description = eventDescription;
  }

  // 9) Subject
  emailData.subject =
    subject || event?.summaryUpdatedWithVisibility || getEventTitle(event);

  // 10) Message
  if (isHTML) {
    emailData.message = message.replace(/\n/g, "<br/>")
  } else {
    emailData.message = message;
  }

  // 11) user_event_id
  emailData = addEventUserEventID(emailData, getEventUserEventID(event));

  // 12) user_calendar_id
  emailData = addEventUserCalendarID(emailData, getEventUserCalendarID(event));

  // 13) reply to email
  emailData.reply_to_email = getUserEmail(currentUser);

  // 14) reply to full name
  emailData.reply_to_full_name = getUserName({ user: currentUser, masterAccount }).fullName;

  // 15) send copy to me

  // TODO: DON'T SEND THIS TO THE BACKEND. ADD SELF TO LIST OF RECIPIENTS INSTEAD
  emailData.should_send_copy_to_me = shouldSendCopyToMe;

  // 16) recipients
  const emailAttendeeList = createEmailAttendeeList(
    currentUser,
    selectedGuests,
    shouldSendCopyToMe
  );
  if (emailAttendeeList) {
    emailData.recipients = removeDuplicatesFromArray(emailAttendeeList);
  }

  const attendees = createAttendeeEmailArray(event, currentUser);
  if (attendees?.length > 0) {
    emailData.attendees = attendees;
  }

  emailData.title = subject;

  if (hideAttendeesEmail || isEventHiddenAttendees(event)) {
    emailData.hide_attendees_emails = true;
  }

  if (draftEmailId) {
    emailData.draft_email_id = draftEmailId
  }

  return emailData;
}

export function isMacElectron() {
  return isElectron() && isMac();
}

export function isWindowsElectron() {
  return isElectron() && isWindows();
}

export function isWindows() {
  const platform = navigator?.userAgentData?.platform?.toLowerCase();
  if (platform && platform.indexOf("win") > -1) {
    return true;
  }

  return navigator.platform?.toLowerCase()?.includes("win");
}

export function isIOS() {
  return getOS() === "iOS";
}

export function getOS() {
  // https://dev.to/vaibhavkhulbe/get-os-details-from-the-webpage-in-javascript-b07
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "MacOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function fibonacci(num, memo) {
  // 1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144
  memo = memo || {};

  if (memo[num]) return memo[num];
  if (num <= 1) return 1;

  return (memo[num] = fibonacci(num - 1, memo) + fibonacci(num - 2, memo));
}

export function isTooltipCompleted(masterAccount, tooltipKey) {
  if (isEmptyObjectOrFalsey(masterAccount) || !tooltipKey) {
    return false;
  }

  return getAccountCompletedToolTips(masterAccount)?.includes(tooltipKey);
}

/// given 2 strings, check if they're equal ignoring case
export function isMatchingLoweredCasedString(stringA, stringB) {
  return stringA?.toLowerCase() === stringB?.toLowerCase();
}

export function isTestEnvironment() {
  return (
    !process.env.REACT_APP_CLIENT_ENV
  );
}

export function getLastElementOfArray(listOfEvents) {
  if (!listOfEvents || listOfEvents.length === 0) {
    // nothing to delete
    return null;
  }

  return listOfEvents[listOfEvents.length - 1];
}

export function getWeekStartOnInt(weekStart) {
  if (isNullOrUndefined(weekStart)) {
    return 0;
  }

  return parseInt(weekStart);
}

export function spellOutDate(date) {
  return format(date, "M/d/yyyy");
}

export function insertElementIntoArray({ arr, index, newItem }) {
  // https://stackoverflow.com/questions/586182/how-to-insert-an-item-into-an-array-at-a-specific-index-javascript
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ];
}

export function isValueOneAndNotContainsOne(str) {
  if (!str) {
    return false;
  }

  const pattern = /(^|[^0-9])1([^0-9]|$)/;
  return pattern.test(str);
}

export function shouldRoundToNearest15(duration) {
  if (!duration) {
    return false;
  }

  return duration % 15 === 0 && duration % 30 !== 0;
}

export function checkForNaNDefaultValue({ value, defaultValue }) {
  if (!value || value === "NaN") {
    return defaultValue;
  }

  return value;
}

// TODO: Move to arrayFunctions.js.
export function arraysAreEqual(a, b) {
  if (!a && !b) {
    // not array
    return false;
  } else if (!a && b) {
    return false;
  } else if (a && !b) {
    return false;
  } else if (a.length !== b.length) {
    return false;
  }

  return a.every((value, index) => value === b[index]);
}

export function isSafari() {
  if (isNullOrUndefined(_isSafari)) {
    const userAgent = navigator.userAgent;
    const isBrowserSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    _isSafari = isBrowserSafari;
    return isBrowserSafari;
  }

  return _isSafari;
}

export function blurAfterClick(event) {
  if (event?.target?.blur) {
    event.target.blur();
  }
}

export function replaceContinuousEmptyStrings(input) {
  return input.replace(/( +)/g, ' ');
}

export function replaceNewlinesWithSpace(input) {
  return input.replace(/(\n+)/g, ' ');
}

export function isHTMLText(text) {
  const htmlTagRegex = /<\s*\/?\s*\w+.*?>/;
  const isHTML = htmlTagRegex.test(text);
  return isHTML
}

export function getAllHTMLContent({ html }) {
  if (!html) {
    return "";
  }

  const extractInnerText = () => {
    // Remove style and script tags and their content
    const removedTags = html.replace(
      /<(script|style)[^>]*>[\s\S]*?<\/\1>/gi,
      ""
    );

    // Remove HTML tags and class attributes
    const innerText = removedTags
      .replace(/<[^>]+>/g, "")
      .replace(/class="[^"]*"/gi, "");

    return innerText;
  };
  const innerText = replaceNewlinesWithSpace(
    replaceContinuousEmptyStrings(extractInnerText(html))
  );
  return innerText;
}

export function removeSpecialHTMLCharacters(inputString) {
  if (!inputString) {
    return "";
  }

  // Replace HTML tags with empty strings
  const noHtmlString = inputString.replace(/<\s*\/?\s*\w+.*?>/g, '');

  // Parse the string into a DOM object
  const parser = new DOMParser();
  const dom = parser.parseFromString(noHtmlString, 'text/html');

  // Extract the text content without HTML entities
  const outputString = dom.body.textContent;
  return outputString;
}

export function parseIntWithSafeGuard(input) {
  if (!input) {
    return null;
  }

  const parsedInt = parseInt(input);
  if (isNaN(parsedInt)) {
    return null;
  }

  return parsedInt;
}

export function removeEmptyLines(str) {
  // the regular expression ^\s*[\r\n] matches any line that starts with optional whitespace characters (spaces, tabs)
  // followed by a newline character (either \r\n for Windows-style line endings or \n for Unix-style line endings).
  // The gm flags mean "global" (match multiple times) and "multiline"
  // (treat the ^ and $ characters as the start and end of a line, not the whole string).
  return str?.replace(/^\s*[\r\n]/gm, '');
}

export function isKeyCodeEscape(keyCode) {
  return keyCode === KEYCODE_ESCAPE;
}

export function getDimmedColor({ color, isDarkMode }) {
  if (isDarkMode) {
    return dimDarkModeColor(color);
  }

  return createFadedColorIfIndexDoesNotExist(color, true);
}

// grabs n (count) random elements from an array
export function getRandomElements(arr, count) {
  // Copy original array to avoid mutating it
  const copyArr = [...arr];

  const result = [];
  for (let i = 0; i < count; i++) {
    if (copyArr.length === 0) {
      break;
    }
    const randomIndex = Math.floor(Math.random() * copyArr.length);
    const element = copyArr[randomIndex];
    // Remove the chosen element from our copy array
    copyArr.splice(randomIndex, 1);
    result.push(element);
  }

  return result;
}

export function forceTwoDecimals(number) {
  if (isNullOrUndefined(number) || isNaN(number)) {
    return null;
  }

  return (Math.round(number * 100) / 100).toFixed(2);
}

/**
 * Returns an array of two strings for dollars and cents. The cents will have the
 * start padded with a zero if needed to make it two characters.
 * @param {number} number
 * @returns {[string, string]}
 */
export function splitDollarsAndCents(number) {
  const dollars = Math.floor(number).toString(10);
  // Round to prevent float math precision issues.
  const cents = Math.round((number - dollars) * 100).toString(10).padStart(2, "0");
  return [dollars, cents];
}

// returns higher level electron, chrome,
export function getBrowserType() {
  if (isElectron()) {
    return "Electron";
  } else {
    return detectBrowser();
  }
}

export function getStartOfDayUTC(jsDate) {
  // using date-fns, when I do startofDay(new Date()).toISOString, I get somethig like this: "2024-03-18T04:00:00.000Z". 
  // this function makes it so I get the start of day in UTC (2024-03-18T00:00:00.000Z)
  // Get the start of the day in local time
  const localStartOfDay = startOfDay(jsDate);

  // Convert to UTC
  const utcStartOfDay = new Date(Date.UTC(
    localStartOfDay.getFullYear(),
    localStartOfDay.getMonth(),
    localStartOfDay.getDate()
  ));
  return utcStartOfDay;
}

export function getEndOfDayUTC(jsDate) {
  // using date-fns, when I do endofDay(new Date()).toISOString, I get somethig like this: "2024-03-18T04:00:00.000Z". 
  // this function makes it so I get the end of day in UTC (2024-03-18T00:00:00.000Z)
  // Get the end of the day in local time
  const localEndOfDay = endOfDay(jsDate);

  // Convert to UTC
  const utcEndOfDay = new Date(Date.UTC(
    localEndOfDay.getFullYear(),
    localEndOfDay.getMonth(),
    localEndOfDay.getDate(),
    23, 59, 59, 999 // Set hours, minutes, seconds, and milliseconds to the end of the day
  ));
  return utcEndOfDay;
}
